<template>
    <v-form :disabled="resumido" ref="form">
        <div class="d-flex mt-3">
            <v-spacer></v-spacer>
            <v-btn
                v-if="!resumido"
                outlined
                @click="$emit('view', 'listar', {})"
            >
                <v-icon left>
                    mdi-arrow-left
                </v-icon>
                Voltar
            </v-btn>
            <v-btn
                v-if="!!historico.id_historico && !resumido"
                class="ml-3"
                outlined
                color="primary"
                @click="$emit('view', 'anexo', historico, 'form')"
            >
                <v-icon left>
                    mdi-paperclip
                </v-icon>
                anexos
            </v-btn>
            <v-btn
                v-if="!!historico.id_historico && this.form.id_tipo_historico == 3"
                class="ml-3"
                outlined
                color="primary"
                @click="$emit('view', 'pactuacao', historico, 'form')"
            >
                <v-icon left>
                    mdi-clipboard-list
                </v-icon>
                pactuações
            </v-btn>
        </div>
        <v-sheet class="pa-4 my-5" rounded outlined>
            <h2 class="subtitle-1 mb-3">Histórico</h2>
            <v-row>
                <v-col>
<!--                    todo refatorar items pegando os dados de api/tipo-historico-->
                    <v-select
                        label="Tipo *"
                        outlined
                        dense
                        :disabled="!!historico.id_historico"
                        v-model="form.id_tipo_historico"
                        :rules="validate('required', 'tipo')"
                        :items="itemsTipoHistorico"
                        item-value="id_tipo_historico"
                        item-text="nm_tipo_historico"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-show="!form.nm_situacao"
                        label="Situação do servidor *"
                        outlined
                        dense
                        :disabled="!form.id_tipo_historico || !$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.id_situacao"
                        :rules="[() => !!form.nm_situacao || !!form.id_situacao || 'O campo situação do servidor é obrigatório.']"
                        :filter="filterNormalize"
                        :items="itemsSituacoes"
                        item-value="id_situacao"
                        item-text="nm_situacao"
                    ></v-autocomplete>
                    <v-text-field
                        v-show="!!form.nm_situacao"
                        label="Situação do servidor *"
                        outlined
                        dense
                        clearable
                        readonly
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.nm_situacao"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>

        <v-sheet class="pa-4 mb-5" rounded outlined>
            <h2 class="subtitle-1 mb-3">Detalhamento</h2>
            <OrgaoSecretariaUnidadeFields
                label="exercício"
                :form="formOrgaoSecretariaUnidade"
                @update:form="updateForm"
            />
            <v-row v-if="form.id_tipo_historico == 1">
                <v-col cols="6">
                    <v-select
                        label="Nível afastamento *"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.id_nivel_afastamento"
                        :items="itemsNiveisAfastamento"
                        item-value="id_nivel_afastamento"
                        item-text="nm_afastamento"
                        :rules="validate('required', 'nível afastamento')"
                    ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Nome do curso *"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.nm_curso"
                        :rules="validate('required', 'nome do curso')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete
                        label="Instituição de ensino *"
                        outlined
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.lt_instituicao_ensino"
                        :error-messages="error.lt_instituicao_ensino"
                        :rules="validate('required', 'instituição de ensino')"
                        :filter="filterNormalize"
                        :items="itemsInstituicaoEnsino"
                        item-value="id_instituicao_ensino"
                        item-text="nm_instituicao_ensino"
                        multiple
                        chips
                        clearable
                    >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :input-value="selected"
                            >
                                <v-icon
                                    v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                                    small
                                    @click="parent.selectItem(item)"
                                >
                                    $delete
                                </v-icon>
                                <span class="pl-2">{{ item.nm_instituicao_ensino }}</span>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-else-if="form.id_tipo_historico == 4">
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Data inicial *"
                        outlined
                        dense
                        type="date"
                        hide-details
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.dt_inicio"
                        :rules="validate('required', 'data inicial')"
                        :error-messages="error.dt_inicio"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Data fim"
                        outlined
                        dense
                        type="date"
                        hide-details
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.dt_fim"
                        :error-messages="error.dt_fim"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Tempo total"
                        outlined
                        dense
                        readonly
                        disabled
                        :value="tempoPermanencia"
                    ></v-text-field>
                </v-col>
            </v-row>

            <CidadeEstadoPaisFields
                v-if="form.id_tipo_historico != 4"
                :form="{nm_cidade: form.nm_cidade, ds_estado: form.ds_estado, id_pais: form.id_pais}"
                @update:form="(index, value) => this.form[index] = value"
                :field_pais_disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                :field_estado_disabled="form.id_tipo_historico != 1 && !$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                :field_cidade_disabled="form.id_tipo_historico != 1 && !$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
            />
        </v-sheet>

        <CargoFuncaoFields
            :form="form"
            @update:form="form = $event"
            :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
        />

        <ChefiaImediataFields v-if="form.id_tipo_historico == 3" :form="form" @update:form="form = $event"/>

        <v-sheet v-if="!resumido" class="pa-4 mb-5" rounded outlined>
            <h2 class="subtitle-1 mb-3">Autorização Legal</h2>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Descrição"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.ds_autorizacao"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Data de publicação"
                        outlined
                        dense
                        type="date"
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.dt_autorizacao_publicacao"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Nº Processo SEI"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-mask="'#####.######/####-##'"
                        v-model="form.nr_autorizacao_processo_sei"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        label="Observação"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.ds_autorizacao_observacao"
                        :rules="validate('max:5000', 'observação')"
                        counter
                        maxlength="5000"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-sheet>

        <v-sheet v-if="form.id_tipo_historico != 4" class="pa-4 mb-5" rounded outlined>
            <h2 v-if="form.id_tipo_historico == 3" class="subtitle-1 mb-3">Permanência</h2>
            <h2 v-else class="subtitle-1 mb-3">Duração</h2>
            <v-row>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Data inicial *"
                        outlined
                        dense
                        type="date"
                        hide-details
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.dt_inicio"
                        :rules="validate('required', 'data inicial')"
                        :error-messages="error.dt_inicio"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Data fim"
                        outlined
                        dense
                        type="date"
                        hide-details
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.dt_fim"
                        :error-messages="error.dt_fim"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Tempo total"
                        outlined
                        dense
                        readonly
                        disabled
                        :value="tempoPermanencia"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                        label="Tipo duração *"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.in_tipo_duracao"
                        :items="[{value:'determinado', text: 'Por prazo determinado'}, {value:'indeterminado', text: 'Por prazo indeterminado'}]"
                        :rules="validate('required', 'tipo duração')"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        label="Data estimativa encerramento"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']) || form.in_tipo_duracao != 'determinado'"
                        type="date"
                        v-model="form.dt_estimativa_encerramento"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>

        <v-sheet v-if="form.id_tipo_historico == 1" class="pa-4 mb-5" rounded outlined>
            <h2 class="subtitle-1 mb-3">Projeto de Pesquisa</h2>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Tema de estudo *"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.ds_tema_estudo"
                        :rules="validate('required', 'tema de estudo')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Título do projeto de pesquisa *"
                        outlined
                        dense
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.nm_projeto_pesquisa"
                        :rules="validate('required', 'título do projeto de pesquisa')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Título do trabalho final"
                        outlined
                        dense
                        v-model="form.nm_trabalho_final"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-combobox
                        label="Palavras chave"
                        outlined
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="itemsPalavrasChaves"
                        :hide-no-data="!search"
                        :search-input.sync="search"
                        :items="itemsPalavrasChaves"
                        hide-selected
                        multiple
                        chips
                    >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === item"
                                v-bind="attrs"
                                :input-value="selected"
                            >
                                <v-icon
                                    :disabled="parent.disabled"
                                    small
                                    @click="parent.selectItem(item)"
                                >
                                    $delete
                                </v-icon>
                                <span class="pl-2">{{ item }}</span>
                            </v-chip>
                        </template>
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Pressione <kbd class="primary">enter</kbd> para adicionar: <strong>{{ search }}</strong>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                </v-col>
            </v-row>
        </v-sheet>

        <v-sheet v-else-if="form.id_tipo_historico == 3" class="pa-4 mb-5" rounded outlined>
            <h2 class="subtitle-1 mb-3">Informações Qualitativas</h2>
            <v-row>
                <v-col cols="12" md="">
                    <ModalidadeTrabalhoSelect
                        label="Modalidade de trabalho"
                        v-model="form.id_modalidade_trabalho"
                    />
                </v-col>
                <v-col cols="6" md="">
                    <v-select
                        label="Projeto estratégico SEGES"
                        outlined
                        dense
                        clearable
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.st_projeto_estrategico_seges"
                        :items="[{value:true, text:'Sim'}, {value:false, text:'Não'}]"
                    ></v-select>
                </v-col>
                <v-col cols="6" md="">
                    <v-select
                        label="Processo seletivo"
                        outlined
                        dense
                        clearable
                        :disabled="!$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        v-model="form.st_processo_seletivo"
                        :items="[{value:true, text:'Sim'}, {value:false, text:'Não'}]"
                    ></v-select>
                </v-col>
                <v-col cols="6" md="">
                    <v-select
                        label="Coordenador de equipe"
                        outlined
                        dense
                        clearable
                        v-model="form.st_coordenador_equipe"
                        @change="form.nr_tamanho_equipe = null"
                        :items="[{value:true, text:'Sim'}, {value:false, text:'Não'}]"
                    ></v-select>
                </v-col>
                <v-col cols="6" md="">
                    <v-text-field
                        label="Tamanho da equipe"
                        outlined
                        dense
                        :disabled="!form.st_coordenador_equipe"
                        type="number"
                        v-model="form.nr_tamanho_equipe"
                        :rules="validate('gt:0', 'tamanho da equipe')"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        label="Nome do projeto ou política pública"
                        outlined
                        dense
                        v-model="form.nm_projeto_politica_publica"
                        :rules="validate('max:2000', 'nome do projeto ou política pública')"
                        counter
                        maxlength="2000"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                    <AreaAtuacaoAutocomplete
                        v-model="form.lt_area_atuacao"
                        :error="error.lt_area_atuacao"
                        st-tipo-historico
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <PoliticasPublicasAutocomplete
                        v-model="form.lt_politicas_publicas"
                        :error="error.lt_politicas_publicas"
                        st-tipo-historico
                    />
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="pa-4 mb-5" rounded outlined>
            <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
        </v-sheet>
        <div v-if="!resumido" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import {createServidorHistorico, getServidorHistorico, updateServidorHistorico} from "../../api/servidor";
import {getSituacoesByTipoHistorico} from "../../api/situacao";
import moment from 'moment';
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {getInstituicoesEnsino} from "../../api/instituicao_ensino";
import {getNivelAfastamento} from "../../api/nivel-afastamento";
import {informacoesQualitativasMixin} from "../../mixins/informacoesQualitativasMixin";
import {orgaoSecretariaUnidadeMixin} from "../../mixins/orgaoSecretariaUnidadeMixin";
import {validationMixin} from "../../mixins/validationMixin";
import tipoHistorico from "../../api/tipo-historico";
import PoliticasPublicasAutocomplete from "../FormInputs/PoliticasPublicasAutocomplete.vue";
import AreaAtuacaoAutocomplete from "../FormInputs/AreaAtuacaoAutocomplete.vue";
import ModalidadeTrabalhoSelect from "../FormInputs/ModalidadeTrabalhoSelect.vue";
import ChefiaImediataFields from "../FormInputs/ChefiaImediataFields.vue";
import CargoFuncaoFields from "../FormInputs/CargoFuncaoFields.vue";
import OrgaoEntidadeSIORGAutocomplete from "../FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
import SecretariaDepartamentoSIORGAutocomplete from "../FormInputs/SecretariaDepartamentoSIORGAutocomplete.vue";
import OrgaoSecretariaUnidadeFields from "../FormInputs/OrgaoSecretariaUnidadeFields.vue";
import CidadeEstadoPaisFields from "../FormInputs/CidadeEstadoPaisFields.vue";

moment.locale('pt-br');

export default {
    name: "FichaHistoricoForm",
    components: {
        CidadeEstadoPaisFields,
        OrgaoSecretariaUnidadeFields,
        SecretariaDepartamentoSIORGAutocomplete,
        OrgaoEntidadeSIORGAutocomplete,
        CargoFuncaoFields,
        ChefiaImediataFields,
        ModalidadeTrabalhoSelect,
        AreaAtuacaoAutocomplete, PoliticasPublicasAutocomplete, RowDataUsuarioAlteracao},
    mixins: [
        dataUsuarioAlteracaoMixin,
        informacoesQualitativasMixin,
        orgaoSecretariaUnidadeMixin,
        validationMixin
    ],
    props: {
        servidorId: {
            type: Number,
            required: true
        },
        cargoId: {
            type: Number,
            required: true
        },
        historico: {
            type: Object,
            default: {}
        },
        resumido: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dataLoadedFichaFuncional: false,
            itemsSituacoes: [],
            itemsNiveisAfastamento: [],
            itemsInstituicaoEnsino: [],
            itemsPalavrasChaves:  [],
            itemsTipoHistorico: tipoHistorico,
            search: "",
            form: {
                nm_situacao: null,
                id_tipo_historico: null,
                id_nivel: null,
                id_situacao: null,
                ds_autorizacao_observacao: '',
                lt_instituicao_ensino: [],
                id_pais: 21,
                ds_estado: null,
                nm_cidade: null,
                nr_tamanho_equipe: null,
                dt_estimativa_encerramento: null,
            },
            error: {},
        }
    },
    created() {
        if (!!this.historico.id_historico) {
            getServidorHistorico(this.servidorId, this.historico.id_historico)
                .then((response) => {
                    this.form = {
                        ...response.data.data.afastamento,
                        ...response.data.data.licenca,
                        ...response.data.data.movimentacao,
                        ...response.data.data.vacancia,
                        ...response.data.data,
                    }
                    this.usuarioAlteracao = !!this.form.usuario_alteracao ? this.form.usuario_alteracao.nm_usuario : null;
                    this.dataAlteracao = moment(this.form.updated_at).format("DD/MM/YYYY LT");

                    if (!!this.form.nm_palavras_chaves) {
                        this.itemsPalavrasChaves = this.form.nm_palavras_chaves.split(';');
                    }
                    if (!!response.data.data.afastamento) {
                        this.form.lt_instituicao_ensino = response.data.data.afastamento.instituicoes_ensino.map((object) => object.id_instituicao_ensino)
                    }
                    if (!!response.data.data.movimentacao) {
                        this.form.lt_area_atuacao = response.data.data.movimentacao.areas_atuacao.map((object) => object.id_area_atuacao)
                        this.form.lt_politicas_publicas = response.data.data.movimentacao.politicas_publicas.map((object) => object.id_politicas_publicas)
                    }
                    delete (this.form.afastamento)
                    delete (this.form.licenca)
                    delete (this.form.movimentacao)
                    delete (this.form.vacancia)
                    delete (this.form.usuario_alteracao)

                    this.$nextTick(() => { //with this we skip the first change
                        this.dataLoadedFichaFuncional = true
                    })
                })
        } else {
            this.dataLoadedFichaFuncional = true
        }
        getNivelAfastamento().then(response => this.itemsNiveisAfastamento = response.data.data);
        getInstituicoesEnsino().then(response => this.itemsInstituicaoEnsino = response.data.data);
    },
    watch: {
        'form.id_tipo_historico'(value) {
            if (this.dataLoadedFichaFuncional) {
                this.form.id_situacao = null
            }
            getSituacoesByTipoHistorico(this.cargoId, value).then((response) => this.itemsSituacoes = response.data.data)
        },
        'form.id_situacao'(value) {
            if (!this.form.nm_situacao) {
                let situacao = this.itemsSituacoes.filter(situacao => situacao.id_situacao == value)
                this.form.nm_situacao = situacao[0].nm_situacao
            }
        },
        'form.in_tipo_duracao'(value) {
            if (this.dataLoadedFichaFuncional) {
                this.form.dt_estimativa_encerramento = null
            }
        }
    },
    computed: {
        tempoPermanencia() {
            if (!!this.form.dt_inicio) {
                const inicio = new moment(this.form.dt_inicio, "YYYYMMDD");
                let fim = new moment(); // today

                if (!!this.form.dt_fim) {
                    fim = new moment(this.form.dt_fim, "YYYYMMDD");
                }

                const duration = moment.duration(inicio.diff(fim));
                const days = Math.abs(duration.days())
                const months = Math.abs(duration.months())
                const years = Math.abs(duration.years())

                let result = '';

                if (years > 0) {
                    const plural = years > 1 ? 's' : '';
                    result += ` ${years} ano${plural}`;
                }

                if (months > 0) {
                    const plural = months > 1 ? 'meses' : 'mês';
                    result += ` ${months} ${plural}`;
                }

                if (days > 0) {
                    const plural = days > 1 ? 's' : '';
                    result += ` ${days} dia${plural}`;
                }

                return result
            }
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                if (!!this.itemsPalavrasChaves.length) {
                    this.form.nm_palavras_chaves = this.itemsPalavrasChaves.join(';');
                }

                if (!!this.historico.id_historico) {
                    updateServidorHistorico(this.servidorId, this.historico.id_historico, this.form)
                        .then(response => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: response.data.message,
                            });
                            this.$vuetify.goTo(0);
                            this.$emit('view', 'listar')
                        })
                        .catch((error) => {
                            this.error = {...error.response.data.errors}
                        });
                } else {
                    createServidorHistorico(this.servidorId, this.form)
                        .then(response => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: response.data.message,
                            });
                            this.$vuetify.goTo(0);
                            this.$emit('view', 'listar')
                        })
                        .catch((error) => {
                            this.error = {...error.response.data.errors}
                        });
                }
            }
        }
    },
}
</script>
