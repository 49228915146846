<template>
    <ficha-funcional-tabs :servidor="servidor" />
</template>

<script>
import {getServidor} from '../api/servidor'
import FichaFuncionalTabs from "../components/FichaFuncional/FichaFuncionalTabs.vue";

export default {
    name: "FichaFuncional",
    components: {FichaFuncionalTabs},
    data() {
        return {
            servidor: {},
        }
    },
    created() {
        getServidor(this.$route.params.servidor).then((response) => this.servidor = {...response.data.data})
    },
}
</script>
