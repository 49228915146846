<template>
    <div v-if="!!historico">
        <ficha-historico-form
            v-if="view === 'form'"
            :servidor-id="servidor.id"
            :cargo-id="servidor.cargo.id"
            :historico="historico"
            resumido
            @view="view = 'pactuacao'"
        />
        <ficha-historico-pactuacao
            v-else-if="view === 'pactuacao'"
            :servidor-id="servidor.id"
            :historico="historico"
            disabled
            return-to-view="form"
            @view="view = 'form'"
        />
    </div>
</template>

<script>
import FichaHistoricoForm from "./FichaHistoricoForm.vue";
import FichaHistoricoPactuacao from "./FichaHistoricoPactuacao.vue";
import {getServidorHistoricoAtual} from "../../api/servidor";

export default {
    name: "FichaHistoricoAtual",
    components: {FichaHistoricoPactuacao, FichaHistoricoForm},
    props: {
        servidor: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            historico: null,
            view: 'form',
        }
    },
    created() {
        getServidorHistoricoAtual(this.servidor.id).then(response => {
            this.historico = response.data.data
        })
    },
}
</script>
