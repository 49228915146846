import api from './index';

const getTipoDeficiancia = () => api.get('deficiencia/tipo');
const createTipoDeficiencia = (dados) => api.post('deficiencia/tipo', dados);
const updateTipoDeficiencia = (id_tipo_deficiencia, dados) => api.put(`deficiencia/tipo/${id_tipo_deficiencia}`, dados);
const deleteTipoDeficiencia = (id_tipo_deficiencia) => api.delete(`deficiencia/tipo/${id_tipo_deficiencia}`);

const getPeriodoDeficiancia = () => api.get('deficiencia/periodo');
const createPeriodoDeficiencia = (dados) => api.post('deficiencia/periodo', dados);
const updatePeriodoDeficiencia = (id_periodo_deficiencia, dados) => api.put(`deficiencia/periodo/${id_periodo_deficiencia}`, dados);
const deletePeriodoDeficiencia = (id_periodo_deficiencia) => api.delete(`deficiencia/periodo/${id_periodo_deficiencia}`);

const getGrauDeficiancia = () => api.get('deficiencia/grau');
const createGrauDeficiencia = (dados) => api.post('deficiencia/grau', dados);
const updateGrauDeficiencia = (id_grau_deficiencia, dados) => api.put(`deficiencia/grau/${id_grau_deficiencia}`, dados);
const deleteGrauDeficiencia = (id_grau_deficiencia) => api.delete(`deficiencia/grau/${id_grau_deficiencia}`);

export {
    getTipoDeficiancia,
    createTipoDeficiencia,
    updateTipoDeficiencia,
    deleteTipoDeficiencia,
    getPeriodoDeficiancia,
    createPeriodoDeficiencia,
    updatePeriodoDeficiencia,
    deletePeriodoDeficiencia,
    getGrauDeficiancia,
    createGrauDeficiencia,
    updateGrauDeficiencia,
    deleteGrauDeficiencia
}
