<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Nome *"
                            outlined
                            dense
                            v-model="form.nm_instituicao_ensino"
                            :rules="validate('required', 'nome')"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Sigla *"
                            outlined
                            dense
                            v-model="form.sg_instituicao_ensino"
                            :rules="validate('required', 'sigla')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)"/>
                <DeleteAction @delete="deletar(item[column_id])"/>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {
    createInstituicaoEnsino,
    deleteInstituicaoEnsino,
    getInstituicoesEnsino,
    updateInstituicaoEnsino
} from "../../api/instituicao_ensino";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaInstituicaoEnsino",
    components: {EditAction, DeleteAction},
    mixins: [listasDominiosMixin],
    data() {
        return {
            headers: [
                {text: 'Nome', value: 'nm_instituicao_ensino', sortable: false},
                {text: 'Sigla', value: 'sg_instituicao_ensino', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            fields: {
                nm_instituicao_ensino: null,
                sg_instituicao_ensino: null,
            },
            column_id: 'id_instituicao_ensino',
            api: {
                index: getInstituicoesEnsino,
                create: createInstituicaoEnsino,
                update: updateInstituicaoEnsino,
                delete: deleteInstituicaoEnsino,
            },
        }
    },
}
</script>
