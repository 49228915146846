<template>
    <div class="d-flex flex-wrap">
        <div v-for="(cargo, key) in cargos" :key="key" class="mx-1">
            <v-chip x-small v-if="cargo.id_cargo == 1" color="blue" text-color="white">EPPGG</v-chip>
            <v-chip x-small v-else-if="cargo.id_cargo == 2" color="green" text-color="white">ACE</v-chip>
            <v-chip x-small v-else-if="cargo.id_cargo == 3" color="orange" text-color="white">AIE</v-chip>
            <v-chip x-small v-else-if="cargo.id_cargo == 4" color="deep-orange" text-color="white">EIS</v-chip>
        </div>
    </div>
</template>

<script>
export default {
    name: "CargosChip",
    props: ['cargos']
}
</script>

<style scoped>

</style>
