<template>
    <v-autocomplete
        :label="label"
        outlined
        :dense="!multiple"
        :value="value"
        :clearable="clearable"
        @input="$emit('input', $event)"
        :disabled="disabled"
        :rules="rules"
        :multiple="multiple"
        :chips="multiple"
        :filter="filterNormalize"
        :items="orgaos"
        item-value="id_unidade_siorg"
        item-text="nm_unidade"
    >
        <template v-if="multiple" v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
            >
                <v-icon
                    small
                    @click="parent.selectItem(item)"
                >
                    $delete
                </v-icon>
                <span class="pl-2">{{ item.nm_unidade }}</span>
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import {getOrgaos} from "../../api/orgao";

export default {
    name: "OrgaoEntidadeSIORGAutocomplete",
    mixins: [filterNormalizeMixin],
    props: {
        label: {
            type: String,
            default: 'Órgão ou entidade'
        },
        value: {
            type: Number|Array,
        },
        rules: {
            type: Array,
            default: () => []
        },
        clearable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        withTrashed: {
            type: Boolean,
            default: false
        },
        nmOrgao: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            orgaos: []
        }
    },
    watch: {
        value(value) {
            const verifyValue = this.multiple ? !!value.length : !!value
            if (!this.nmOrgao && verifyValue && this.orgaos.length > 0) {
                let orgao = this.orgaos.filter(orgao => orgao.id_unidade_siorg == value)
                this.$emit('update:nmOrgao', orgao[0].nm_unidade)
            }
        }
    },
    created() {
        getOrgaos(this.withTrashed).then(response => this.orgaos = response.data.data);
    },
}
</script>
