<template>
    <div>
        <h1 class="text-h5 my-4">{{ title }}</h1>
        <div v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])">
            <v-tabs v-model="tab" fixed-tabs center-active>
                <v-tab v-for="(item, key) in items" :key="key" :disabled="!analiseProcessoSeletivo">
                    {{ item.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, key) in items" :key="key">
                    <v-sheet
                        class="pa-md-4"
                        :outlined="$vuetify.breakpoint.mdAndUp"
                        rounded
                    >
                        <component :is="item.component" v-bind="item.props" v-on="item.event"/>
                    </v-sheet>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-else-if="analiseProcessoSeletivo">
            <v-sheet
                class="pa-md-4"
                :outlined="$vuetify.breakpoint.mdAndUp"
                rounded
            >
                <AnaliseProcessoSeletivoOrgao
                    :analiseProcessoSeletivo="analiseProcessoSeletivo"
                    :id_status_processo_seletivo="idStatusProcessoSeletivo"
                    :showInstrucoes="true"
                    :disableEnviar="disableBtnEnviar"
                />
            </v-sheet>
        </div>
    </div>
</template>

<script>
import AnaliseProcessoSeletivoGeral from "./AnaliseProcessoSeletivoGeral.vue";
import {getAnaliseProcessoSeletivo} from "../../api/analise-processo-seletivo";
import AnaliseProcessoSeletivoOrgao from "./AnaliseProcessoSeletivoOrgao.vue";
import DivulgacaoProcessoSeletivo from "./DivulgacaoProcessoSeletivo.vue";

export default {
    name: "AnaliseProcessoSeletivo",
    components: {AnaliseProcessoSeletivoOrgao},
    props: ['processoSeletivo'],
    data() {
        return {
            tab: 0,
            analiseProcessoSeletivo: null,
            idStatusProcessoSeletivo: null,
            items: [
                {
                    title: 'Geral',
                    component: AnaliseProcessoSeletivoGeral,
                    props: {
                        analiseProcessoSeletivo: null,
                        id_processo_seletivo: this.processoSeletivo.id_processo_seletivo,
                        id_status_processo_seletivo: this.processoSeletivo.id_status_processo_seletivo,
                    },
                    event: {
                        'setAnaliseProcessoSeletivo': (analiseProcessoSeletivo) => this.analiseProcessoSeletivo = analiseProcessoSeletivo,
                        'setIdStatusProcessoSeletivo': (idStatusProcessoSeletivo) => this.idStatusProcessoSeletivo = idStatusProcessoSeletivo,
                    }
                },
                {
                    title: 'Checklist Órgão',
                    component: AnaliseProcessoSeletivoOrgao,
                    props: {analiseProcessoSeletivo: null, id_status_processo_seletivo: null, showInstrucoes: false},
                    event: {
                        'setIdStatusProcessoSeletivo': (idStatusProcessoSeletivo) => this.idStatusProcessoSeletivo = idStatusProcessoSeletivo,
                    }
                },
                {
                    title: 'Divulgação',
                    component: DivulgacaoProcessoSeletivo,
                    props: {processoSeletivo: this.processoSeletivo},
                    event: {
                        'setIdStatusProcessoSeletivo': (idStatusProcessoSeletivo) => this.idStatusProcessoSeletivo = idStatusProcessoSeletivo,
                    }
                },
            ]
        }
    },
    async created() {
        await getAnaliseProcessoSeletivo(this.processoSeletivo.id_processo_seletivo).then(response => {
            this.analiseProcessoSeletivo = response.data.data;
        });
        this.idStatusProcessoSeletivo = this.processoSeletivo.id_status_processo_seletivo;
    },
    watch: {
        analiseProcessoSeletivo: function (val) {
            this.items.forEach(item => {
                item.props.analiseProcessoSeletivo = val;
            });
        },
        idStatusProcessoSeletivo: function (val) {
            this.items.forEach(item => {
                item.props.id_status_processo_seletivo = val;
            });
            this.$emit('setIdStatusProcessoSeletivo', val);
        }
    },
    computed: {
        title() {
            if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
                return 'Análise CGCAT';
            }

            return 'Revisar Solicitação de Processo Seletivo';
        },
        disableBtnEnviar() {
            return this.processoSeletivo.id_usuario_solicitante !== this.$store.getters['auth/usuarioAutenticado'] &&
                !this.$store.getters['auth/usuarioPermissao'](['rh'])
        }
    },

}
</script>
