<template>
    <v-card outlined>
        <v-card-text>
            <PactuacaoResultadoFields
                type="processo_seletivo"
                :model="processoSeletivo"
                @update:items="items = $event"
                :disabled="disabled"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-3" :disabled="disabled" outlined @click="$emit('previousStep')">voltar</v-btn>
            <div v-if="processoSeletivo.id_status_processo_seletivo === 1">
                <v-btn disabled v-if="items.length < 3 || !validaPrazoSegundoAnoSubsequente">
                    <span v-show="items.length < 3">Cadastre, pelo menos, +{{
                            3 - items.length
                        }} resultado(s)</span>
                    <span v-show="items.length >= 3 && !validaPrazoSegundoAnoSubsequente">Cadastre, pelo menos, 1 resultado para o segundo ano subsequente</span>
                </v-btn>
                <v-btn
                    v-else-if="!!processoSeletivo.divulgacao"
                    :disabled="disabled"
                    color="primary"
                    @click="dialogReabrirInscricoes = true"
                >Reabrir Inscrições</v-btn>
                <v-btn
                    v-else
                    :disabled="disabled"
                    color="primary"
                    @click="dialog = true"
                >enviar</v-btn>
            </div>
        </v-card-actions>

        <v-dialog
            v-if="processoSeletivo.id_status_processo_seletivo === 1"
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>
                    Enviar solicitação de abertura de processo seletivo
                </v-card-title>
                <v-card-text>
                    Deseja enviar a solicitação de abertura de processo seletivo para análise da Secretaria de Gestão e Inovação?
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="enviar"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogReabrirInscricoes"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>Reabrir Inscrições</v-card-title>
                <v-card-text>Deseja reabrir as inscrições?</v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialogReabrirInscricoes = false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="reabrirInscricoes"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import {updateProcessoSeletivoStatus} from "../../api/processo-seletivo";
import PactuacaoResultadoFields from "../FormInputs/PactuacaoResultadoFields.vue";
import {reabrirInscricoesMixin} from "../../mixins/reabrirInscricoesMixin";

export default {
    name: "ProcessoSeletivoPactuacaoResultado",
    mixins: [reabrirInscricoesMixin],
    components: {PactuacaoResultadoFields},
    props: ['disabled', 'processoSeletivo'],
    data() {
        return {
            dialog: false,
            items: [],
        }
    },
    computed: {
        validaPrazoSegundoAnoSubsequente() {
            return this.items.some(item => {
                const ano = item.dt_prazo.split('-')[0];
                const mes = item.dt_prazo.split('-')[1];
                const dia = item.dt_prazo.split('-')[2];
                const date = new Date(ano, (mes - 1), dia);

                const createdDate = new Date(this.processoSeletivo.created_at);
                return date.getFullYear() >= (createdDate.getFullYear() + 2);
            });
        }
    },
    methods: {
        enviar() {
            updateProcessoSeletivoStatus(this.processoSeletivo.id_processo_seletivo, {id_status_processo_seletivo: 2})
                .then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$router.push({name: 'processo-seletivo-listar'});
                });
        }
    },
}
</script>
