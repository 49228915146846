import api from './index'

const getAnaliseSolicitacao = (id_solicitacao_movimentacao) => api.get('solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao');

const upsertAnaliseSolicitacao = (id_solicitacao_movimentacao, dados) => api.post(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao',
    dados
);

const updateAnaliseChecklistOrgao = (id_solicitacao_movimentacao, id_analise_solicitacao, dados) => api.put(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/' + id_analise_solicitacao + '/checklist-orgao',
    dados
);

const updateAnaliseChecklistServidor = (id_solicitacao_movimentacao, id_analise_solicitacao, dados) => api.put(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/' + id_analise_solicitacao + '/checklist-servidor',
    dados
);

const enviarAnaliseOrgao = (id_solicitacao_movimentacao, dados) => api.put(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/enviar-analise-orgao',
    dados
);

const enviarAnaliseServidor = (id_solicitacao_movimentacao, dados) => api.put(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/enviar-analise-servidor',
    dados
);

const registrarAceite = (id_solicitacao_movimentacao, dados) => api.put(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/registrar-aceite',
    dados
);

const downloadZip = (id_solicitacao_movimentacao, arquivosSelecionados) => api.get(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/download-zip',
    {responseType: 'arraybuffer', params: {arquivosSelecionados}},
);

const abrirProcessoSEI = (id_solicitacao_movimentacao, id_analise_solicitacao) => api.put(
    'solicitacao-movimentacao/' + id_solicitacao_movimentacao + '/analise-solicitacao/' + id_analise_solicitacao + '/abrir-processo-sei',
);

export {
    getAnaliseSolicitacao,
    upsertAnaliseSolicitacao,
    updateAnaliseChecklistOrgao,
    updateAnaliseChecklistServidor,
    enviarAnaliseOrgao,
    enviarAnaliseServidor,
    registrarAceite,
    abrirProcessoSEI,
    downloadZip,
}
