import {getPaises} from "../api/pais";
import {getEstados} from "../api/estado";

export const enderecoMixin = {
    data() {
        return {
            itemsPaises: [],
            itemsEstados: [],
            itemsMunicipios: [],
        }
    },
    created() {
        getPaises().then(response => this.itemsPaises = response.data.data);
    },
    watch: {
        'form.ds_estado'(val) {
            if (this.form.id_pais == 21 && !!val) {
                return getEstados(val).then(response => this.itemsMunicipios = response.data.data)
            }
        }
    },
    methods: {
        limparCamposEndereco() {
            this.form.nr_cep = null;
            this.form.ds_logradouro = null;
            this.form.ds_bairro = null;
            this.form.ds_numero = null;
            this.form.ds_complemento = null;
            this.form.ds_estado = null;
            this.form.nm_cidade = null;
        },
    },
}
