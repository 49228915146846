<template>
    <v-form ref="form">
        <h1 class="text-h5 my-4">
            Cadastro de Usuário
        </h1>
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <h2 class="subtitle-1 mb-3">Dados do Usuário</h2>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        :disabled="!!form.id_servidor"
                        label="Nome *"
                        outlined
                        dense
                        v-model="form.nm_usuario"
                        :rules="validate('required', 'nome')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        :disabled="!!form.id_servidor"
                        label="CPF *"
                        outlined
                        dense
                        v-model="form.nr_cpf"
                        v-mask="'###.###.###-##'"
                        :rules="validate('required|cpf', 'cpf')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="">
                    <v-text-field
                        :disabled="!!form.id_servidor"
                        label="Email institucional *"
                        outlined
                        dense
                        v-model="form.ds_email_institucional"
                        :rules="validate('required|email', 'email institucional')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="">
                    <v-text-field
                        :disabled="!!form.id_servidor"
                        label="Telefone comercial *"
                        outlined
                        dense
                        v-model="form.nr_telefone"
                        :rules="validate('required', 'telefone comercial')"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                    ></v-text-field>
                </v-col>
                <v-col v-if="!this.usuario.id_usuario" cols="12" md="">
                    <v-select
                        label="Status *"
                        outlined
                        dense
                        v-model="form.st_ativo"
                        :items="[
                            {value: true, text: 'Ativo'},
                            {value: false, text: 'Inativo'},
                        ]"
                        :rules="validate('required', 'status')"
                    ></v-select>
                </v-col>
                <v-col v-if="!usuario.id_usuario && this.perfisHasRH" cols="12">
                    <OrgaoEntidadeSIORGAutocomplete
                        label="Órgão ou Entidade *"
                        v-model="form.lt_orgaos"
                        :rules="validate('required', 'órgão ou entidade')"
                        multiple
                        clearable
                    />
                </v-col>
            </v-row>
            <div v-if="!usuario.id_usuario">
                <h2  class="subtitle-1 mb-3">Perfis do Usuário</h2>
                <v-row>
                    <v-col>
                        <v-checkbox
                            class="mb-n5"
                            v-model="form.in_perfis"
                            value="analista_cgcat"
                            label="Analista CGCAT"
                        />
                        <v-checkbox
                            class="mb-n5"
                            v-model="form.in_perfis"
                            value="administrador"
                            label="Administrador"
                        />
                        <v-checkbox
                            disabled
                            class="mb-n5"
                            v-model="form.in_perfis"
                            value="carreira"
                            label="Carreira"
                        />
                        <v-checkbox
                            class="mb-n5"
                            v-model="form.in_perfis"
                            value="chefia"
                            label="Chefia imediata do servidor"
                        />
                        <v-checkbox
                            class="mb-n5"
                            v-model="form.in_perfis"
                            value="rh"
                            label="RH"
                        />
                        <v-checkbox
                            disabled
                            class="mb-n5"
                            v-model="form.in_perfis"
                            value="solicitante"
                            label="Solicitante"
                        />
                    </v-col>
                </v-row>
                <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao" />
            </div>
        </v-sheet>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import {getUsuario, putMe, putUsuario} from "../api/usuario";
import RowDataUsuarioAlteracao from "./RowDataUsuarioAlteracao";
import moment from 'moment';
import {dataUsuarioAlteracaoMixin,} from "../mixins/dataUsuarioAlteracaoMixin";
import {validationMixin} from "../mixins/validationMixin";
import {filterNormalizeMixin} from "../mixins/filterNormalizeMixin";
import {getOrgaos} from "../api/orgao";
import OrgaoEntidadeSIORGAutocomplete from "./FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
moment.locale('pt-br');

export default {
    name: "UsuarioForm",
    mixins: [dataUsuarioAlteracaoMixin, validationMixin, filterNormalizeMixin],
    components: {OrgaoEntidadeSIORGAutocomplete, RowDataUsuarioAlteracao},
    props: {
        usuario: {
            type: Object,
            default() {
                return {id_usuario: null}
            }
        },
    },
    data() {
        return {
            dataLoaded: false,
            dialogMeusDados: false,
            itemsOrgaos: [],
            form: {
                nm_usuario: null,
                nr_cpf: null,
                ds_email_institucional: null,
                nr_telefone: null,
                in_perfis: [],
                st_ativo: true,
            },
        }
    },
    created() {
        getOrgaos().then((response) => this.itemsOrgaos = response.data.data);
        if (!!this.usuario.id_usuario) {
            this.form = {...this.usuario}
        } else {
            getUsuario(this.$route.params.usuario).then((response) => {
                this.form.nm_usuario = response.data.data.nm_usuario;
                this.form.nr_cpf = response.data.data.nr_cpf;
                this.form.ds_email_institucional = response.data.data.ds_email_institucional;
                this.form.nr_telefone = response.data.data.nr_telefone;
                this.form.in_perfis = response.data.data.in_perfis.split(',');
                this.form.st_ativo = response.data.data.st_ativo;
                this.form.lt_orgaos = response.data.data.orgaos.map(orgao => orgao.id_unidade_siorg);
                this.setDataUsuarioAlteracaoByResponse(response)
            })
        }
    },
    computed: {
        perfisHasRH() {
            return this.form.in_perfis.includes('rh')
        },
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                this.form.nr_cpf = this.form.nr_cpf.replace(/[-.]/g, '')

                if (!!this.usuario.id_usuario) {
                    putMe(this.form).then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$emit('closeDialog', false);
                        this.$vuetify.goTo(0);
                    })
                } else {
                    putUsuario(this.$route.params.usuario, this.form).then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$router.push('/usuario');
                    })
                }
            }
        }
    },
}
</script>
