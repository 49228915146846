export const informacoesQualitativasMixin = {
    data() {
        return {
            dataLoaded: false,
            form: {
                id_modalidade_trabalho: null,
                st_projeto_estrategico_seges: null,
                st_processo_seletivo: null,
                st_coordenador_equipe: null,
                nr_tamanho_equipe: null,
                lt_area_atuacao: [],
                lt_politicas_publicas: [],
            }
        }
    },
}
