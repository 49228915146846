<template>
    <div class="text-left">
        <v-alert v-model="erro.ativo" type="error" dismissible prominent>
            <div class="text-h6">
                <strong>{{ erro.titulo }}</strong>
            </div>
            {{ erro.mensagem }}
        </v-alert>

        <v-alert v-model="sucesso.ativo" type="success" dismissible prominent>
            <div class="text-h6">
                <strong>{{ sucesso.titulo }}</strong>
            </div>
            {{ sucesso.mensagem }}
        </v-alert>

        <v-alert v-model="info.ativo" type="info" dismissible prominent>
            <div class="text-h6">
                <strong>{{ info.titulo }}</strong>
            </div>
            {{ info.mensagem }}
        </v-alert>

        <v-alert v-model="warning.ativo" type="warning" dismissible prominent>
            <div class="text-h6">
                <strong>{{ warning.titulo }}</strong>
            </div>
            {{ warning.mensagem }}
        </v-alert>
    </div>
</template>


<script>
    export default {
        name: 'Alerta',
        computed: {
            erro() {
                return this.$store.getters['alertas/getErro'];
            },
            sucesso() {
                return this.$store.getters['alertas/getSucesso'];
            },
            info() {
                return this.$store.getters['alertas/getInfo'];
            },
            warning() {
                return this.$store.getters['alertas/getWarning'];
            }
        }
    }
</script>
