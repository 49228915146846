<template>
    <v-select
        :label="label"
        outlined
        dense
        clearable
        :value="value"
        @input="$emit('input', $event)"
        :items="modalidadesTrabalho"
        :rules="rules"
        item-value="id_modalidade_trabalho"
        item-text="nm_modalidade_trabalho"
    />
</template>

<script>
import {getModalidadesTrabalho} from "../../api/modalidade-trabalho";

export default {
    name: "ModalidadeTrabalhoSelect",
    props: {
        label: {
            type: String,
            default: 'Modalidade de trabalho'
        },
        value: {
            type: Number|Array,
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            modalidadesTrabalho: []
        }
    },
    created() {
        getModalidadesTrabalho().then(response => this.modalidadesTrabalho = response.data.data);
    },
}
</script>
