<template>
    <v-form :readonly="resumido" ref="form">
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Endereço do currículo do SOUGOV"
                        :append-icon="validateUrl(form.url_banco_talentos) ? 'mdi-open-in-new' : ''"
                        outlined
                        dense
                        readonly
                        @click:append="openLink(form.url_banco_talentos)"
                        v-model="form.url_banco_talentos"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Endereço do portfólio individual (GENIALLY)"
                        :append-icon="validateUrl(form.url_genially) ? 'mdi-open-in-new' : ''"
                        outlined
                        dense
                        @click:append="openLink(form.url_genially)"
                        @keyup.enter="salvar()"
                        v-model="form.url_genially"
                        :rules="[() => !form.url_genially || validateUrl(form.url_genially) || 'Endereço é inválido']"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Endereço linkedin"
                        :append-icon="validateUrl(form.url_linkedin) ? 'mdi-open-in-new' : ''"
                        outlined
                        dense
                        @click:append="openLink(form.url_linkedin)"
                        @keyup.enter="salvar()"
                        v-model="form.url_linkedin"
                        :rules="[() => !form.url_linkedin || validateUrl(form.url_linkedin) || 'Endereço é inválido']"
                    ></v-text-field>
                </v-col>
            </v-row>
            <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
        </v-sheet>
        <div v-if="!resumido" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import {
    getServidorCurriculo,
    upsertServidorCurriculo,
    upsertServidorCurriculoPeloBancoTalentos
} from "../../api/servidor";
import moment from 'moment';
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";

moment.locale('pt-br');

export default {
    name: "FichaCurriculo",
    mixins: [dataUsuarioAlteracaoMixin],
    components: {RowDataUsuarioAlteracao},
    props: {
        servidorId: {
            type: Number,
            required: true
        },
        resumido: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            url_sougov: null,
            form: {},
            error: {}
        }
    },
    async created() {
        await upsertServidorCurriculoPeloBancoTalentos(this.servidorId)
        await getServidorCurriculo(this.servidorId)
            .then(response => {
                this.form = {
                    ...response.data.data
                }

                this.setDataUsuarioAlteracaoByResponse(response)
            });
    },
    methods: {
        validateUrl(value) {
            return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
        },
        openLink(url) {
            window.open(url)
        },
        salvar() {
            upsertServidorCurriculo(this.servidorId, this.form)
                .then((response) => {
                    this.setDataUsuarioAlteracaoByResponse(response)

                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
                .catch((error) => {
                    this.error = {...error.response.data.errors}
                });
        }
    },
}
</script>
