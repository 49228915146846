<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1 mb-7"
        >
            <template v-slot:item.dt_inicio="{ item, index }">
                <span
                    :class="sobreposicaoDataInicio(item.dt_inicio, index) ? 'font-weight-bold red--text' : ''"
                >{{ dateFormat(item.dt_inicio) }}</span>
            </template>
            <template v-slot:item.dt_fim="{ item, index }">
                <span
                    :class="sobreposicaoDataFim(item.dt_fim, index) ? 'font-weight-bold red--text' : ''"
                >{{ dateFormat(item.dt_fim) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction @click="$emit('view', 'form', item)" />
                <DeleteAction
                    v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                    @delete="deletar(item.id_historico)"
                />
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="primary"
                            elevation="0"
                            x-small
                            v-on="{ ...tooltip}"
                            @click="$emit('view', 'anexo', item)"
                        >
                            <v-icon>
                                mdi-paperclip
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Anexos</span>
                </v-tooltip>
                <v-tooltip v-if="item.id_tipo_historico === 3" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="primary"
                            elevation="0"
                            x-small
                            v-on="{ ...tooltip}"
                            @click="$emit('view', 'pactuacao', item)"
                        >
                            <v-icon>
                                mdi-clipboard-list
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Pactuações</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <div v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="$emit('view', 'form', {})"
            >
                Novo Registro
            </v-btn>
        </div>
    </div>
</template>

<script>
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {deleteServidorHistorico, getServidorHistoricos} from "../../api/servidor";
import moment from 'moment';
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";
moment.locale('pt-br');

export default {
    name: "FichaHistoricoListar",
    components: {EditAction, DeleteAction},
    mixins: [dataTableMixin],
    props: {
        servidorId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            cargosFuncoesOcupados: [],
            headers: [
                {text: 'Tipo', value: 'tipo_historico.nm_tipo_historico', sortable: false},
                {text: 'Orgão ou Entidade', value: 'nm_orgao', sortable: false},
                {text: 'Início', value: 'dt_inicio', sortable: false},
                {text: 'Fim', value: 'dt_fim', sortable: false},
                {text: 'Situação Funcional', value: 'nm_situacao', sortable: false},
                {text: 'Nível', value: 'nivel.nm_nivel', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
        }
    },
    methods: {
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY")
            }
            return null;
        },
        sobreposicaoDataInicio(dt_inicio, index) {
            if (index === this.items.length - 1) {
                return false;
            }
            return dt_inicio < this.items[index + 1].dt_fim;
        },
        sobreposicaoDataFim(dt_fim, index) {
            if (index === 0) {
                return false;
            }
            return dt_fim > this.items[index - 1].dt_inicio;
        },
        filtrar() {
            getServidorHistoricos(this.servidorId, this.pagination.page, this.pagination.itemsPerPage)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        deletar(id_historico) {
            deleteServidorHistorico(this.servidorId, id_historico)
                .then(response => {
                    this.items = this.items.filter(item => item.id_historico !== id_historico);
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        }
    }
}
</script>
