<template>
    <div>
        <h1 class="text-h5 my-4"> {{ title }} </h1>
        <v-tabs v-model="tab">
            <v-tab v-for="(item, key) in items" :key="key">{{item}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-sheet class="pa-4" outlined rounded>
                    <oportunidades-processos-listar
                        v-if="showLists"
                        id_status_processo_seletivo="4"
                        :id_cargo="id_cargo"
                        :id_usuario_solicitante="id_usuario_solicitante"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <oportunidades-processos-listar
                        v-if="showLists"
                        id_status_processo_seletivo="5"
                        :id_cargo="id_cargo"
                        :id_usuario_solicitante="id_usuario_solicitante"
                    />
                </v-sheet>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {dateMixin} from "../../mixins/dateMixin";
import OportunidadesProcessosListar from "./OportunidadesProcessosListar.vue";
import {getServidor} from "../../api/servidor";

export default {
    name: "OportunidadesProcessosTabs",
    components: {OportunidadesProcessosListar},
    mixins: [dataTableMixin, dateMixin],
    props: {
        isProcessos: {
           type: Boolean,
           default: false
        }
    },
    data() {
        return {
            tab: null,
            id_cargo: null,
            id_usuario_solicitante: null,
            items: ['Inscrições Abertas', 'Inscrições Encerradas'],
            showLists: false,
        }
    },
    created() {
        if (this.isProcessos) {
            this.id_usuario_solicitante = this.$store.getters['auth/usuarioAutenticado'];
        } else if (
            !this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']) &&
            this.$store.getters['auth/usuarioAutenticadoServidor']
        ) {
            this.id_cargo = this.$store.getters['auth/usuarioAutenticadoServidorCargo'];
        }

        this.showLists = true;
    },
    computed: {
        title() {
            return this.isProcessos ? 'Processos Seletivos' : 'Oportunidades de Movimentação';
        }
    }
}
</script>
