import api from './index';

const getServidores = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('servidores', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

const getServidoresSemVacancia = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('servidores-sem-vacancia', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

const getServidor = (id_servidor) => api.get('servidor/' + id_servidor);
const getServidorResumido = (id_servidor) => api.get('servidor/' + id_servidor + '/resumido');
const gerarPDFServidor = (id_servidor) => api.get(
    'servidor/' + id_servidor + '/gerar-pdf',
    {responseType: 'arraybuffer'}
);
const getServidorCargosFuncoesOcupados = (id_servidor, page, itemsPerPage) => api.get('servidor/' + id_servidor + '/cargos-funcoes-ocupados', {
    params: {
        page: page,
        limit: itemsPerPage,
    },
    showLoader: false
})

const getServidorContatos = (id_servidor) => api.get('servidor/' + id_servidor + '/contatos')

const getServidorCurriculo = (id_servidor) => api.get('servidor/' + id_servidor + '/curriculo')

const getServidorDadosPessoais = (id_servidor) => api.get('servidor/' + id_servidor + '/dados-pessoais')

const getServidorDadosFuncionais = (id_servidor) => api.get('servidor/' + id_servidor + '/dados-funcionais')

const getServidorHistorico = (id_servidor, id_historico) => api.get('servidor/' + id_servidor + '/historico/' + id_historico)

const getServidorHistoricoAtual = (id_servidor) => api.get('servidor/' + id_servidor + '/historico-atual');

const getServidorHistoricos = (id_servidor, page, itemsPerPage) => api.get('servidor/' + id_servidor + '/historico', {
    params: {
        page: page,
        limit: itemsPerPage,
    }
})

const getServidorHistoricoAnexoDownload = (id_servidor, id_historico, id_anexo) => api.get('servidor/' + id_servidor + '/historico/' + id_historico + '/anexo/' + id_anexo, {responseType: 'blob'})

const getServidorHistoricoAnexos = (id_servidor, id_historico, page, itemsPerPage) => api.get('servidor/' + id_servidor + '/historico/' + id_historico + '/anexo', {
    params: {
        page: page,
        limit: itemsPerPage,
    }
})

const getServidorInteresses = (id_servidor) => api.get('servidor/' + id_servidor + '/interesses')

const getServidorOportunidades = (id_servidor) => api.get('servidor/' + id_servidor + '/oportunidades');

const createServidorHistorico = (id_servidor, dados) => api.post('servidor/' + id_servidor + '/historico', dados)

const createServidorHistoricoAnexo = (id_servidor, id_historico, dados) => api.post('servidor/' + id_servidor + '/historico/' + id_historico + '/anexo', dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
})

const updateServidor = (id_servidor, dados) => api.put('servidor/' + id_servidor, dados);
const updateServidorHistorico = (id_servidor, id_historico, dados) => api.put('servidor/' + id_servidor + '/historico/' + id_historico, dados)

const updateServidorHistoricoAnexo = (id_servidor, id_historico, id_anexo, dados) => api.post('servidor/' + id_servidor + '/historico/' + id_historico + '/anexo/' + id_anexo, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
})

const upsertServidorContatos = (id_servidor, dados) => api.post('servidor/' + id_servidor + '/contatos', dados)

const upsertServidorCurriculo = (id_servidor, dados) => api.post('servidor/' + id_servidor + '/curriculo', dados)

const upsertServidorCurriculoPeloBancoTalentos = (id_servidor) => api.post('servidor/' + id_servidor + '/curriculo-banco-talentos')

const upsertServidorDadosPessoais = (id_servidor, dados) => api.post('servidor/' + id_servidor + '/dados-pessoais', dados)

const upsertServidorDadosFuncionais = (id_servidor, dados) => api.post('servidor/' + id_servidor + '/dados-funcionais', dados)

const upsertServidorInteresses = (id_servidor, dados) => api.post('servidor/' + id_servidor + '/interesses', dados)

const deleteServidorHistorico = (id_servidor, id_historico) => api.delete('servidor/' + id_servidor + '/historico/' + id_historico)

const deleteServidorHistoricoAnexo = (id_servidor, id_historico, id_anexo) => api.delete('servidor/' + id_servidor + '/historico/' + id_historico + '/anexo/' + id_anexo)

export {
    getServidores,
    getServidoresSemVacancia,
    getServidor,
    getServidorResumido,
    gerarPDFServidor,
    getServidorCargosFuncoesOcupados,
    getServidorContatos,
    getServidorCurriculo,
    getServidorDadosPessoais,
    getServidorDadosFuncionais,
    getServidorHistorico,
    getServidorHistoricoAtual,
    getServidorHistoricos,
    getServidorHistoricoAnexoDownload,
    getServidorHistoricoAnexos,
    getServidorInteresses,
    getServidorOportunidades,
    createServidorHistorico,
    createServidorHistoricoAnexo,
    updateServidor,
    updateServidorHistorico,
    updateServidorHistoricoAnexo,
    upsertServidorContatos,
    upsertServidorCurriculo,
    upsertServidorCurriculoPeloBancoTalentos,
    upsertServidorDadosPessoais,
    upsertServidorDadosFuncionais,
    upsertServidorInteresses,
    deleteServidorHistorico,
    deleteServidorHistoricoAnexo
}
