<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <CarreiraSelect v-model="filtro['tb_servidor.id_cargo']" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="CPF"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nr_cpf"
                            v-mask="'###.###.###-##'"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-combobox
                            label="Nome"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            :loading="loading"
                            v-model="filtro.nm_servidor"
                            :search-input.sync="searchServidorByNome"
                            :items="comboboxServidores"
                            :filter="filterNormalize"
                            item-text="nm_servidor"
                            item-value="nm_servidor"
                            :return-object="false"
                        >
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >
            <template v-slot:item.nr_cpf="{ item }">
                <span>{{ item.nr_cpf }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction :to="{name: 'ficha-funcional-editar', params: {servidor: item.id_servidor}}" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {getServidores} from "../api/servidor";
import {dataTableMixin} from "../mixins/dataTableMixin";
import _ from 'lodash';
import {filterNormalizeMixin} from "../mixins/filterNormalizeMixin";
import CarreiraSelect from "../components/FormInputs/CarreiraSelect.vue";
import EditAction from "../components/Table/EditAction.vue";

export default {
    name: "FichaFuncionalListar",
    components: {EditAction, CarreiraSelect},
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                'tb_servidor.id_cargo': null,
                nr_cpf: '',
                nm_servidor: null
            },
            loading: false,
            searchServidorByNome: null,
            comboboxServidores: [],
            headers: [
                {text: 'Nome', value: 'nm_servidor', sortable: false},
                {text: 'Carreira', value: 'nm_cargo', sortable: false},
                {text: 'CPF', value: 'nr_cpf', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ]
        }
    },
    watch: {
        searchServidorByNome: _.debounce(function (val) {
            val && val !== this.filtro.nm_servidor && this.getServidoresByNome(val)
        }, 600)
    },
    methods: {
        getServidoresByNome(nome) {
            this.loading = true
            const filtro = {nm_servidor: nome}
            getServidores(1, 30, filtro)
                .then(response => {
                    this.comboboxServidores = response.data.data.data
                })
                .finally(() => {
                    this.loading = false
                });
        },
        limparFiltro() {
            this.filtro = {
                'tb_servidor.id_cargo': null,
                nr_cpf: '',
                nm_servidor: null
            };
            this.filtrar()
        },
        filtrar() {
            this.filtro.nr_cpf = this.filtro.nr_cpf.replace(/[-.]/g, '');
            getServidores(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
    }

}
</script>
