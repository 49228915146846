<template>
    <v-form :disabled="disabled" ref="form">
        <h2 class="subtitle-1 mb-3 font-weight-bold">Solicitação de movimentação</h2>
        <h3 v-show="showInstrucoes" class="mb-3 subtitle-2">
            Verifique no check-list a seguir as pendências que precisam ser sanadas para que a solicitação de
            movimentação ao lado possa ter prosseguimento:
        </h3>
        <v-row>
            <v-col
                v-for="(item, key) in itemsCheckLists"
                :key="key"
                cols="12"
                class="d-flex flex-wrap align-center justify-space-between mt-n4"
            >
                <span class="col-12 col-lg-5 col-xl-6 pa-0">{{ item.title }}</span>
                <div class="d-flex align-center">
                    <v-radio-group
                        @change="salvar"
                        v-model="form[item.model]"
                        :rules="validate('required', '')"
                        row
                    >
                        <v-radio label="OK" :value="true"></v-radio>
                        <v-radio label="Não" :value="false"></v-radio>
                    </v-radio-group>
                    <v-btn @click="showObservacao(item)" icon>
                        <v-icon v-show="!item.showObservacao" :color="form[item.observacao] ? 'primary' : ''">
                            {{ form[item.observacao] ? 'mdi-comment-text' : 'mdi-comment-text-outline' }}
                        </v-icon>
                        <v-icon v-show="item.showObservacao">mdi-comment-off</v-icon>
                    </v-btn>
                </div>
                <v-expand-transition>
                    <div v-if="item.showObservacao" class="col-12 pa-0">
                        <VueEditor
                            class="col-12 pa-0 mb-3"
                            :disabled="disabled"
                            :editor-toolbar="customToolbar"
                            v-model="form[item.observacao]"
                        />
                        <div class="d-flex justify-end">
                            <v-btn :disabled="disabled" @click="salvar" outlined color="primary">
                                Salvar
                            </v-btn>
                        </div>
                    </div>
                </v-expand-transition>
            </v-col>
        </v-row>

        <h2 class="subtitle-1 mb-3 font-weight-bold">Documentos obrigatórios do órgão solicitante</h2>
        <v-row>
            <v-col
                v-for="(item, key) in itemsCheckListsDocumentos"
                :key="key"
                cols="12"
                class="d-flex flex-wrap align-center justify-space-between mt-n4"
            >
                <v-checkbox
                    class="col-12 col-lg-5 col-xl-6 pa-0"
                    :label="item.title + (form[item.obrigatorio] ? '*' : '')"
                    v-model="form[item.obrigatorio]"
                    @change="changeObrigatorio(item)"
                />
                <div class="d-flex align-center">
                    <v-radio-group
                        :disabled="!form[item.obrigatorio]"
                        @change="salvar"
                        v-model="form[item.model]"
                        :rules="form[item.obrigatorio] ? validate('required', '') : []"
                        row
                    >
                        <v-radio label="OK" :value="true"></v-radio>
                        <v-radio label="Não" :value="false"></v-radio>
                    </v-radio-group>
                    <v-btn :disabled="!form[item.obrigatorio]" @click="showObservacao(item)" icon>
                        <v-icon v-show="!item.showObservacao" :color="form[item.observacao] ? 'primary' : ''">
                            {{ form[item.observacao] ? 'mdi-comment-text' : 'mdi-comment-text-outline' }}
                        </v-icon>
                        <v-icon v-show="item.showObservacao">mdi-comment-off</v-icon>
                    </v-btn>
                </div>
                <v-expand-transition>
                    <div v-if="item.showObservacao && form[item.obrigatorio]" class="col-12 pa-0">

                        <VueEditor
                            class="col-12 pa-0  mb-3"
                            :disabled="disabled"
                            :editor-toolbar="customToolbar"
                            v-model="form[item.observacao]"
                        />

                        <div class="d-flex justify-end">
                            <v-btn :disabled="disabled" @click="salvar" outlined color="primary">
                                Salvar
                            </v-btn>
                        </div>
                    </div>
                </v-expand-transition>
            </v-col>
        </v-row>
        <h3 v-show="showInstrucoes" class="mt-3 subtitle-2">
            Para atualizar as informações na Solicitação de Movimentação, navegue nas opções disponíveis do lado
            direito da página, anexe os documentos pendentes, e depois registre que você sanou  todas as pendências.
        </h3>
        <div class="d-flex mt-10">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :disabled="disableEnviar"
                @click="openDialog"
            >
                Enviar
            </v-btn>
        </div>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>
                    {{ this.showInstrucoes ? 'Enviar Aceite' : 'Enviar Análise' }}
                </v-card-title>
                <v-card-text>
                    {{ this.showInstrucoes ? 'Você sanou todas as pendências dessa solicitação de movimentação ?' : 'Deseja confirmar a mudança de status da solicitação e enviar notificação ao órgão solicitante ?' }}
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="enviar"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import {enviarAnaliseOrgao, registrarAceite, updateAnaliseChecklistOrgao} from "../../api/analise-solicitacao";
import {analiseChecklistMixin} from "../../mixins/analiseChecklistMixin";
import { VueEditor } from "vue2-editor";

export default {
    name: "AnaliseSolicitacaoOrgao",
    mixins: [analiseChecklistMixin],
    props: {
        disableEnviar: {
            type: Boolean,
            default: false
        }
    },
    components: { VueEditor },
    data() {
        return {
            salvarFunction: updateAnaliseChecklistOrgao,
            itemsCheckLists: [
                {
                    title: 'Identificação do servidor*',
                    model: 'checklist_orgao_identificacao_servidor',
                    observacao: 'observacao_orgao_identificacao_servidor',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Identificação do órgão solicitante*',
                    model: 'checklist_orgao_identificacao_solicitante',
                    observacao: 'observacao_orgao_identificacao_solicitante',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Contextualização*',
                    model: 'checklist_orgao_contextualizacao',
                    observacao: 'observacao_orgao_contextualizacao',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Projeto*',
                    model: 'checklist_orgao_projeto',
                    observacao: 'observacao_orgao_projeto',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Cargo ou função*',
                    model: 'checklist_orgao_cargo_funcao',
                    observacao: 'observacao_orgao_cargo_funcao',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Chefia imediata*',
                    model: 'checklist_orgao_chefia_imediata',
                    observacao: 'observacao_orgao_chefia_imediata',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Informações qualitativas*',
                    model: 'checklist_orgao_informacoes_qualitativas',
                    observacao: 'observacao_orgao_informacoes_qualitativas',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
                {
                    title: 'Pactuação de resultados*',
                    model: 'checklist_orgao_pactuacao_resultados',
                    observacao: 'observacao_orgao_pactuacao_resultados',
                    observacaoPadrao: null,
                    showObservacao: false,
                }
            ],
            itemsCheckListsDocumentos: [
                {
                    title: 'Ofício de solicitação',
                    obrigatorio: 'obrigatorio_orgao_oficio_solicitacao',
                    model: 'checklist_orgao_oficio_solicitacao',
                    observacao: 'observacao_orgao_oficio_solicitacao',
                    observacaoPadrao: '<p>na Aba Anexos da Solicitação de Movimentação, anexe a cópia em pdf do Ofício de solicitação da movimentação da servidora ou do servidor assinado por uma das seguintes autoridades:</p><p><br></p><ul><li>Titular de Secretaria Executiva de Ministério (ou Adjunto ou Chefia de Gabinete); ou</li><li>Dirigente máximo de Autarquia ou Fundação Pública Federal (ou Adjunto ou Chefia de Gabinete).</li></ul>',
                    showObservacao: false,
                },
                {
                    title: 'Anuência do órgão de exercício',
                    obrigatorio: 'obrigatorio_orgao_anuencia',
                    model: 'checklist_orgao_anuencia',
                    observacao: 'observacao_orgao_anuencia',
                    observacaoPadrao: '<p>na Aba Anexos da Solicitação de Movimentação, anexe a cópia em pdf da Anuência (ofício ou e-mail) do atual órgão de exercício da servidora ou do servidor, dada por uma das seguintes autoridades:</p><p><br></p><ul><li>Titular de Secretaria Executiva de Ministério (ou Adjunto ou Chefia de Gabinete); ou</li><li>Dirigente máximo de Autarquia ou Fundação Pública Federal (ou Adjunto ou Chefia de Gabinete); ou</li><li>Titular de Secretaria do Ministério da Gestão e da Inovação em Serviços Públicos (ou Adjunto ou Chefia de Gabinete), quando EPPGG se encontra em exercício no MGI.</li></ul>',
                    showObservacao: false,
                },
                {
                    title: 'Organograma',
                    obrigatorio: 'obrigatorio_orgao_organograma',
                    model: 'checklist_orgao_organograma',
                    observacao: 'observacao_orgao_organograma',
                    observacaoPadrao: '<p>na Aba Anexos da Solicitação de Movimentação, anexe a cópia em pdf do Organograma do órgao ou da entidade.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Outros',
                    obrigatorio: 'obrigatorio_orgao_outros',
                    model: 'checklist_orgao_outros',
                    observacao: 'observacao_orgao_outros',
                    observacaoPadrao: '<p>na Aba Anexos da Solicitação de Movimentação, anexe a cópia em pdf:</p><p><br></p><p>(Se a unidade de exercício será a Seges): de e-mail convidando a servidora ou o servidor para a unidade enviado pela/o Titular da Diretoria ou Chefia de Gabinete.</p><p><br></p><p>(Se a unidade de exercício será outra Secretaria ou departamento do MGI): de e-mail convidando a servidora ou o servidor para a unidade enviado pela/o Titular da Diretoria ou Chefia de Gabinete.</p>',
                    showObservacao: false,
                },
            ],
            form: {
                checklist_orgao_identificacao_servidor: null,
                observacao_orgao_identificacao_servidor: null,
                checklist_orgao_identificacao_solicitante: null,
                observacao_orgao_identificacao_solicitante: null,
                checklist_orgao_contextualizacao: null,
                observacao_orgao_contextualizacao: null,
                checklist_orgao_projeto: null,
                observacao_orgao_projeto: null,
                checklist_orgao_cargo_funcao: null,
                observacao_orgao_cargo_funcao: null,
                checklist_orgao_chefia_imediata: null,
                observacao_orgao_chefia_imediata: null,
                checklist_orgao_informacoes_qualitativas: null,
                observacao_orgao_informacoes_qualitativas: null,
                checklist_orgao_pactuacao_resultados: null,
                observacao_orgao_pactuacao_resultados: null,

                obrigatorio_orgao_oficio_solicitacao: null,
                checklist_orgao_oficio_solicitacao: null,
                observacao_orgao_oficio_solicitacao: null,
                obrigatorio_orgao_anuencia: null,
                checklist_orgao_anuencia: null,
                observacao_orgao_anuencia: null,
                obrigatorio_orgao_organograma: null,
                checklist_orgao_organograma: null,
                observacao_orgao_organograma: null,
                obrigatorio_orgao_outros: null,
                checklist_orgao_outros: null,
                observacao_orgao_outros: null,

                st_orgao_revisao_enviada: false,
            }
        }
    },
    created() {
        this.form.checklist_orgao_identificacao_servidor = this.analiseSolicitacao.checklist_orgao_identificacao_servidor;
        this.form.observacao_orgao_identificacao_servidor = this.analiseSolicitacao.observacao_orgao_identificacao_servidor;
        this.form.checklist_orgao_identificacao_solicitante = this.analiseSolicitacao.checklist_orgao_identificacao_solicitante;
        this.form.observacao_orgao_identificacao_solicitante = this.analiseSolicitacao.observacao_orgao_identificacao_solicitante;
        this.form.checklist_orgao_contextualizacao = this.analiseSolicitacao.checklist_orgao_contextualizacao;
        this.form.observacao_orgao_contextualizacao = this.analiseSolicitacao.observacao_orgao_contextualizacao;
        this.form.checklist_orgao_projeto = this.analiseSolicitacao.checklist_orgao_projeto;
        this.form.observacao_orgao_projeto = this.analiseSolicitacao.observacao_orgao_projeto;
        this.form.checklist_orgao_cargo_funcao = this.analiseSolicitacao.checklist_orgao_cargo_funcao;
        this.form.observacao_orgao_cargo_funcao = this.analiseSolicitacao.observacao_orgao_cargo_funcao;
        this.form.checklist_orgao_chefia_imediata = this.analiseSolicitacao.checklist_orgao_chefia_imediata;
        this.form.observacao_orgao_chefia_imediata = this.analiseSolicitacao.observacao_orgao_chefia_imediata;
        this.form.checklist_orgao_informacoes_qualitativas = this.analiseSolicitacao.checklist_orgao_informacoes_qualitativas;
        this.form.observacao_orgao_informacoes_qualitativas = this.analiseSolicitacao.observacao_orgao_informacoes_qualitativas;
        this.form.checklist_orgao_pactuacao_resultados = this.analiseSolicitacao.checklist_orgao_pactuacao_resultados;
        this.form.observacao_orgao_pactuacao_resultados = this.analiseSolicitacao.observacao_orgao_pactuacao_resultados;
        this.form.obrigatorio_orgao_oficio_solicitacao = this.analiseSolicitacao.obrigatorio_orgao_oficio_solicitacao;
        this.form.checklist_orgao_oficio_solicitacao = this.analiseSolicitacao.checklist_orgao_oficio_solicitacao;
        this.form.observacao_orgao_oficio_solicitacao = this.analiseSolicitacao.observacao_orgao_oficio_solicitacao;
        this.form.obrigatorio_orgao_anuencia = this.analiseSolicitacao.obrigatorio_orgao_anuencia;
        this.form.checklist_orgao_anuencia = this.analiseSolicitacao.checklist_orgao_anuencia;
        this.form.observacao_orgao_anuencia = this.analiseSolicitacao.observacao_orgao_anuencia;
        this.form.obrigatorio_orgao_organograma = this.analiseSolicitacao.obrigatorio_orgao_organograma;
        this.form.checklist_orgao_organograma = this.analiseSolicitacao.checklist_orgao_organograma;
        this.form.observacao_orgao_organograma = this.analiseSolicitacao.observacao_orgao_organograma;
        this.form.obrigatorio_orgao_outros = this.analiseSolicitacao.obrigatorio_orgao_outros;
        this.form.checklist_orgao_outros = this.analiseSolicitacao.checklist_orgao_outros;
        this.form.observacao_orgao_outros = this.analiseSolicitacao.observacao_orgao_outros;

        if (this.showInstrucoes) {
            this.form.st_orgao_revisao_enviada = true;
        }

    },
    computed: {
        enviarFunction() {
            return this.showInstrucoes ? registrarAceite : enviarAnaliseOrgao
        },
        customToolbar() {
            if (!this.disabled) {
                return [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"]
                ]
            }
            return [[]]
        }
    },
    methods: {
        showObservacao(item) {
            item.showObservacao = !item.showObservacao
            if (
                item.showObservacao &&
                item.observacaoPadrao &&
                this.form[item.model] === false &&
                !this.form[item.observacao]
            ) {
                this.form[item.observacao] = item.observacaoPadrao
            }
        }
    },
}
</script>
