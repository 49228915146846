<template>
    <v-card outlined>
        <v-card-text>
            <PactuacaoResultadoFields
                type="solicitacao_movimentacao"
                :model="solicitacao"
                :disabled="disabled"
                @update:items="items = $event"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="disabled" outlined @click="$emit('previousStep')">voltar</v-btn>
            <v-btn disabled v-if="items.length < 3 || !validaPrazoSegundoAnoSubsequente">
                <span v-show="items.length < 3">Cadastre, pelo menos, +{{
                        3 - items.length
                    }} resultado(s)</span>
                <span v-show="items.length >= 3 && !validaPrazoSegundoAnoSubsequente">Cadastre, pelo menos, 1 resultado para o segundo ano subsequente</span>
            </v-btn>
            <v-btn :disabled="disabled" color="primary" @click="$emit('nextStep')" v-else>avançar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import PactuacaoResultadoFields from "../FormInputs/PactuacaoResultadoFields.vue";

export default {
    name: "SolicitacaoPactuacao",
    components: {PactuacaoResultadoFields},
    props: ['disabled', 'solicitacao'],
    data() {
        return {
            items: [],
        }
    },
    computed: {
        validaPrazoSegundoAnoSubsequente() {
            return this.items.some(item => {
                const ano = item.dt_prazo.split('-')[0];
                const mes = item.dt_prazo.split('-')[1];
                const dia = item.dt_prazo.split('-')[2];
                const date = new Date(ano, (mes - 1), dia);

                const createdDate = new Date(this.solicitacao.created_at);
                return date.getFullYear() >= (createdDate.getFullYear() + 2);
            });
        }
    },
}
</script>
