<template>
    <v-autocomplete
        :label="label"
        outlined
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :error-messages="error"
        :items="areasAtuacao"
        :filter="filterNormalize"
        item-value="id_area_atuacao"
        item-text="nm_area_atuacao"
        multiple
        chips
    >
        <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
            >
                <v-icon
                    small
                    @click="parent.selectItem(item)"
                >
                    $delete
                </v-icon>
                <span class="pl-2">{{ item.nm_area_atuacao }}</span>
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import {getAreasAtuacao} from "../../api/area_atuacao";

export default {
    name: "AreaAtuacaoAutocomplete",
    mixins: [filterNormalizeMixin],
    props: {
        label: {
            type: String,
            default: 'Área de Atuação'
        },
        value: {
            type: Array,
        },
        rules: {
            type: Array,
            default: () => []
        },
        error: {
            type: String|Array,
            default: () => []
        },
        stTipoInteresses: {
            type: Boolean,
            default: false
        },
        stTipoHistorico: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            areasAtuacao: []
        }
    },
    created() {
        let params = {}
        if (this.stTipoInteresses) {
            params.st_tipo_interesses = true
        } else if (this.stTipoHistorico) {
            params.st_tipo_historico = true
        }

        getAreasAtuacao(params).then(response => this.areasAtuacao = response.data.data)
    }
}
</script>
