<template>
    <v-sheet class="pa-4 mb-5" rounded outlined>
        <h2 class="subtitle-1 mb-3 font-weight-bold">{{ title }}</h2>
        <v-row>
            <v-col cols="12" md="8">
                <v-text-field
                    :label="field_name_label"
                    outlined
                    dense
                    :value="form[field_name_value]"
                    @input="$emit('update:form', {...form, [field_name_value]: $event})"
                    :rules="field_name_rules"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    :label="field_email_label"
                    outlined
                    dense
                    :value="form[field_email_value]"
                    @input="$emit('update:form', {...form, [field_email_value]: $event})"
                    :rules="field_email_rules.length ? field_email_rules : validate('email', 'email')"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
                <v-text-field
                    :label="field_cargo_label"
                    outlined
                    dense
                    v-model="form[field_cargo_value]"
                    @input="$emit('update:form', {...form, [field_cargo_value]: $event})"
                    :rules="field_cargo_rules"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    :label="field_telefone_label"
                    outlined
                    dense
                    :value="form[field_telefone_value]"
                    @input="$emit('update:form', {...form, [field_telefone_value]: $event})"
                    :rules="field_telefone_rules"
                    v-mask="!!form[field_telefone_value] && form[field_telefone_value].length < 15 ?  '(##) ####-#####' : '(##) #####-####'"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";

export default {
    name: "ChefiaImediataFields",
    mixins: [validationMixin],
    props: {
        title: {
            type: String,
            default: 'Chefia Imediata'
        },

        form: {
            type: Object,
            required: true
        },

        field_name_value: {
            type: String,
            default: "nm_chefia_imediata"
        },
        field_name_label: {
            type: String,
            default: "Nome"
        },
        field_name_rules: {
            type: Array,
            default: () => []
        },

        field_email_value: {
            type: String,
            default: "ds_email_chefia_imediata"
        },
        field_email_label: {
            type: String,
            default: "Email"
        },
        field_email_rules: {
            type: Array,
            default: () => []
        },

        field_cargo_value: {
            type: String,
            default: "nm_cargo_chefia_imediata"
        },
        field_cargo_label: {
            type: String,
            default: "Cargo"
        },
        field_cargo_rules: {
            type: Array,
            default: () => []
        },

        field_telefone_value: {
            type: String,
            default: "ds_telefone_chefia_imediata"
        },
        field_telefone_label: {
            type: String,
            default: "Telefone"
        },
        field_telefone_rules: {
            type: Array,
            default: () => []
        },
    },
}
</script>
