require('./bootstrap');

import Vue from 'vue';

import { router } from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask'
import VueNumeric from 'vue-numeric';

import MainApp from './components/MainApp.vue';

Vue.use(VueTheMask);
Vue.use(VueNumeric);
Vue.use(require('vue-moment'));

const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    components: {
        MainApp
    }
});
