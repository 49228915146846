<template>
    <v-data-iterator
        :items="items"
        :items-per-page="pagination.itemsPerPage"
        :page="pagination.page"
        :footer-props="footerProps"
        :options.sync="pagination"
        @update:options="filtrar()"
        :server-items-length="pagination.total"
    >
        <template v-slot:default="props">
            <v-row>
                <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    class="pa-2"
                    v-for="item in props.items" :key="item.id_processo_seletivo"
                >
                    <v-hover v-slot="{ hover }">
                        <OportunidadeProcessoCard
                            :oportunidade-movimetacao="item"
                            :disabled="disableCard(item.id_processo_seletivo)"
                            :to="setLink(item.id_processo_seletivo)"
                            :show-inscritos-info="!!id_usuario_solicitante"
                            :elevation="hover ? 5 : 2"
                            outlined
                        />
                    </v-hover>
                </v-col>
            </v-row>
        </template>
    </v-data-iterator>
</template>

<script>
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {getOportunidadesMovimentacoes} from "../../api/oportunidade-movimentacao";
import OportunidadeProcessoCard from "./OportunidadeProcessoCard.vue";

export default {
    name: "OportunidadesProcessosListar",
    components: {OportunidadeProcessoCard},
    props: ['id_status_processo_seletivo', 'id_cargo', 'id_usuario_solicitante'],
    mixins: [dataTableMixin],
    data() {
        return {
            items: [],
            pagination: {
                itemsPerPage: 6,
                sortBy: ['dt_publicacao'],
                sortDesc: [true],
            },
            footerProps: {'items-per-page-options':[6, 12, 24]},
        }
    },
    computed: {
        routeName() {
            return !!this.id_usuario_solicitante ? 'processo-seletivo-form' : 'oportunidade-movimentacao-form';
        }
    },
    methods: {
        disableCard(id_processo_seletivo) {
            return this.$route.name === 'processo-seletivo-form' && id_processo_seletivo === this.$route.params.processo;
        },
        filtrar() {
            this.handlerPaginatePromise(
                getOportunidadesMovimentacoes(
                    this.pagination,
                    {
                        'id_status_processo_seletivo': this.id_status_processo_seletivo,
                        'id_cargo': this.id_cargo,
                        'id_usuario_solicitante': this.id_usuario_solicitante
                    }
                )
            );
        },
        setLink(id_processo_seletivo) {
            return {
                name: this.routeName,
                params: {
                    processo: id_processo_seletivo
                }
            }
        }
    },
}
</script>
