import api from './index';

const getTermoPolitica = () => api.get('termo-politica/first');

const updateTermoPolitica = (dados) => api.put('termo-politica/first', dados);

export {
    getTermoPolitica,
    updateTermoPolitica
}
