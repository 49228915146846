<template>
    <v-dialog
        :value="dialogTermoUso"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title>{{ dados.ds_titulo }} - Versão {{ dados.nr_versao }} ({{ dados.dt_termo_politica }})</v-card-title>
            <v-card-text>
                <div v-html="dados.ds_descricao"></div>
                <v-checkbox v-model="enableButton">
                    <template v-slot:label>
                        <div>
                            Aceito os
                            <a target="_blank"
                               :href="dados.url_termo_politica"
                               @click.stop>
                                Termos de Uso e a Aviso de Privacidade
                            </a>
                            do Sistema de Gestão de Carreiras.
                        </div>
                    </template>
                </v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    large
                    :disabled="!enableButton"
                    @click="aceitarTermoUso()"
                >
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {putAceitarTermoUso} from "../api/usuario";
import {getTermoPolitica} from "../api/termo-politica";
import moment from 'moment';

export default {
    name: "DialogTermoUso",
    props: {
        show: Boolean,
        preview: Object,
    },
    data() {
        return {
            dialogTermoUso: false,
            enableButton: false,
            dados: {}
        }
    },
    created() {
        this.dialogTermoUso = this.show
        if (_.isEmpty(this.preview)) {
            getTermoPolitica().then(response => this.dados = {
                ds_titulo: response.data.data.ds_titulo,
                nr_versao: response.data.data.nr_versao,
                ds_descricao: response.data.data.ds_descricao,
                url_termo_politica: response.data.data.url_termo_politica,
                dt_termo_politica: moment(response.data.data.dt_termo_politica).format("DD/MM/YYYY"),
            })
        } else {
            this.dados = {
                ds_titulo: this.preview.ds_titulo,
                nr_versao: this.preview.nr_versao,
                ds_descricao: this.preview.ds_descricao,
                url_termo_politica: this.preview.url_termo_politica,
                dt_termo_politica: moment(this.preview.dt_termo_politica).format("DD/MM/YYYY"),
            }
        }
    },
    methods: {
        aceitarTermoUso() {
            if (_.isEmpty(this.preview)) {
                putAceitarTermoUso().then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
            } else {
                this.$emit('closePreview')
            }
            this.dialogTermoUso = false
        }
    },
}
</script>

<style scoped>

</style>
