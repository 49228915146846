import api from './index';

const getPactuacoes = (id = null, type = null, page, itemsPerPage) => api.get('pactuacao-resultado', {
    params: {
        id: id,
        type: type,
        page: page,
        limit: itemsPerPage,
    }
});
const getPactuacao = (id_pactuacao) => api.get('pactuacao-resultado/' + id_pactuacao);
const createPactuacao = (dados) => api.post('pactuacao-resultado', dados);
const updatePactuacao = (id_pactuacao, dados) => api.put('pactuacao-resultado/' + id_pactuacao, dados);
const deletePactuacao = (id_pactuacao) => api.delete('pactuacao-resultado/' + id_pactuacao);

export {
    getPactuacoes,
    getPactuacao,
    createPactuacao,
    updatePactuacao,
    deletePactuacao,
}
