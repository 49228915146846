import api from './index';

const getCriteriosDiversidades = () => api.get('criterio-diversidade');
const createCriterioDiversidade = (dados) => api.post('criterio-diversidade', dados);
const updateCriterioDiversidade = (criterio_diversidade_id, dados) => api.put(`criterio-diversidade/${criterio_diversidade_id}`, dados);
const deleteCriterioDiversidade = (criterio_diversidade_id) => api.delete(`criterio-diversidade/${criterio_diversidade_id}`);

export {
    getCriteriosDiversidades,
    createCriterioDiversidade,
    updateCriterioDiversidade,
    deleteCriterioDiversidade
}
