<template>
    <v-select
        :label="label"
        outlined
        dense
        :disabled="disabled"
        clearable
        :value="value"
        :multiple="multiple"
        @input="$emit('input', $event)"
        :rules="rules"
        :items="carreiras"
        item-value="id"
        item-text="sigla"
    />
</template>

<script>
import {getCargos} from "../../api/cargo";

export default {
    name: "CarreiraSelect",
    props: {
        label: {
            type: String,
            default: 'Carreira'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number|Array,
        },
        multiple: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        },
        groupCarreiras: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            carreiras: [],
        }
    },
    created() {
        if (this.groupCarreiras) {
            this.carreiras = [
                {id: 1, sigla: 'EPPGG'},
                {id: 2, sigla: 'ACE'},
                {id: 3, sigla: 'AIE / EIS'},
            ]
        } else {
            getCargos().then(response => this.carreiras = response.data.data);
        }
    }
}
</script>
