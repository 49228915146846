<template>
    <div class="text-center">

        <v-dialog
            v-model="dialog_progress"

            persistent
            width="300"
        >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                Aguarde. Carregando.....
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    export default {
        name: 'Loader',
        data () {
            return {
                dialog_progress: true,
            }
        },
        watch: {
            dialog_progress (val) {
                if (!val) return
            },
        },
    }
</script>
