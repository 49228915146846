<template>
    <div>
        <ficha-historico-listar v-if="view === 'listar'" :servidor-id="servidor.id" @view="changeView"/>
        <ficha-historico-form v-else-if="view === 'form'" :servidor-id="servidor.id" :cargo-id="servidor.cargo.id" :historico="historico"  @view="changeView" />
        <ficha-historico-anexo v-else-if="view === 'anexo' && !!historico.id_historico" :historico="historico" :return-to-view="returnToView" @view="changeView" />
        <ficha-historico-pactuacao v-else-if="view === 'pactuacao' && !!historico.id_historico" :historico="historico" :return-to-view="returnToView" @view="changeView" />
    </div>
</template>

<script>
import FichaHistoricoListar from "./FichaHistoricoListar";
import FichaHistoricoForm from "./FichaHistoricoForm";
import FichaHistoricoAnexo from "./FichaHistoricoAnexo";
import FichaHistoricoPactuacao from "./FichaHistoricoPactuacao";
export default {
    name: "FichaHistorico",
    components: {FichaHistoricoPactuacao, FichaHistoricoAnexo, FichaHistoricoForm, FichaHistoricoListar},
    props: {
        servidor: {
            type: Object
        },
        returnToListar: false,
    },
    data() {
        return {
            historico: {},
            view: 'listar',
            returnToView: 'listar'
        }
    },
    watch: {
        returnToListar(value) {
            if (value) {
                this.view = 'listar'
            }
        }
    },
    methods: {
        changeView(view, historico, returnToView) {
            this.view = view;
            this.historico = !!historico ? historico : {};
            this.returnToView = !!returnToView ? returnToView : 'listar';
        }
    },
}
</script>

<style scoped>

</style>
