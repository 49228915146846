<template>
    <div>
        <v-form ref="form" :disabled="!form.st_cadastrado_manual">
            <h1 class="text-h5 my-4">
                Cadastro de Órgão
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Dados do Órgão</h2>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Esfera *"
                            outlined
                            dense
                            v-model="form.id_esfera"
                            :items="itemsEsfera"
                            item-text="nm_esfera"
                            item-value="id_esfera"
                            :rules="validate('required', 'esfera')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Poder *"
                            outlined
                            dense
                            v-model="form.id_poder"
                            :items="itemsPoder"
                            item-text="nm_poder"
                            item-value="id_poder"
                            :rules="validate('required', 'poder')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Nome do Órgão ou Entidade *"
                            outlined
                            dense
                            v-model="form.nm_unidade"
                            :rules="validate('required', 'nome do órgão ou entidade')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Sigla do Órgao ou Entidade *"
                            outlined
                            dense
                            v-model="form.sg_unidade"
                            :rules="validate('required', 'sigla do órgao ou entidade')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            label="Status *"
                            outlined
                            dense
                            v-model="form.st_ativo"
                            :items="[
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'},
                            ]"
                            :rules="validate('required', 'status')"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            label="País *"
                            outlined
                            dense
                            v-model="form.id_pais"
                            :rules="validate('required', 'país')"
                            :items="itemsPaises"
                            item-value="id_pais"
                            item-text="nm_pais"
                            @change="limparCamposEndereco()"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-autocomplete
                            v-if="form.id_pais == 21"
                            label="Estado *"
                            outlined
                            dense
                            clearable
                            v-model="form.ds_estado"
                            :rules="validate('required', 'estado')"
                            :items="['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI',
                                'RJ','RN','RS','RO','RR','SC','SP','SE','TO']"
                            @change="form.nm_cidade = null"
                        ></v-autocomplete>
                        <v-text-field
                            v-else
                            label="Estado *"
                            outlined
                            @keyup.enter="salvar()"
                            dense
                            v-model="form.ds_estado"
                            :rules="validate('required', 'estado')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-autocomplete
                            v-if="form.id_pais == 21"
                            label="Cidade *"
                            outlined
                            dense
                            clearable
                            v-model="form.nm_cidade"
                            :rules="validate('required', 'cidade')"
                            :items="itemsMunicipios"
                            item-value="nm_municipio"
                            item-text="nm_municipio"
                        ></v-autocomplete>
                        <v-text-field
                            v-else
                            label="Cidade *"
                            outlined
                            @keyup.enter="salvar()"
                            dense
                            v-model="form.nm_cidade"
                            :rules="validate('required', 'cidade')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="form.st_cadastrado_manual"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-divider v-if="$route.params.orgao" class="my-6"></v-divider>

        <SecretariaDepartamento v-if="$route.params.orgao" />
    </div>
</template>

<script>
import {enderecoMixin} from "../mixins/enderecoMixin";
import {createOrgao, getOrgao, updateOrgao} from "../api/orgao";
import SecretariaDepartamento from "../components/SecretariaDepartamento";
import {getEsferas} from "../api/esfera";
import {getPoderes} from "../api/poder";
import {validationMixin} from "../mixins/validationMixin";

export default {
    name: "Orgao",
    components: {SecretariaDepartamento},
    mixins: [enderecoMixin, validationMixin],
    data() {
        return {
            form: {
                st_ativo: true,
                id_pais: 21,
                st_cadastrado_manual: true
            },
            itemsEsfera: [],
            itemsPoder: [],
        }
    },
    created() {
        if (!!this.$route.params.orgao) {
            getOrgao(this.$route.params.orgao)
                .then(response => {
                    this.form = {...response.data.data}
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Erro',
                            titulo: 'Órgão não encontrado.'
                        })
                        this.$router.push({name: 'orgao-listar'})
                    }
                })
        }
        getEsferas().then(response => this.itemsEsfera = response.data.data)
        getPoderes().then(response => this.itemsPoder = response.data.data)
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                let promise;
                if (!!this.$route.params.orgao) {
                    promise = updateOrgao(this.$route.params.orgao, this.form)
                } else {
                    promise = createOrgao(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    if (!!this.$route.params.orgao) {
                        this.$router.push({name: 'orgao-listar'})
                    } else {
                        this.$router.push({name: 'orgao-form', params: {orgao: response.data.data.id_unidade_siorg}})
                    }
                })
            }
        },
    },
}
</script>
