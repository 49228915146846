<template>
    <v-form :disabled="disabled" ref="form">
        <v-card outlined>
            <v-card-text>
                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Detalhamento</h2>
                    <OrgaoSecretariaUnidadeFields
                        :form="formOrgaoSecretariaUnidade"
                        @update:form="updateForm"
                    />
                    <CidadeEstadoPaisFields
                        :form="{id_pais: form.id_pais, ds_estado: form.ds_estado, nm_cidade: form.nm_cidade}"
                        @update:form="(index, value) => form[index] = value"
                    />
                </v-sheet>

                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Contextualização</h2>
                    <p>Informe breve contexto sobre a unidade de exercício, principais desafios etc.</p>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                label="Contextualização *"
                                outlined
                                dense
                                v-model="form.ds_contextualizacao"
                                :rules="validate('required|max:5000', 'contextualização')"
                                counter
                                maxlength="5000"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-sheet>

                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Projeto</h2>
                    <p>Nome do principal projeto em que haverá contribuição do servidor</p>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Nome do projeto ou política pública *"
                                outlined
                                dense
                                v-model="form.nm_projeto_politica_publica"
                                :rules="validate('required', 'nome do projeto ou política pública')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-sheet>

                <CargoFuncaoFields :form="form" @update:form="form = $event"/>

                <ChefiaImediataFields
                    :form="form"
                    @update:form="form = $event"

                    field_name_label="Nome *"
                    :field_name_rules="validate('required', 'nome da chefia imediata')"

                    field_cargo_label="Cargo *"
                    :field_cargo_rules="validate('required', 'cargo da chefia imediata')"

                    field_email_label="Email *"
                    :field_email_rules="validate('required|email', 'email da chefia imediata')"

                    field_telefone_label="Telefone *"
                    :field_telefone_rules="validate('required', 'telefone da chefia imediata')"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="disabled" outlined @click="$emit('previousStep')">voltar</v-btn>
                <v-btn :disabled="disabled" color="primary" @click="salvar">Avançar</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";
import {orgaoSecretariaUnidadeMixin} from "../../mixins/orgaoSecretariaUnidadeMixin";
import {updateSolicitacaoOrgaoSolitante} from "../../api/solicitacao-movimentacao";
import {enderecoMixin} from "../../mixins/enderecoMixin";
import ChefiaImediataFields from "../FormInputs/ChefiaImediataFields.vue";
import CargoFuncaoFields from "../FormInputs/CargoFuncaoFields.vue";
import OrgaoEntidadeSIORGAutocomplete from "../FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
import SecretariaDepartamentoSIORGAutocomplete from "../FormInputs/SecretariaDepartamentoSIORGAutocomplete.vue";
import OrgaoSecretariaUnidadeFields from "../FormInputs/OrgaoSecretariaUnidadeFields.vue";
import CidadeEstadoPaisFields from "../FormInputs/CidadeEstadoPaisFields.vue";

export default {
    name: "SolicitacaoOrgaoSolicitante",
    components: {
        CidadeEstadoPaisFields,
        OrgaoSecretariaUnidadeFields,
        SecretariaDepartamentoSIORGAutocomplete,
        OrgaoEntidadeSIORGAutocomplete, CargoFuncaoFields, ChefiaImediataFields},
    mixins: [validationMixin, orgaoSecretariaUnidadeMixin, enderecoMixin],
    props: ['disabled', 'solicitacao'],
    data() {
        return {
            form: {
                id_pais: 21,
                ds_estado: null,
                nm_cidade: null,
            },
        }
    },
    created() {
        this.form = {
            id_orgao: this.solicitacao.id_orgao,
            nm_orgao: this.solicitacao.nm_orgao,
            id_secretaria_departamento_exercicio: this.solicitacao.id_secretaria_departamento_exercicio,
            nm_secretaria_departamento_exercicio: this.solicitacao.nm_secretaria_departamento_exercicio,
            nm_unidade_exercicio: this.solicitacao.nm_unidade_exercicio,
            id_pais: this.solicitacao.id_pais ? this.solicitacao.id_pais : 21,
            ds_estado: this.solicitacao.ds_estado,
            nm_cidade: this.solicitacao.nm_cidade,
            ds_contextualizacao: this.solicitacao.ds_contextualizacao,
            nm_projeto_politica_publica: this.solicitacao.nm_projeto_politica_publica,
            nm_cargo_funcao: this.solicitacao.nm_cargo_funcao,
            id_funcao: this.solicitacao.id_funcao,
            id_nivel: this.solicitacao.id_nivel,
            nm_chefia_imediata: this.solicitacao.nm_chefia_imediata,
            ds_email_chefia_imediata: this.solicitacao.ds_email_chefia_imediata,
            nm_cargo_chefia_imediata: this.solicitacao.nm_cargo_chefia_imediata,
            ds_telefone_chefia_imediata: this.solicitacao.ds_telefone_chefia_imediata,
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                updateSolicitacaoOrgaoSolitante(this.solicitacao.id_solicitacao_movimentacao, this.form).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    this.$emit('nextStep');

                    this.$vuetify.goTo(0)
                });
            }
        }
    },
}
</script>
