<template>
    <div>
        <h1 class="text-h5 my-4">
            {{ title }}
        </h1>
        <div v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])">
            <v-tabs v-model="tab" fixed-tabs center-active>
                <v-tab v-for="(item, key) in items" :key="key" :disabled="!analiseSolicitacao">
                    {{ item.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, key) in items" :key="key">
                    <v-sheet
                        class="pa-md-4"
                        :outlined="$vuetify.breakpoint.mdAndUp"
                        rounded
                    >
                        <component :is="item.component" v-bind="item.props" v-on="item.event"/>
                    </v-sheet>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-else-if="analiseSolicitacao && solicitacao.id_servidor === $store.getters['auth/usuarioAutenticadoServidor']">
            <v-sheet
                class="pa-md-4"
                :outlined="$vuetify.breakpoint.mdAndUp"
                rounded
            >
                <AnaliseSolicitacaoServidor
                    :analiseSolicitacao="analiseSolicitacao"
                    :id_status_solicitacao="idStatusSolicitacao"
                    :showInstrucoes="true"
                />
            </v-sheet>
        </div>
        <div v-else-if="analiseSolicitacao">
            <v-sheet
                class="pa-md-4"
                :outlined="$vuetify.breakpoint.mdAndUp"
                rounded
            >
                <AnaliseSolicitacaoOrgao
                    :analiseSolicitacao="analiseSolicitacao"
                    :id_status_solicitacao="idStatusSolicitacao"
                    :showInstrucoes="true"
                    :disableEnviar="disableBtnEnviar"
                />
            </v-sheet>
        </div>
    </div>
</template>

<script>
import AnaliseSolicitacaoGeral from "./AnaliseSolicitacaoGeral.vue";
import AnaliseSolicitacaoOrgao from "./AnaliseSolicitacaoOrgao.vue";
import AnaliseSolicitacaoServidor from "./AnaliseSolicitacaoServidor.vue";
import {getAnaliseSolicitacao} from "../../api/analise-solicitacao";

export default {
    name: "AnaliseSolicitacao",
    components: {AnaliseSolicitacaoServidor, AnaliseSolicitacaoOrgao},
    props: ['solicitacao'],
    data() {
        return {
            tab: 0,
            analiseSolicitacao: null,
            idStatusSolicitacao: null,
            items: [
                {
                    title: 'Geral',
                    component: AnaliseSolicitacaoGeral,
                    props: {
                        analiseSolicitacao: null,
                        id_cargo: this.solicitacao.servidor.id_cargo,
                        id_solicitacao: this.solicitacao.id_solicitacao_movimentacao,
                        id_status_solicitacao: this.solicitacao.id_status_solicitacao,
                        nm_servidor: this.solicitacao.servidor.nm_servidor,
                    },
                    event: {
                        'setAnaliseSolicitacao': (analiseSolicitacao) => this.analiseSolicitacao = analiseSolicitacao,
                        'setIdStatusSolicitacao': (idStatusSolicitacao) => this.idStatusSolicitacao = idStatusSolicitacao
                    }
                },
                {
                    title: 'Checklist Órgão',
                    component: AnaliseSolicitacaoOrgao,
                    props: {analiseSolicitacao: null, id_status_solicitacao: null, showInstrucoes: false},
                    event: {
                        'setIdStatusSolicitacao': (idStatusSolicitacao) => this.idStatusSolicitacao = idStatusSolicitacao
                    }

                },
                {
                    title: 'Checklist Servidor',
                    component: AnaliseSolicitacaoServidor,
                    props: {analiseSolicitacao: null, id_status_solicitacao: null, showInstrucoes: false},
                    event: {
                        'setIdStatusSolicitacao': (idStatusSolicitacao) => this.idStatusSolicitacao = idStatusSolicitacao
                    }
                },
            ]
        }
    },
    async created() {
        await getAnaliseSolicitacao(this.solicitacao.id_solicitacao_movimentacao).then(response => {
            this.analiseSolicitacao = response.data.data;
        });
        this.idStatusSolicitacao = this.solicitacao.id_status_solicitacao;
    },
    watch: {
        analiseSolicitacao: function (val) {
            this.items.forEach(item => {
                item.props.analiseSolicitacao = val;
            });
        },
        idStatusSolicitacao: function (val) {
            this.items.forEach(item => {
                item.props.id_status_solicitacao = val;
            });
            this.$emit('setIdStatusSolicitacao', val);
        }
    },
    computed: {
        title() {
            if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
                return 'Análise CGCAT';
            }

            return 'Revisar Solicitação de Movimentação';
        },
        disableBtnEnviar() {
            return this.solicitacao.id_usuario_solicitante !== this.$store.getters['auth/usuarioAutenticado'] &&
                !this.$store.getters['auth/usuarioPermissao'](['rh'])
        }
    },
}
</script>
