<template>
    <v-form :disabled="disabled" ref="form">
        <v-card outlined>
            <v-card-text>
                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Informações Qualitativas</h2>
                    <p>As informações qualitativas a seguir são essenciais para que possamos compreender melhor a
                        necessidade do seu órgão e possamos melhorar a gestão das carreiras supervisionadas pela Secretaria
                        de Gestão.
                    </p>
                    <v-row>
                        <v-col cols="12" md="">
                            <ModalidadeTrabalhoSelect
                                label="Modalidade de trabalho *"
                                v-model="form.id_modalidade_trabalho"
                                :rules="validate('required', 'modalidade de trabalho')"
                            />
                        </v-col>
                        <v-col cols="6" md="">
                            <v-select
                                label="Servidor(a) foi escolhido por processo seletivo divulgado pela Seges? *"
                                outlined
                                dense
                                v-model="form.st_processo_seletivo"
                                :items="[{value:true, text:'Sim'}, {value:false, text:'Não'}]"
                                :rules="validate('required', 'processo seletivo divulgado pela Seges')"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" md="">
                            <v-select
                                label="Coordenador de equipe"
                                outlined
                                dense
                                clearable
                                v-model="form.st_coordenador_equipe"
                                @change="form.nr_tamanho_equipe = null"
                                :items="[{value:true, text:'Sim'}, {value:false, text:'Não'}]"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" md="">
                            <v-text-field
                                label="Tamanho da equipe"
                                outlined
                                dense
                                :disabled="!form.st_coordenador_equipe"
                                type="number"
                                v-model="form.nr_tamanho_equipe"
                                :rules="validate('positive', 'tamanho da equipe')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <AreaAtuacaoAutocomplete
                                label="Área Atuação *"
                                v-model="form.lt_area_atuacao"
                                :rules="validate('required', 'area de atuação')"
                                st-tipo-historico
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <PoliticasPublicasAutocomplete
                                label="Políticas Públicas *"
                                v-model="form.lt_politicas_publicas"
                                :rules="validate('required', 'políticas públicas')"
                                st-tipo-historico
                            />
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="disabled" outlined @click="$emit('previousStep')">voltar</v-btn>
                <v-btn :disabled="disabled" color="primary" @click="salvar()">Avançar</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import {informacoesQualitativasMixin} from "../../mixins/informacoesQualitativasMixin";
import {validationMixin} from "../../mixins/validationMixin";
import {updateSolicitacaoInformacoesQualitativas} from "../../api/solicitacao-movimentacao";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import AreaAtuacaoAutocomplete from "../FormInputs/AreaAtuacaoAutocomplete.vue";
import PoliticasPublicasAutocomplete from "../FormInputs/PoliticasPublicasAutocomplete.vue";
import ModalidadeTrabalhoSelect from "../FormInputs/ModalidadeTrabalhoSelect.vue";

export default {
    name: "SolicitacaoInformacoesQualitativas",
    components: {ModalidadeTrabalhoSelect, PoliticasPublicasAutocomplete, AreaAtuacaoAutocomplete},
    mixins: [informacoesQualitativasMixin, validationMixin, filterNormalizeMixin],
    props: ['disabled', 'solicitacao'],
    created() {
        this.form.id_modalidade_trabalho = this.solicitacao.id_modalidade_trabalho;
        this.form.id_modalidade_trabalho = this.solicitacao.id_modalidade_trabalho;
        this.form.st_processo_seletivo = this.solicitacao.st_processo_seletivo;
        this.form.st_coordenador_equipe = this.solicitacao.st_coordenador_equipe;
        this.form.nr_tamanho_equipe = this.solicitacao.nr_tamanho_equipe;

        this.form.lt_area_atuacao = this.solicitacao.areas_atuacao.map((object) => object.id_area_atuacao)
        this.form.lt_politicas_publicas = this.solicitacao.politicas_publicas.map((object) => object.id_politicas_publicas)
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                updateSolicitacaoInformacoesQualitativas(this.solicitacao.id_solicitacao_movimentacao, this.form)
                    .then((response) => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        })

                        this.$emit('nextStep');

                        this.$vuetify.goTo(0)
                    });
            }
        }
    },
}
</script>
