import api from './index';

const addSolicitante = (dados) => api.post('solicitantes', dados);

const getSolicitantes = (id, type) => api.get('solicitantes', {
    params: {
        id,
        type
    }
});

const updateSolicitantePrincipal = (id_usuario, dados) => api.put(`solicitantes/${id_usuario}`, dados);

const removerSolicitante = (id_usuario, id, type) => api.delete(`solicitantes/${id_usuario}`,{
    params: {
        id,
        type
    }
});

export {
    addSolicitante,
    getSolicitantes,
    updateSolicitantePrincipal,
    removerSolicitante,
}
