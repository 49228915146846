<template>
    <div class="pt-8">
        <v-row>
            <v-col v-if="isAdminOrAnalistaCgcat" cols="12" md="6">
                <v-sheet class="pa-5" outlined rounded>
                    <h2 class="mb-3">Consulta Ficha Funcional</h2>
                    <ficha-funcional-listar />
                </v-sheet>
            </v-col>

            <v-col :class="isAdminOrAnalistaCgcat ? 'col-12 col-md-6' : 'col-12'">
                <v-row>
                    <v-col v-if="isCarreira" :class="!isAdminOrAnalistaCgcat ? 'col-12 col-md-6' : 'col-12'">
                        <v-sheet class="pa-5" outlined rounded>
                            <h2>Ficha Funcional</h2>
                            <v-row class="mt-1 text-center">
                                <v-col v-for="(opcao, key) in opcoesFicha" :key="key" cols="6" lg="4" xl="3">
                                    <v-hover v-slot="{ hover }">
                                        <v-card
                                            :to="opcao.to"
                                            :elevation="hover ? 5 : 2"
                                            outlined
                                            height="130"
                                            class="pa-2"
                                            style="position: relative"
                                        >
                                            <v-chip v-if="opcao.new" small class="orange"
                                                    style="position: absolute;top: 5px;left: 5px;">Novo
                                            </v-chip>
                                            <div class="pa-3">
                                                <v-icon color="primary" class="text-h3">{{ opcao.icon }}</v-icon>
                                            </div>
                                            <h3 class="text-subtitle-2">{{ opcao.title }}</h3>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                    <v-col :class="!isAdminOrAnalistaCgcat ? 'col-12 col-md-6' : 'col-12'">
                        <v-sheet class="pa-5" outlined rounded>
                            <h2 class="mb-3">Solicitações</h2>
                            <v-row class="mt-1 text-center">
                                <v-col v-for="(opcao, key) in opcoesSolicitacao" :key="key" cols="6" lg="4" xl="3">
                                    <v-hover v-slot="{ hover }">
                                        <v-card
                                            :to="opcao.to"
                                            :elevation="hover ? 5 : 2"
                                            outlined
                                            height="130"
                                            class="pa-2"
                                            style="position: relative"
                                        >
                                            <v-chip v-if="opcao.new" small class="orange"
                                                    style="position: absolute;top: 5px;left: 5px;">Novo
                                            </v-chip>
                                            <div class="pa-3">
                                                <v-icon color="primary" class="text-h3">{{ opcao.icon }}</v-icon>
                                            </div>
                                            <h3 class="text-subtitle-2">{{ opcao.title }}</h3>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                    <v-col
                        v-if="$store.getters['auth/usuarioPermissao'](['administrador'])"
                        :class="!isAdminOrAnalistaCgcat ? 'col-12 col-md-6' : 'col-12'"
                    >
                        <v-sheet class="pa-5" outlined rounded>
                            <h2 class="mb-3">Gerenciar</h2>
                            <v-row class="mt-1 text-center">
                                <v-col v-for="(opcao, key) in opcoesGerenciar" :key="key" cols="6" lg="4" xl="3">
                                    <v-hover v-slot="{ hover }">
                                        <v-card
                                            :to="opcao.to"
                                            :elevation="hover ? 5 : 2"
                                            outlined
                                            height="130"
                                            class="pa-2"
                                            style="position: relative"
                                        >
                                            <v-chip v-if="opcao.new" small class="orange"
                                                    style="position: absolute;top: 5px;left: 5px;">Novo
                                            </v-chip>
                                            <div class="pa-3">
                                                <v-icon color="primary" class="text-h3">{{ opcao.icon }}</v-icon>
                                            </div>
                                            <h3 class="text-subtitle-2">{{ opcao.title }}</h3>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                    <v-col v-if="isAdminOrAnalistaCgcat || isCarreira" cols="12">
                        <v-sheet class="pa-5 mb-5" outlined rounded>
                            <OportunidadesProcessosTabs />
                        </v-sheet>
                    </v-col>
                    <v-col cols="12">
                        <v-sheet class="pa-5" outlined rounded>
                            <OportunidadesProcessosTabs is-processos />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import {dataTableMixin} from "../mixins/dataTableMixin";
import FichaFuncionalListar from "./FichaFuncionalListar";
import OportunidadesProcessosTabs from "../components/OportunidadeProcesso/OportunidadesProcessosTabs.vue";

export default {
    name: 'home-app',
    components: {OportunidadesProcessosTabs, FichaFuncionalListar},
    mixins: [dataTableMixin],
    data() {
        return {
            opcoesGerenciar: [
                {
                    to: {name: 'orgao-listar'},
                    icon: 'mdi-bank',
                    title: 'Estrutura Organizacional',
                    new: false,
                },
                {
                    to: {name: 'listas-dominios'},
                    icon: 'mdi-format-list-bulleted',
                    title: 'Listas de Domínios',
                    new: false,
                },
                {
                    to: {name: 'termo-politica'},
                    icon: 'mdi-file-sign',
                    title: 'Termo de Uso e Aviso de Privacidade',
                    new: false,
                },
                {
                    to: {name: 'movimentacao-lote-servidores'},
                    icon: 'mdi-transit-transfer',
                    title: 'Movimentação em Lote de Servidores',
                    new: true,
                },
                {
                    to: {name: 'usuario-listar'},
                    icon: 'mdi-account-group',
                    title: 'Usuários',
                    new: false,
                },
            ],
            opcoesSolicitacao: [
                {
                    to: {name: 'solicitacao-movimentacao-listar'},
                    icon: 'mdi-arrow-decision',
                    title: 'Movimentação',
                    new: false,
                },
                {
                    to: {name: 'processo-seletivo-listar'},
                    icon: 'mdi-briefcase-search',
                    title: 'Processo Seletivo',
                    new: true,
                },
            ],
            opcoesFicha: [
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 0}
                    },
                    icon: 'mdi-account-box',
                    title: 'Dados Pessoais',
                    new: false,
                },
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 1}
                    },
                    icon: 'mdi-card-account-phone',
                    title: 'Contatos',
                    new: false,
                },
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 2}
                    },
                    icon: 'mdi-account-details',
                    title: 'Dados Funcionais',
                    new: false,
                },
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 3}
                    },
                    icon: 'mdi-clipboard-text-clock',
                    title: 'Histórico',
                    new: false,
                },
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 4}
                    },
                    icon: 'mdi-account-star',
                    title: 'Interesses',
                    new: false,
                },
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 5}
                    },
                    icon: 'mdi-card-account-details',
                    title: 'Currículo',
                    new: false,
                },
                {
                    to: {
                        name: 'ficha-funcional-editar',
                        params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                        query: {tab: 6}
                    },
                    icon: 'mdi-briefcase',
                    title: 'Órgão Atual SIAPE',
                    new: false,
                },
                // {
                //     to: {
                //         name: 'ficha-funcional-editar',
                //         params: {servidor: this.$store.getters['auth/usuarioAutenticadoServidor']},
                //         query: {tab: 7}
                //     },
                //     icon: 'mdi-briefcase-clock',
                //     title: 'Cargos Ocupados',
                //     new: false,
                // },
            ]
        }
    },
    computed: {
        isCarreira() {
            return !!this.$store.getters['auth/usuarioAutenticadoServidor']
        },
        isAdminOrAnalistaCgcat() {
            return this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])
        }
    }
}
</script>
