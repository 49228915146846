<template>
    <usuario-form  />
</template>

<script>
import UsuarioForm from "../components/UsuarioForm";
export default {
    name: "Usuario",
    components: {UsuarioForm}
}
</script>

<style scoped>

</style>
