<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Situação *"
                            outlined
                            dense
                            v-model="form.nm_situacao"
                            :rules="validate('required', 'situação')"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            label="Tipo histórico *"
                            outlined
                            dense
                            clearable
                            v-model="form.id_tipo_historico"
                            :rules="validate('required', 'tipo histórico')"
                            :items="itemsTipoHistorico"
                            item-value="id_tipo_historico"
                            item-text="nm_tipo_historico"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <CarreiraSelect
                            label="Carreiras *"
                            multiple
                            v-model="form.lt_cargo"
                            :rules="validate('required', 'carreiras')"
                        />
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.cargos="{ item }" >
                <cargos-chip :cargos="item.cargos" />
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)" />
                <DeleteAction @delete="deletar(item[column_id])"/>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {createSituacao, deleteSituacao, getSituacoes, updateSituacao} from "../../api/situacao";
import tipoHistorico from "../../api/tipo-historico";
import CargosChip from "../CargosChip.vue";
import CarreiraSelect from "../FormInputs/CarreiraSelect.vue";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaSituacao",
    components: {EditAction, DeleteAction, CarreiraSelect, CargosChip},
    mixins: [listasDominiosMixin],
    data() {
        return {
            itemsTipoHistorico: tipoHistorico,
            headers: [
                {text: 'Situação', value: 'nm_situacao', sortable: false},
                {text: 'Tipo Histórico', value: 'tipo_historico.nm_tipo_historico', sortable: false},
                {text: 'Carreiras', value: 'cargos', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            fields: {
                nm_situacao: null,
                id_tipo_historico: null,
                lt_cargo: [],
            },
            column_id: 'id_situacao',
            api: {
                index: getSituacoes,
                create: createSituacao,
                update: updateSituacao,
                delete: deleteSituacao,
            }
        }
    },
    watch: {
        'form.cargos'(newValue) {
            if (newValue) {
                this.form.lt_cargo = newValue.map(cargo => cargo.id_cargo);
            }
        },
    },
}
</script>
