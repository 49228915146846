import api from './index';

const getPoderes = () => api.get('poder');
const createPoder = (dados) => api.post('poder', dados);
const updatePoder = (poder_id, dados) => api.put(`poder/${poder_id}`, dados);
const deletePoder = (poder_id) => api.delete(`poder/${poder_id}`);

export {
    getPoderes,
    createPoder,
    updatePoder,
    deletePoder
}
