<template>
    <v-card outlined>
        <v-card-text>
            <v-sheet class="pa-4 mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3 font-weight-bold">Anexos</h2>
                <p>Alguns tipos de movimentação exigem documentos complementares para sua aprovação final. Para saber
                    mais,
                    clique <strong><a
                        href="https://www.gov.br/servidor/pt-br/acesso-a-informacao/servidor/carreiras/eppgg/sobre-a-carreira/mobilidade-1/mobilidade"
                        target="_blank">aqui</a></strong>).
                </p>
                <p>Se você já possui os documentos em mãos, anexe as cópias em pdf a seguir. Se ainda não possui os
                    documentos, não se preocupe. Pode clicar em <strong>Enviar</strong> a solicitação de movimentação
                    abaixo. A equipe da
                    CGCAT irá analisar o pedido e entrará em contato para passar as orientações sobre a documentação
                    pendente.
                </p>
                <v-form :disabled="disabled" ref="form">
                    <v-row>
                        <v-col cols="6" md="2">
                            <v-select
                                label="Tipo do anexo *"
                                outlined
                                dense
                                :rules="validate('required', 'tipo do anexo')"
                                v-model="form.id_tipo_anexo"
                                :error-messages="error.id_tipo_anexo"
                                :items="itemsTipoAnexo"
                                item-value="id_tipo_anexo"
                                item-text="nm_anexo"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-text-field
                                label="Número do anexo"
                                outlined
                                dense
                                @keyup.enter="salvar()"
                                type="number"
                                v-model="form.nr_anexo"
                                :error-messages="error.nr_anexo"
                                :rules="[() => !form.nr_anexo || form.nr_anexo >= 0 || 'O campo número do anexo deve ser maior igual à zero']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Descrição do anexo *"
                                outlined
                                dense
                                @keyup.enter="salvar()"
                                :rules="validate('required', 'descrição do anexo')"
                                v-model="form.ds_anexo"
                                :error-messages="error.ds_anexo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-text-field
                                label="Data do anexo *"
                                outlined
                                dense
                                type="date"
                                v-model="form.dt_anexo"
                                :rules="validate('required', 'data do anexo')"
                                :error-messages="error.dt_anexo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="12">
                            <v-file-input
                                accept="application/pdf"
                                label="Arquivo"
                                outlined
                                dense
                                :rules="[() => !!form.id_solicitacao_movimentacao_anexo || !!form.file || 'O campo arquivo é obrigatório.']"
                                v-model="form.file"
                                :error-messages="error.file"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </v-form>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="disabled"
                        class="mr-3"
                        outlined
                        @click="limpar()"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        :disabled="disabled"
                        color="primary"
                        @click="salvar()"
                    >
                        Salvar
                    </v-btn>
                </div>
            </v-sheet>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="pagination.itemsPerPage"
                :footer-props="footerProps"
                :options.sync="pagination"
                @update:options="filtrar()"
                :server-items-length="pagination.total"
                class="elevation-1 mb-7"
            >
                <template v-slot:item.dt_anexo="{ item }">
                    <span>{{ dateFormat(item.dt_anexo) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip}">
                            <v-btn
                                fab
                                color="primary"
                                elevation="0"
                                x-small
                                @click="download(item.id_solicitacao_movimentacao_anexo)"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-cloud-download
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Download</span>
                    </v-tooltip>
                    <EditAction @click="form = {...item}" />
                    <DeleteAction @delete="deletar(item.id_solicitacao_movimentacao_anexo)" />
                </template>
            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="disabled" outlined @click="$emit('previousStep')">voltar</v-btn>
            <v-btn
                v-if="solicitacao.id_status_solicitacao === 1"
                :disabled="disabled"
                color="primary"
                @click="dialog = true"
            >enviar</v-btn>
        </v-card-actions>

        <v-dialog
            v-if="solicitacao.id_status_solicitacao === 1"
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>
                    Enviar Solicitação de Movimentação
                </v-card-title>
                <v-card-text>
                    Deseja enviar a solicitação de movimentação para análise da Secretaria de Gestão e Inovação?
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="enviar"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>

import {anexoMixin} from "../../mixins/anexoMixin";
import {validationMixin} from "../../mixins/validationMixin";
import {
    createSolicitacaoMovimentacaoAnexo,
    deleteSolicitacaoMovimentacaoAnexo,
    getSolicitacaoMovimentacaoAnexoDownload,
    getSolicitacaoMovimentacaoAnexos,
    updateSolicitacaoMovimentacaoAnexo,
    updateSolicitacaoStatus
} from "../../api/solicitacao-movimentacao";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "SolicitacaoAnexo",
    components: {EditAction, DeleteAction},
    mixins: [anexoMixin, validationMixin],
    props: ['disabled', 'solicitacao'],
    data() {
        return {
            dialog: false,
            fields: {
                id_solicitacao_movimentacao_anexo: null,
                id_tipo_anexo: null,
                nr_anexo: null,
                ds_anexo: null,
                dt_anexo: null,
                file: null,
            },
        }
    },
    watch: {
        solicitacao: function (val) {
            this.filtrar();
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                const formData = new FormData();
                formData.append('id_tipo_anexo', this.form.id_tipo_anexo);
                if (!!this.form.nr_anexo) {
                    formData.append('nr_anexo', this.form.nr_anexo);
                }
                if (!!this.form.ds_anexo) {
                    formData.append('ds_anexo', this.form.ds_anexo);
                }
                if (!!this.form.dt_anexo) {
                    formData.append('dt_anexo', this.form.dt_anexo);
                }
                if (!!this.form.file) {
                    formData.append('file', this.form.file);
                }

                let callback;
                if (!!this.form.id_solicitacao_movimentacao_anexo) {
                    callback = updateSolicitacaoMovimentacaoAnexo(this.solicitacao.id_solicitacao_movimentacao, this.form.id_solicitacao_movimentacao_anexo, formData);
                } else {
                    callback = createSolicitacaoMovimentacaoAnexo(this.solicitacao.id_solicitacao_movimentacao, formData)
                }

                callback.then((response) => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                    this.limpar()
                    this.filtrar()
                }).catch((error) => {
                    this.error = {...error.response.data.errors}
                });
            }
        },
        download(id_anexo) {
            getSolicitacaoMovimentacaoAnexoDownload(this.solicitacao.id_solicitacao_movimentacao, id_anexo)
                .then(response => {
                    this.forceFileDownload(response)
                });
        },
        filtrar() {
            if (!!this.solicitacao.id_solicitacao_movimentacao) {
                getSolicitacaoMovimentacaoAnexos(this.solicitacao.id_solicitacao_movimentacao, this.pagination.page, this.pagination.itemsPerPage)
                    .then(response => {
                        this.items = response.data.data.data;
                        this.pagination.total = response.data.data.total;
                        if (this.pagination.total < this.pagination.itemsPerPage) {
                            this.pagination.page = 1;
                        }
                    });
            }
        },
        deletar(id_anexo) {
            deleteSolicitacaoMovimentacaoAnexo(this.solicitacao.id_solicitacao_movimentacao, id_anexo)
                .then(response => {
                    this.items = this.items.filter(item => item.id_solicitacao_movimentacao_anexo !== id_anexo);
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        },
        enviar() {
            updateSolicitacaoStatus(this.solicitacao.id_solicitacao_movimentacao, {id_status_solicitacao: 2})
                .then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$router.push({name: 'solicitacao-movimentacao-listar'});
                })
        }
    },
}
</script>
