<template>
    <v-form :disabled="disabled" ref="form">
        <v-card outlined>
            <v-card-text>
                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Informações Qualitativas</h2>
                    <p><strong>Campos obrigatórios:</strong></p>

                    <p>Selecione a(s) carreira(s), indique a modalidade de trabalho (presencial, híbrida ou teletrabalho),
                        defina as principais áreas de atuação e políticas públicas com as quais a candidata ou o candidato
                        irá trabalhar.</p>

                    <p><strong>Campos opcionais:</strong></p>

                    <p>Se desejável, indique nível mínimo de formação acadêmica, proficiência em idiomas, se é necessária
                        experiência prévia em coordenação de equipes, e descreva o perfil desejado em termos de competências,
                        formação acadêmica ou experiência prévia específica.</p><br>
                    <v-row>
                        <v-col cols="12" md="6" lg="">
                            <CarreiraSelect
                                label="Carreira *"
                                v-model="form.lt_cargo"
                                :rules="validate('required', 'carreira')"
                                multiple
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="">
                            <ModalidadeTrabalhoSelect
                                label="Modalidade de trabalho *"
                                v-model="form.id_modalidade_trabalho"
                                :rules="validate('required', 'modalidade de trabalho')"
                            />
                        </v-col>
                        <v-col cols="12" lg="">
                            <v-autocomplete
                                label="Idiomas"
                                outlined
                                dense
                                multiple
                                clearable
                                :filter="filterNormalize"
                                v-model="form.lt_idioma"
                                :items="itemsIdiomas"
                                item-value="id_idioma"
                                item-text="nm_idioma"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="">
                            <v-select
                                label="Experiência prévia com coordenação de equipes?"
                                outlined
                                dense
                                clearable
                                v-model="form.st_experiencia_cordenacao_equipe"
                                :items="[{value:true, text:'Sim'}, {value:false, text:'Não'}]"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" lg="">
                            <v-select
                                label="Formação Acadêmica"
                                outlined
                                dense
                                clearable
                                v-model="form.id_formacao_academica"
                                :items="itemsFormacaoAcademica"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <AreaAtuacaoAutocomplete
                                label="Área de Atuação *"
                                v-model="form.lt_area_atuacao"
                                :rules="validate('required', 'área de atuação')"
                                st-tipo-historico
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <PoliticasPublicasAutocomplete
                                label="Políticas Públicas *"
                                v-model="form.lt_politicas_publicas"
                                :rules="validate('required', 'políticas públicas')"
                                st-tipo-historico
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                label="Descreva os requisitos desejados da candidata ou do candidado (formação acadêmica, experiência profissional, competências técnicas ou comportamentais)"
                                outlined
                                dense
                                v-model="form.ds_requisitos_desejados"
                                :rules="validate('max:5000', 'descrição dos requisitos desejados')"
                                counter
                                maxlength="5000"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="disabled" outlined @click="$emit('previousStep')">voltar</v-btn>
                <v-btn :disabled="disabled" color="primary" @click="salvar()">Avançar</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import {informacoesQualitativasMixin} from "../../mixins/informacoesQualitativasMixin";
import {validationMixin} from "../../mixins/validationMixin";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import {getIdiomas} from "../../api/idiomas";
import {updateProcessoSeletivoInformacoesQualitativas} from "../../api/processo-seletivo";
import CarreiraSelect from "../FormInputs/CarreiraSelect.vue";
import AreaAtuacaoAutocomplete from "../FormInputs/AreaAtuacaoAutocomplete.vue";
import PoliticasPublicasAutocomplete from "../FormInputs/PoliticasPublicasAutocomplete.vue";
import ModalidadeTrabalhoSelect from "../FormInputs/ModalidadeTrabalhoSelect.vue";

export default {
    name: "ProcessoSeletivoInformacoesQualitativas",
    components: {ModalidadeTrabalhoSelect, PoliticasPublicasAutocomplete, AreaAtuacaoAutocomplete, CarreiraSelect},
    mixins: [informacoesQualitativasMixin, validationMixin, filterNormalizeMixin],
    props: ['disabled', 'processoSeletivo'],
    data() {
        return {
            itemsIdiomas: [],
            itemsFormacaoAcademica: [
                {value: 1, text: 'Graduação'},
                {value: 2, text: 'Especialização'},
                {value: 3, text: 'Mestrado'},
                {value: 4, text: 'Doutorado'},
                {value: 5, text: 'Pós-Doutorado'},
            ]
        }
    },
    async created() {
        await getIdiomas().then(response => this.itemsIdiomas = response.data.data);

        this.form.lt_cargo = this.processoSeletivo.cargos.map((object) => object.id_cargo)
        this.form.id_modalidade_trabalho = this.processoSeletivo.id_modalidade_trabalho;
        this.form.lt_idioma = this.processoSeletivo.idiomas.map((object) => object.id_idioma)
        this.form.st_experiencia_cordenacao_equipe = this.processoSeletivo.st_experiencia_cordenacao_equipe;
        this.form.lt_politicas_publicas = this.processoSeletivo.politicas_publicas.map((object) => object.id_politicas_publicas)
        this.form.lt_area_atuacao = this.processoSeletivo.areas_atuacao.map((object) => object.id_area_atuacao)
        this.form.id_formacao_academica = this.processoSeletivo.id_formacao_academica;
        this.form.ds_requisitos_desejados = this.processoSeletivo.ds_requisitos_desejados;
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                updateProcessoSeletivoInformacoesQualitativas(this.processoSeletivo.id_processo_seletivo, this.form)
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        })

                        this.$emit('nextStep');

                        this.$vuetify.goTo(0)
                    });
            }
        }
    },
}
</script>
