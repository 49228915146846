import api from './index';

const getHierarquias = () => api.get('hierarquia');
const createHierarquia = (dados) => api.post('hierarquia', dados);
const updateHierarquia = (id, dados) => api.put(`hierarquia/${id}`, dados);
const deleteHierarquia = (id) => api.delete(`hierarquia/${id}`);

export {
    getHierarquias,
    createHierarquia,
    updateHierarquia,
    deleteHierarquia,
}
