<template>
    <div>
        <h1 class="text-h5 my-4">
            Ficha Funcional | {{ !!this.servidor.cargo ? this.servidor.cargo.sigla : '' }} | {{ servidor.nome }}
        </h1>
        <v-tabs v-model="tab">
            <v-tab v-for="(item, key) in items" :key="key">
                {{ item }}
                <v-tooltip
                    v-if="item === 'Histórico'"
                    top
                >
                    <template v-slot:activator="{ on: tooltip}">
                        <v-fab-transition>
                            <v-btn
                                v-show="tab === 3"
                                outlined
                                fab
                                x-small
                                class="ml-2"
                                @click="resetHistorico"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-table-arrow-left
                                </v-icon>
                            </v-btn>
                        </v-fab-transition>
                    </template>
                    <span>Voltar ao Listar</span>
                </v-tooltip>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-dados-pessoais
                        v-if="servidor.id"
                        :servidor="servidor"
                        :data-alteracao-servidor="dataAlteracao"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-contatos
                        v-if="servidor.id"
                        :servidor-id="servidor.id"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-dados-funcionais
                        v-if="servidor.id"
                        :servidor="servidor"
                        :data-alteracao-servidor="dataAlteracao"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-historico
                        v-if="servidor.id"
                        :servidor="servidor"
                        :return-to-listar="returnToList"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-interesses
                        v-if="servidor.id"
                        :servidor-id="servidor.id"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-curriculo
                        v-if="servidor.id"
                        :servidor-id="servidor.id"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-orgao-atual-siape
                        v-if="servidor.id"
                        :servidor="servidor"
                        :data-alteracao-servidor="dataAlteracao"
                    />
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <ficha-cargos-funcoes
                        v-if="servidor.id"
                        :servidor-id="servidor.id"
                        :data-alteracao-servidor="dataAlteracao"
                    />
                </v-sheet>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import FichaDadosFuncionais from "./FichaDadosFuncionais";
import FichaDadosPessoais from './FichaDadosPessoais'
import FichaInteresses from "./FichaInteresses";
import moment from 'moment';
import FichaContatos from "./FichaContatos";
import FichaOrgaoAtualSiape from "./FichaOrgaoAtualSiape.vue";
import FichaCargosFuncoes from "./FichaCargosFuncoes";
import FichaHistorico from "./FichaHistorico";
import FichaCurriculo from "./FichaCurriculo";

moment.locale('pt-br');

export default {
    name: "FichaFuncionalTabs",
    props: ['servidor'],
    components: {
        FichaCurriculo,
        FichaHistorico,
        FichaCargosFuncoes,
        FichaOrgaoAtualSiape,
        FichaContatos,
        FichaInteresses,
        FichaDadosFuncionais,
        FichaDadosPessoais
    },
    data() {
        return {
            tab: null,
            items: [
                'Dados Pessoais',
                'Contatos',
                'Dados Funcionais',
                'Histórico',
                'Interesses',
                'Currículo',
                'Órgão Atual SIAPE',
                // 'Cargos Ocupados'
            ],
            returnToList: false,
        }
    },
    created() {
        if (this.$route.query.tab) {
            this.tab = this.$route.query.tab
        }
    },
    computed: {
        dataAlteracao() {
            if (this.servidor.updated_at) {
                return moment(this.servidor.updated_at).format('DD/MM/YYYY')
            }
            return null
        },
    },
    methods: {
        resetHistorico() {
            this.returnToList = true
            setInterval(() => this.returnToList = false, 1000)
        }
    },
}
</script>
