<template>
    <div>
        <v-form ref="formServidor">
            <v-sheet class="pa-4 mb-5" rounded outlined>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Nome do órgão lotação"
                            :value="servidor.orgao_lotacao?.nome"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="UORG de lotação"
                            :value="servidor.unidade_lotacao?.nome"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            label="Nome do cargo"
                            :value="servidor.cargo?.nome"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            label="Matrícula SIAPE"
                            :value="servidor.matricula_siape"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                            label="Ingresso no cargo *"
                            outlined
                            type="date"
                            dense
                            v-model="formServidor.dt_data_ingresso_cargo"
                            :rules="validate('required', 'Ingresso no cargo')"
                        ></v-text-field>
                        <v-text-field
                            v-else
                            label="Ingresso no cargo"
                            :value="dataIngresso"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Data de exclusão"
                            :value="dataExclusao"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            label="Situação funcional"
                            :value="servidor.situacao"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            label="Jornada de trabalho vigente"
                            :value="!!servidor.jornada_trabalho ? servidor.jornada_trabalho + ' Horas Semanais' : ''"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            label="Classe"
                            :value="servidor.classe_carreira"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            label="Padrão"
                            :value="servidor.padrao_carreira"
                            outlined
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                </v-row>
                <row-data-usuario-alteracao fonte="SIAPE" :data="dataAlteracaoServidor"/>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                    color="primary"
                    @click="salvarFormServidor()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
        <v-form ref="form">
            <v-sheet class="pa-4 my-5" rounded outlined>
                <v-row>
                    <v-col cols="6" md="9">
                        <v-select
                            label="Area Concentração"
                            outlined
                            dense
                            clearable
                            v-model="form.id_area_concentracao"
                            :items="itemsAreaConcentracao"
                            :error-messages="error.id_area_concentracao"
                            item-value="id_area_concentracao"
                            item-text="nm_area_concentracao"
                        ></v-select>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-select
                            label="Turma *"
                            outlined
                            dense
                            v-model="form.id_turma_ingresso"
                            :items="itemsTurmasIngresso"
                            item-value="id_turma_ingresso"
                            item-text="nm_turma_ingresso"
                            :rules="validate('required', 'turma')"
                            :error-messages="error.id_turma_ingresso"
                        ></v-select>
                    </v-col>
                </v-row>
                <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import {getAreasConcentracao} from "../../api/area_concentracao";
import {getTurmas} from "../../api/turma";
import moment from 'moment';
import {getServidorDadosFuncionais, updateServidor, upsertServidorDadosFuncionais} from "../../api/servidor";
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {validationMixin} from "../../mixins/validationMixin";

moment.locale('pt-br');

export default {
    name: "FichaDadosFuncionais",
    mixins: [dataUsuarioAlteracaoMixin, validationMixin],
    components: {RowDataUsuarioAlteracao},
    props: {
        servidor: {
            type: Object
        },
        dataAlteracaoServidor: {
            type: String
        }
    },
    data() {
        return {
            itemsTurmasIngresso: [],
            itemsAreaConcentracao: [],
            formServidor: {
                dt_data_ingresso_cargo: null,
            },
            form: {
                id_area_concentracao: null,
                id_turma_ingresso: null,
            },
            error: {},
        }
    },
    created() {
        this.formServidor.dt_data_ingresso_cargo = this.servidor.data_ingresso_cargo
    },
    async mounted() {
        await getAreasConcentracao(this.servidor.cargo.id)
            .then(response => this.itemsAreaConcentracao = response.data.data)
        await getTurmas(this.servidor.cargo.id)
            .then(response => this.itemsTurmasIngresso = response.data.data)
        await getServidorDadosFuncionais(this.servidor.id)
            .then((response) => {
                this.form = {
                    id_area_concentracao: response.data.data.id_area_concentracao,
                    id_turma_ingresso: response.data.data.id_turma_ingresso,
                }

                this.setDataUsuarioAlteracaoByResponse(response)
            })
    },
    methods: {
        salvarFormServidor() {
            if (this.$refs.formServidor.validate()) {
                updateServidor(this.servidor.id, JSON.stringify(this.formServidor))
                    .then((response) => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$vuetify.goTo(0);
                    })
            }
        },
        salvar() {
            if (this.$refs.form.validate()) {
                upsertServidorDadosFuncionais(this.servidor.id, JSON.stringify(this.form))
                    .then((response) => {
                        this.setDataUsuarioAlteracaoByResponse(response)

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$vuetify.goTo(0);
                    })
                    .catch((error) => {
                        this.error = {...error.response.data.errors}
                    });
            }
        }
    },
    computed: {
        dataIngresso() {
            if (this.servidor.data_ingresso_cargo) {
                return moment(this.servidor.data_ingresso_cargo).format("DD/MM/YYYY")
            }
            return ''
        },
        dataExclusao() {
            return this.servidor.data_exclusao_cargo ? moment(this.servidor.data_exclusao_cargo).format("DD/MM/YYYY") : null
        }
    }
}
</script>
