<template>
    <v-row>
        <v-col cols="12" md="4">
            <v-autocomplete
                :label="label('País')"
                outlined
                dense
                :disabled="field_pais_disabled"
                :value="form.id_pais"
                @input="$emit('update:form', 'id_pais', $event)"
                :rules="required ? validate('required', 'país') : []"
                :filter="filterNormalize"
                :items="itemsPaises"
                item-value="id_pais"
                item-text="nm_pais"
                @change="limparCamposEndereco()"
            ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4">
            <v-autocomplete
                v-if="form.id_pais == 21"
                :label="label('Estado')"
                outlined
                dense
                clearable
                :disabled="field_estado_disabled"
                :value="form.ds_estado"
                @input="$emit('update:form', 'ds_estado', $event)"
                :rules="required ? validate('required', 'estado') : []"
                :items="['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI',
                        'RJ','RN','RS','RO','RR','SC','SP','SE','TO']"
                @change="form.nm_cidade = null"
            ></v-autocomplete>
            <v-text-field
                v-else
                :label="label('Estado')"
                outlined
                dense
                :disabled="field_estado_disabled"
                :value="form.ds_estado"
                @input="$emit('update:form', 'ds_estado', $event)"
                :rules="required ? validate('required', 'estado') : []"
            ></v-text-field>
        </v-col>
        <v-col cols="6" md="4">
            <v-autocomplete
                v-if="form.id_pais == 21"
                :label="label('Cidade')"
                outlined
                dense
                clearable
                :disabled="field_cidade_disabled || !form.ds_estado"
                :value="form.nm_cidade"
                @input="$emit('update:form', 'nm_cidade', $event)"
                :rules="required ? validate('required', 'cidade') : []"
                :filter="filterNormalize"
                :items="itemsMunicipios"
                item-value="nm_municipio"
                item-text="nm_municipio"
            ></v-autocomplete>
            <v-text-field
                v-else
                :label="label('Cidade')"
                outlined
                dense
                :disabled="field_cidade_disabled"
                :value="form.nm_cidade"
                @input="$emit('update:form', 'nm_cidade', $event)"
                :rules="required ? validate('required', 'cidade') : []"
            ></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import {enderecoMixin} from "../../mixins/enderecoMixin";

export default {
    name: "CidadeEstadoPaisFields",
    mixins: [validationMixin, filterNormalizeMixin, enderecoMixin],
    props: {
        form: {
            type: Object,
            required: true
        },
        required: {
            type: Boolean,
            default: true
        },
        field_pais_disabled: {
            type: Boolean,
            default: false
        },
        field_estado_disabled: {
            type: Boolean,
            default: false
        },
        field_cidade_disabled: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        label(title) {
            return this.required ? title + ' *' : title
        }
    },
}
</script>
