<template>
    <div>
        <v-sheet class="pa-4 mb-5" rounded outlined>
            <v-row>
                <v-col cols="12" md="8">
                    <v-text-field
                        label="Nome"
                        :value="servidor.nome"
                        outlined
                        dense
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col v-if="!resumido" cols="6" md="4">
                    <v-text-field
                        label="CPF"
                        :value="servidor.cpf"
                        outlined
                        dense
                        disabled
                        v-mask="'###.###.###-##'"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Data de Nascimento"
                        :value="dataNascimentoServidor"
                        outlined
                        dense
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Idade"
                        :value="idadeServidor"
                        outlined
                        dense
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Sexo"
                        :value="servidor.sexo"
                        outlined
                        dense
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <row-data-usuario-alteracao fonte="SIAPE" :data="dataAlteracaoServidor"/>
        </v-sheet>
        <v-form :disabled="resumido" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col cols="12" sm="6" lg="3">
                        <v-select
                            label="Raça *"
                            outlined
                            dense
                            v-model="form.id_raca"
                            :items="itemsRacas"
                            :rules="validate('required', 'raça')"
                            :error-messages="error.id_raca"
                            item-value="id_raca"
                            item-text="nm_raca"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" lg="3">
                        <v-select
                            label="Deficiência *"
                            outlined
                            dense
                            v-model="form.st_deficiente"
                            :items="[{value: true, text: 'Sim'}, {value: false, text: 'Não'}]"
                            :rules="validate('required', 'deficiência')"
                            :error-messages="error.st_deficiente"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.st_deficiente" cols="12" sm="6" lg="3">
                        <v-select
                            label="Tipo de deficiência *"
                            outlined
                            dense
                            v-model="form.id_tipo_deficiencia"
                            :items="itemsTipoDeficiencia"
                            :rules="validate('required', 'tipo de deficiência')"
                            :error-messages="error.id_tipo_deficiencia"
                            item-value="id_tipo_deficiencia"
                            item-text="nm_tipo_deficiencia"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.st_deficiente" cols="12" sm="6" lg="3">
                        <v-select
                            label="Tempo de deficiência *"
                            outlined
                            dense
                            v-model="form.id_periodo_deficiencia"
                            :items="itemsPeriodoDeficiencia"
                            :rules="validate('required', 'tempo de deficiência')"
                            :error-messages="error.id_periodo_deficiencia"
                            item-value="id_periodo_deficiencia"
                            item-text="nm_periodo_deficiencia"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.st_deficiente" cols="12" sm="6" lg="3">
                        <v-select
                            label="Grau de deficiência *"
                            outlined
                            dense
                            v-model="form.id_grau_deficiencia"
                            :items="itemsGrauDeficiencia"
                            :rules="validate('required', 'grau de deficiência')"
                            :error-messages="error.id_grau_deficiencia"
                            item-value="id_grau_deficiencia"
                            item-text="nm_grau_deficiencia"
                        ></v-select>
                    </v-col>
                </v-row>
                <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
            </v-sheet>
            <div v-if="!resumido" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import {getRacas} from "../../api/raca";
import {getGrauDeficiancia, getPeriodoDeficiancia, getTipoDeficiancia} from "../../api/deficiencia";
import {upsertServidorDadosPessoais, getServidorDadosPessoais} from '../../api/servidor'
import moment from 'moment';
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {validationMixin} from "../../mixins/validationMixin";
moment.locale('pt-br');

export default {
    name: "DadosPessoais",
    components: {RowDataUsuarioAlteracao},
    mixins: [dataUsuarioAlteracaoMixin, validationMixin],
    props: {
        servidor: {
            type: Object,
            required: true
        },
        dataAlteracaoServidor: {
            type: String
        },
        resumido: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            itemsRacas: [],
            itemsTipoDeficiencia: [],
            itemsPeriodoDeficiencia: [],
            itemsGrauDeficiencia: [],
            form: {
                id_raca: null,
                id_tipo_deficiencia: null,
                id_periodo_deficiencia: null,
                id_grau_deficiencia: null,
                st_deficiente: null,
            },
            error: {},
        }
    },
    created() {
        getServidorDadosPessoais(this.servidor.id)
            .then((response) => {
                this.form = {
                    id_raca: response.data.data.id_raca,
                    id_tipo_deficiencia: response.data.data.id_tipo_deficiencia,
                    id_periodo_deficiencia: response.data.data.id_periodo_deficiencia,
                    id_grau_deficiencia: response.data.data.id_grau_deficiencia,
                    st_deficiente: response.data.data.st_deficiente,
                }

                this.setDataUsuarioAlteracaoByResponse(response)
            })
        getRacas().then((response) => this.itemsRacas = response.data.data)
        getTipoDeficiancia().then((response) => this.itemsTipoDeficiencia = response.data.data)
        getPeriodoDeficiancia().then((response) => this.itemsPeriodoDeficiencia = response.data.data)
        getGrauDeficiancia().then((response) => this.itemsGrauDeficiencia = response.data.data)
    },
    watch: {
        'form.st_deficiente'(val) {
            if (!val) {
                this.form.id_tipo_deficiencia = null;
                this.form.id_periodo_deficiencia = null;
                this.form.id_grau_deficiencia = null;
            }
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                upsertServidorDadosPessoais(this.servidor.id, JSON.stringify(this.form))
                    .then((response) => {
                        this.setDataUsuarioAlteracaoByResponse(response)

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$vuetify.goTo(0);
                    })
                    .catch((error) => {
                        this.error = {...error.response.data.errors}
                    });
            }
        }
    },
    computed: {
        dataNascimentoServidor() {
            return moment(this.servidor.data_nascimento).format("DD/MM/YYYY")
        },
        idadeServidor() {
            return moment().diff(this.servidor.data_nascimento, 'years') + ' anos';
        },
    },
}
</script>
