<template>
    <v-select
        :label="label"
        outlined
        dense
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :items="analistas"
        item-value="id_usuario"
        item-text="nm_usuario"
    />
</template>

<script>
import {getUsuarios} from "../../api/usuario";

export default {
    name: "AtribuirResponsavelSelect",
    props: {
        label: {
            type: String,
            default: 'Atribuir responsável *'
        },
        value: {
            type: Number,
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            analistas: [],
        }
    },
    created() {
        getUsuarios(1, 10000, {}).then(response => {
            this.analistas = response.data.data.data.filter(usuario => usuario.in_perfis.includes('analista_cgcat'));
            // verifica entre os analistas se existe o usuário logado
            if (this.analistas.filter(usuario => usuario.id_usuario == this.$store.getters['auth/usuarioAutenticado']).length > 0) {
                this.$emit('input', this.$store.getters['auth/usuarioAutenticado']);
            }
        });
    }
}
</script>
