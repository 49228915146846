import api from './index';

const getProcessosSeletivos = async (optionsPagination, filtrosAplicados = []) => await api.get('processo-seletivo', {
    params: {
        options: optionsPagination,
        filtros: filtrosAplicados,
    }
});
const getProcessoSeletivo = async (id) => await api.get(`processo-seletivo/${id}`);
const createProcessoSeletivo = (dados) => api.post('processo-seletivo', dados);
const updateProcessoSeletivoOrgaoSolitante = (id, dados) => api.put(`processo-seletivo/${id}/orgao-solicitante`, dados);
const updateProcessoSeletivoInformacoesQualitativas = (id, dados) => api.put(`processo-seletivo/${id}/informacoes-qualitativas`, dados);
const updateProcessoSeletivoStatus = (id, dados) => api.put(`processo-seletivo/${id}/status`, dados);

const concluirProcessoSeletivo = (id) => api.put(`processo-seletivo/${id}/concluir`);

export {
    getProcessosSeletivos,
    getProcessoSeletivo,
    createProcessoSeletivo,
    updateProcessoSeletivoOrgaoSolitante,
    updateProcessoSeletivoInformacoesQualitativas,
    updateProcessoSeletivoStatus,
    concluirProcessoSeletivo,
}
