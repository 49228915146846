import moment from 'moment';
moment.locale('pt-br');

export const dataUsuarioAlteracaoMixin = {
    data() {
        return {
            dataAlteracao: null,
            usuarioAlteracao: null,
        }
    },
    methods: {
        setDataUsuarioAlteracaoByResponse(response) {
            this.setDataAlteracao(response.data.data.updated_at);

            if (!!response.data.data.usuario) {
                this.usuarioAlteracao = response.data.data.usuario.nm_usuario;
            }
        },
        setDataAlteracao(updated_at) {
            if (!!updated_at) {
                this.dataAlteracao = moment(updated_at).format("DD/MM/YYYY LT");
            }
        }
    },
}
