<template>
    <v-stepper v-model="step" @change="addStepVisited" elevation="0">
        <h1 class="text-h5 my-4">{{ title }}</h1>
        <v-stepper-header style="height: auto">
            <v-stepper-step
                v-for="item in steppers"
                :key="item.step"
                :editable="editableStep(item.step)"
                :complete="completeStep(item.step)"
                :step="item.step"
            >
                {{ item.title }}
            </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content v-for="item in steppers" :key="item.step" :step="item.step" class="pa-0">
                <component
                    v-if="item.component && stepVisited.includes(item.step)"
                    :is="item.component"
                    :processo-seletivo="processoSeletivo"
                    :disabled="item.disabled"
                    @nextStep="nextStep"
                    @previousStep="addStepVisited(--step)"
                />
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import {stepperMixin} from "../../mixins/stepperMixin";
import ProcessoSeletivoOrgaoSolicitante from "./ProcessoSeletivoOrgaoSolicitante.vue";
import ProcessoSeletivoInformacoesQualitativas from "./ProcessoSeletivoInformacoesQualitativas.vue";
import ProcessoSeletivoPactuacaoResultado from "./ProcessoSeletivoPactuacaoResultado.vue";
import ProcessoSeletivoInscritos from "./ProcessoSeletivoInscritos.vue";

export default {
    name: "ProcessoSeletivoStepper",
    props: ['processoSeletivo', 'title'],
    mixins: [stepperMixin],
    watch: {
        processoSeletivo: function (val) {
            if (val.id_processo_seletivo) {
                if (!!val.id_modalidade_trabalho) {
                    this.stepEditable = 3;
                } else if (!!val.id_orgao) {
                    this.stepEditable = 2;
                }
                if (val.id_status_processo_seletivo === 1) {
                    this.step = this.stepEditable;
                } else {
                    this.stepEditable = this.steppers.length
                }
            }
            this.addStepVisited(this.step);
        }
    },
    computed: {
        steppers() {
            if (!!this.processoSeletivo.divulgacao &&
                (this.processoSeletivo.id_usuario_solicitante === this.$store.getters['auth/usuarioAutenticado'] ||
                    this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])
                )
            ) {
                return [
                    {step: 1, title: 'Candidaturas', component: ProcessoSeletivoInscritos, disabled: this.disabled()},
                    {step: 2, title: 'Órgão Solicitante', component: ProcessoSeletivoOrgaoSolicitante, disabled: this.disabled()},
                    {step: 3, title: 'Informações Qualitativas', component: ProcessoSeletivoInformacoesQualitativas, disabled: this.disabled()},
                    {step: 4, title: 'Pactuações de Resultados', component: ProcessoSeletivoPactuacaoResultado, disabled: this.disabled()},
                ]
            }

            return [
                {step: 1, title: 'Órgão Solicitante', component: ProcessoSeletivoOrgaoSolicitante, disabled: this.disabled()},
                {step: 2, title: 'Informações Qualitativas', component: ProcessoSeletivoInformacoesQualitativas, disabled: this.disabled()},
                {step: 3, title: 'Pactuações de Resultados', component: ProcessoSeletivoPactuacaoResultado, disabled: this.disabled()},
            ];
        }
    },
    methods: {
        disabled() {
            if (!this.processoSeletivo.id_processo_seletivo) {
                return false;
            }

            if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
                return this.processoSeletivo.id_status_processo_seletivo > 5;
            }

            const statusEnable = [1, 3];
            if (statusEnable.includes(this.processoSeletivo.id_status_processo_seletivo)) {
                const verificaUsuarioSolicitanteEPerfilRH = (
                    this.processoSeletivo.id_usuario_solicitante !== this.$store.getters['auth/usuarioAutenticado'] &&
                    !this.processoSeletivo.solicitantes.find((solicitante) => solicitante.id_usuario === this.$store.getters['auth/usuarioAutenticado']) &&
                    !this.$store.getters['auth/usuarioPermissao'](['rh'])
                );

                return verificaUsuarioSolicitanteEPerfilRH;
            } else {
                return true;
            }
        }
    },
}
</script>
