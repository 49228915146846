<template>
    <div>
        <v-row v-if="showVinculo">
            <v-col cols="12">
                <v-btn
                    outlined
                    @click="showVinculo = false"
                >
                    Listas de Vínculo
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-form :id="column_id" ref="form">
                    <v-sheet class="pa-4  mb-5" rounded outlined>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-select
                                    label="Sigla *"
                                    outlined
                                    dense
                                    v-model="form.id_funcao"
                                    :rules="validate('required', 'sigla')"
                                    :items="itemsFuncao"
                                    item-value="id_funcao"
                                    item-text="nm_funcao"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    label="Nível *"
                                    outlined
                                    dense
                                    v-model="form.id_nivel"
                                    :rules="validate('required', 'Nível')"
                                    :items="itemsNivel"
                                    item-value="id_nivel"
                                    item-text="nm_nivel"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                    label="Hierarquia"
                                    outlined
                                    dense
                                    clearable
                                    v-model="form.id_hierarquia"
                                    :items="itemsHierarquia"
                                    item-value="id_hierarquia"
                                    item-text="nm_hierarquia"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mr-3"
                            outlined
                            @click="limpar()"
                        >
                            Limpar
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="salvar()"
                        >
                            Salvar
                        </v-btn>
                    </div>
                </v-form>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    @update:options="getAllItems()"
                    :server-items-length="pagination.total"
                    hide-default-footer
                    class="col-12 elevation-1 mt-7"
                >
                    <template v-slot:item.actions="{ item }">
                        <EditAction @click="editar(item)"/>
                        <DeleteAction @delete="deletar(item[column_id])" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-btn
                    outlined
                    @click="showVinculo = true"
                >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Voltar
                </v-btn>
            </v-col>
            <v-col cols="12" md="4"><ListaFuncao /></v-col>
            <v-col cols="12" md="4"><ListaNivel /></v-col>
            <v-col cols="12" md="4"><ListaHierarquia /></v-col>
        </v-row>
    </div>
</template>

<script>
import ListaFuncao from "./ListaFuncao";
import ListaHierarquia from "./ListaHierarquia";
import ListaNivel from "./ListaNivel";
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {
    createNivelFuncaoHierarquia, deleteNivelFuncaoHierarquia,
    getNiveisFuncoesHierarquias,
    updateNivelFuncaoHierarquia
} from "../../api/nivel-funcao-hierarquia";
import {getFuncoes} from "../../api/funcao";
import {getNiveis} from "../../api/nivel";
import {getHierarquias} from "../../api/hierarquia";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaNivelFuncaoHierarquia",
    components: {EditAction, DeleteAction, ListaNivel, ListaHierarquia, ListaFuncao},
    mixins: [listasDominiosMixin],
    data() {
        return {
            showVinculo: true,
            itemsFuncao: [],
            itemsHierarquia: [],
            itemsNivel: [],
            headers: [
                {text: "Sigla", value: "nm_funcao", sortable: false},
                {text: "Nível", value: "nm_nivel", sortable: false},
                {text: "Hierarquia", value: "nm_hierarquia", sortable: false},
                {text: "Ações", value: "actions", sortable: false}
            ],
            fields: {
                id_funcao: null,
                id_hierarquia: null,
                id_nivel: null,
            },
            column_id: "id_nivel_funcao_hierarquia",
            api: {
                index: getNiveisFuncoesHierarquias,
                create: createNivelFuncaoHierarquia,
                update: updateNivelFuncaoHierarquia,
                delete: deleteNivelFuncaoHierarquia,
            }
        }
    },
    methods: {
        getAllItems() {
            getFuncoes().then(response => this.itemsFuncao = response.data.data);
            getNiveis().then(response => this.itemsNivel = response.data.data);
            getHierarquias().then(response => this.itemsHierarquia = response.data.data);
            getNiveisFuncoesHierarquias().then(response => this.items = response.data.data);
        }
    },
}
</script>
