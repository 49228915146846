import _ from "lodash";

export const validationMixin = {
    methods: {
        validate(rules, label) {
            let result = [];
            rules.split("|").forEach((rule) => {
                if (rule == "required") {
                    result.push((v) => !this.empty(v) || `O campo ${label} é obrigatório.`);
                }
                if (rule == "accepted") {
                    result.push((v) => v == true || `O campo ${label} deve ser aceito.`);
                }
                if (rule.includes("max")) {
                    const length = Number(rule.substring(4));
                    result.push((v) => !v || v.length <= length || `${label} não deve ser maior que ${length} caracteres.`);
                }
                if (rule.includes("gte")) {
                    const size = Number(rule.substring(4));
                    result.push((v) => !v || v >= size || `O campo ${label} deve ser maior ou igual à ${size}.`);
                } else if (rule.includes("gt")) {
                    const size = Number(rule.substring(3));
                    result.push((v) => !v || v > size || `O campo ${label} deve ser maior que ${size}.`);
                }
                if (rule == "email") {
                    result.push((v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || `${label} deve ser válido`);
                }
                if (rule == 'url') {
                    result.push((v) => !v || this.validateUrl(v) || `${label} é inválido.`)
                }
                if (rule == 'positive') {
                    result.push((v) => !v || v > 0 || `O campo ${label} deve ser positivo.`)
                }
                if (rule == 'cpf') {
                    result.push((v) => !v || this.validateCPF(v) || `O campo ${label} é inválido.`)
                }
            });
            return result;
        },
        empty(value) {
            if (Array.isArray(value)) {
                return value.length == 0;
            }
            if (_.isBoolean(value)) {
                return false;
            }
            return !value;
        },
        validateUrl(value) {
            return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
        },
        validateCPF(strCPF) {
            strCPF = strCPF.replaceAll('.', '').replaceAll('-', '');
            var Soma;
            var Resto;
            var i = null;
            Soma = 0;
            if (strCPF == "00000000000") return false;
            if (strCPF == "11111111111") return false;
            if (strCPF == "22222222222") return false;
            if (strCPF == "33333333333") return false;
            if (strCPF == "44444444444") return false;
            if (strCPF == "55555555555") return false;
            if (strCPF == "66666666666") return false;
            if (strCPF == "77777777777") return false;
            if (strCPF == "88888888888") return false;
            if (strCPF == "99999999999") return false;

            for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11)) Resto = 0;
            if (Resto != parseInt(strCPF.substring(9, 10))) return false;

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11)) Resto = 0;
            if (Resto != parseInt(strCPF.substring(10, 11))) return false;
            return true;
        },
    },
}
