import api from './index';

const getPapelPactuacoes = () => api.get('papel-pactuacao');
const createPapelPactuacao = (dados) => api.post('papel-pactuacao', dados);
const updatePapelPactuacao = (papel_pactuacao_id, dados) => api.put(`papel-pactuacao/${papel_pactuacao_id}`, dados);
const deletePapelPactuacao = (papel_pactuacao_id) => api.delete(`papel-pactuacao/${papel_pactuacao_id}`);

export {
    getPapelPactuacoes,
    createPapelPactuacao,
    updatePapelPactuacao,
    deletePapelPactuacao
}
