import api from "./index";

const getAnaliseProcessoSeletivo = (id_processo_seletivo) => api.get('processo-seletivo/' + id_processo_seletivo + '/analise-processo-seletivo');

const upsertAnaliseProcessoSeletivo = (id_processo_seletivo, dados) => api.post(
    "processo-seletivo/" + id_processo_seletivo + "/analise-processo-seletivo",
    dados
);

const updateAnaliseChecklistOrgao = (id_processo_seletivo, id_analise_processo_seletivo, dados) => api.put(
    "processo-seletivo/" + id_processo_seletivo + "/analise-processo-seletivo/" + id_analise_processo_seletivo + "/checklist-orgao",
    dados
);

const enviarAnaliseOrgao = (id_processo_seletivo, dados) => api.put(
    "processo-seletivo/" + id_processo_seletivo + "/analise-processo-seletivo/enviar-analise-orgao",
    dados
);

const enviarAjustes = (id_processo_seletivo, dados) => api.put(
    "processo-seletivo/" + id_processo_seletivo + "/analise-processo-seletivo/enviar-ajustes",
    dados
);

export {
    getAnaliseProcessoSeletivo,
    upsertAnaliseProcessoSeletivo,
    updateAnaliseChecklistOrgao,
    enviarAnaliseOrgao,
    enviarAjustes,
};
