<template>
    <div>
        <div class="d-flex mb-3">
            <v-spacer></v-spacer>
            <v-btn
                outlined
                @click="$emit('view', returnToView, historico)"
            >
                <v-icon left>
                    mdi-arrow-left
                </v-icon>
                Voltar
            </v-btn>
        </div>
        <PactuacaoResultadoFields type="historico" :model="historico" :disabled="disabled">
            <template v-slot:instrucao>
                <v-sheet class="d-flex flex-wrap justify-space-between pa-4  my-5 subtitle-1">
                    <div><b>Tipo:</b> {{ historico.tipo_historico.nm_tipo_historico }}</div>
                    <div><b>Orgão ou Entidade:</b> {{ historico.nm_orgao }}</div>
                    <div><b>Data Início:</b> {{ dateFormat(historico.dt_inicio) }}</div>
                    <div><b>Data Fim:</b> {{ dateFormat(historico.dt_fim) }}</div>
                    <div><b>Situação:</b> {{ historico.nm_situacao }}</div>
                </v-sheet>
            </template>
        </PactuacaoResultadoFields>
    </div>
</template>

<script>
import PactuacaoResultadoFields from "../FormInputs/PactuacaoResultadoFields.vue";
import {dateMixin} from "../../mixins/dateMixin";

export default {
    name: "FichaHistoricoPactuacao",
    components: {PactuacaoResultadoFields},
    mixins: [dateMixin],
    props: {
        historico: {
            type: Object,
            required: true,
        },
        returnToView: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>
