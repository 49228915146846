<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Nome *"
                            outlined
                            dense
                            v-model="form.nm_anexo"
                            :rules="validate('required', 'nome')"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Ordem Envio SEI *"
                            type="number"
                            outlined
                            dense
                            v-model="form.nr_ordem_sei"
                            :rules="validate('required|positive', 'ordem envio sei')"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="ID SEI *"
                            type="number"
                            outlined
                            dense
                            v-model="form.id_sei"
                            :rules="validate('required|positive', 'id sei')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)" />
                <DeleteAction @delete="deletar(item[column_id])" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {createTipoAnexo, deleteTipoAnexo, getTipoAnexos, updateTipoAnexo} from "../../api/tipo-anexo";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaTipoAnexo",
    components: {EditAction, DeleteAction},
    mixins: [listasDominiosMixin],
    data() {
        return {
            fields : {
                nm_anexo: null,
                nr_ordem_sei: null,
                id_sei: null,
            },
            headers: [
                { text: 'Nome', value: 'nm_anexo', sortable: false},
                { text: 'Ordem SEI', value: 'nr_ordem_sei', sortable: false},
                { text: 'ID SEI', value: 'id_sei', sortable: false},
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            column_id: 'id_tipo_anexo',
            api: {
                index: getTipoAnexos,
                create: createTipoAnexo,
                update: updateTipoAnexo,
                delete: deleteTipoAnexo,
            }
        }
    }
}
</script>
