<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Turma de ingresso *"
                            outlined
                            dense
                            v-model="form.nm_turma_ingresso"
                            :rules="validate('required', 'área de concentração')"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <CarreiraSelect
                            label="Carreira *"
                            v-model="form.id_cargo"
                            :rules="validate('required', 'carreira')"
                            group-carreiras
                        />
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.id_cargo="{ item }">
                <v-badge v-if="item.id_cargo == 1" color="blue" content="EPPGG" />
                <v-badge v-else-if="item.id_cargo == 2" color="green" content="ACE" />
                <v-badge v-else-if="item.id_cargo == 3" color="orange" content="AIE / EIS" />
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)" />
                <DeleteAction @delete="deletar(item[column_id])" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {createTurma, deleteTurma, getTurmas, updateTurma} from "../../api/turma";
import CarreiraSelect from "../FormInputs/CarreiraSelect.vue";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";


export default {
    name: "ListaTurmaIngresso",
    components: {EditAction, DeleteAction, CarreiraSelect},
    mixins: [listasDominiosMixin],
    data() {
        return {
            headers: [
                {text: 'Nome', value: 'nm_turma_ingresso', sortable: false},
                {text: 'Carreira', value: 'id_cargo', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            fields: {
                id_cargo: null,
                nm_turma_ingresso: null,
            },
            column_id: 'id_turma_ingresso',
            api: {
                index: getTurmas,
                create: createTurma,
                update: updateTurma,
                delete: deleteTurma,
            }
        }
    },
}
</script>
