<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col cols="12" md="9">
                        <v-text-field
                            label="Nome cargo ou função *"
                            outlined
                            dense
                            v-model="form.nm_funcao"
                            :rules="validate('required', 'nome cargo ou função')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            label="Sigla *"
                            outlined
                            dense
                            v-model="form.sg_funcao"
                            :rules="validate('required', 'sigla')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)" />
                <DeleteAction @delete="deletar(item[column_id])" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {createFuncao, deleteFuncao, getFuncoes, updateFuncao} from "../../api/funcao";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaFuncao",
    components: {EditAction, DeleteAction},
    mixins: [listasDominiosMixin],
    data() {
        return {
            headers: [
                {text: 'Nome', value: 'nm_funcao', sortable: false},
                {text: 'Sigla', value: 'sg_funcao', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            fields: {
                nm_funcao: null,
                sg_funcao: null,
                st_cadastrado_manual: true,
            },
            form: {
                nm_funcao: null,
                sg_funcao: null,
                st_cadastrado_manual: true,
            },
            column_id: 'id_funcao',
            api: {
                index: getFuncoes,
                create: createFuncao,
                update: updateFuncao,
                delete: deleteFuncao,
            }
        }
    },
}
</script>
