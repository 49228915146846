<template>
    <v-expansion-panels
        v-model="panel"
        :disabled="disabled"
        multiple
    >
        <v-row>
            <v-col
                v-for="(panelData, key) in panelsData"
                :key="key"
                :cols="panelData.size.cols"
                :md="panelData.size.md"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header
                        :color="verificaPanelAberto(key) ? 'primary' : undefined"
                        :class="verificaPanelAberto(key) ? 'white--text' : null"
                        class="text-h6"
                    >{{ panelData.title }}</v-expansion-panel-header>
                    <v-expansion-panel-content class="my-3">
                        <component :is="panelData.component" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>
        </v-row>
    </v-expansion-panels>
</template>

<script>
import ListaRacas from "../components/ListasDominios/ListaRacas";
import ListaTipoDeficiencia from "../components/ListasDominios/ListaTipoDeficiencia";
import ListaTempoDeficiencia from "../components/ListasDominios/ListaTempoDeficiencia";
import ListaGrauDeficiencia from "../components/ListasDominios/ListaGrauDeficiencia";
import ListaAreaConcentracao from "../components/ListasDominios/ListaAreaConcentracao";
import ListaEsfera from "../components/ListasDominios/ListaEsfera";
import ListaNivelAfastamento from "../components/ListasDominios/ListaNivelAfastamento";
import ListaStatusPactuacao from "../components/ListasDominios/ListaStatusPactuacao";
import ListaPoder from "../components/ListasDominios/ListaPoder";
import ListaPosicoesLideranca from "../components/ListasDominios/ListaPosicoesLideranca";
import ListaTipoAnexo from "../components/ListasDominios/ListaTipoAnexo";
import ListaTurmaIngresso from "../components/ListasDominios/ListaTurmaIngresso";
import ListaPapelPactuacao from "../components/ListasDominios/ListaPapelPactuacao";
import ListaInstituicaoEnsino from "../components/ListasDominios/ListaInstituicaoEnsino";
import ListaModalidadeTrabalho from "../components/ListasDominios/ListaModalidadeTrabalho";
import ListaPoliticasPublicas from "../components/ListasDominios/ListaPoliticasPublicas";
import ListaAreaAtuacao from "../components/ListasDominios/ListaAreaAtuacao";
import ListaSituacao from "../components/ListasDominios/ListaSituacao";
import ListaNivelFuncaoHierarquia from "../components/ListasDominios/ListaNivelFuncaoHierarquia";
import ListaCriterioDiversidade from "../components/ListasDominios/ListaCriterioDiversidade.vue";

export default {
    name: "ListasDominios",
    data: () => ({
        panel: [],
        disabled: false,
        panelsData: [
            {
                size: { cols: 12 },
                title: "Area de Atuação",
                component: ListaAreaAtuacao
            },
            {
                size: { cols: 12 },
                title: "Area de Concentração",
                component: ListaAreaConcentracao
            },
            {
                size: {cols: 12, md: 6},
                title: "Critério de Diversidade",
                component: ListaCriterioDiversidade,
            },
            {
                size: {cols: 12, md: 6},
                title: 'Deficiência: Tipo de Deficiência',
                component: ListaTipoDeficiencia,
            },
            {
                size: {cols: 12, md: 6},
                title: "Deficiência: Tempo de Deficiência",
                component: ListaTempoDeficiencia,
            },
            {
                size: {cols: 12, md: 6},
                title: "Deficiência: Grau de Deficiência",
                component: ListaGrauDeficiencia,
            },
            {
                size: { cols: 12, md: 6 },
                title: "Esferas",
                component: ListaEsfera
            },
            {
                size: {cols: 12, md: 6},
                title: "Modalidade de Trabalho",
                component: ListaModalidadeTrabalho,
            },
            {
                size: {cols: 12, md: 6},
                title: "Nível do Afastamento",
                component: ListaNivelAfastamento,
            },
            {
                size: {cols: 12, md: 6},
                title: "Instituição de Ensino",
                component: ListaInstituicaoEnsino,
            },
            {
                size: {cols: 12, md: 6},
                title: "Pactuação: Papel do Servidor",
                component: ListaPapelPactuacao,
            },
            {
                size: {cols: 12, md: 6},
                title: "Pactuação: Status da Pactuação",
                component: ListaStatusPactuacao,
            },
            {
                size: {cols: 12, md: 6},
                title: "Poderes",
                component: ListaPoder,
            },
            {
                size: {cols: 12, md: 6},
                title: "Posições de Liderança",
                component: ListaPosicoesLideranca,
            },
            {
                size: {cols: 12, md: 6},
                title: "Políticas Públicas",
                component: ListaPoliticasPublicas,
            },
            {
                size: {cols: 12, md: 6},
                title: 'Raças',
                component: ListaRacas,
            },
            {
                size: {cols: 12, md: 6},
                title: "Tipo do Anexo",
                component: ListaTipoAnexo,
            },
            {
                size: {cols: 12, md: 6},
                title: "Turma de Ingresso",
                component: ListaTurmaIngresso,
            },
            {
                size: {cols: 12},
                title: 'Sigla, Nível e Hierarquia do Cargo ou Função',
                component: ListaNivelFuncaoHierarquia,
            },
            {
                size: {cols: 12},
                title: 'Situações',
                component: ListaSituacao,
            },
        ]
    }),
    methods: {
        verificaPanelAberto(value) {
            return this.panel.indexOf(value) > -1;
        }
    },

}
</script>
