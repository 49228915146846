<template>
    <v-tooltip top>
        <template v-slot:activator="{ on: tooltip}">
            <v-btn
                fab
                color="primary"
                x-small
                elevation="0"
                :to="to"
                v-on="{ ...tooltip}"
            >
                <v-icon>
                    mdi-eye
                </v-icon>
            </v-btn>
        </template>
        <span>Visualizar</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "ViewAction",
    props: {
        to: {
            type: Object,
            required: true
        }
    }
}
</script>
