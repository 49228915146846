import api from './index';

const getProcessoSeletivoInscritos = async (id_processo_seletivo) => await api.get(`processo-seletivo/${id_processo_seletivo}/inscritos`);
const updateClassificacaoInscrito = (id_processo_seletivo, id_servidor, dados) => api.put(`processo-seletivo/${id_processo_seletivo}/inscrito/${id_servidor}`, dados);
const gerarSolicitarMovimentacaoInscrito = (id_processo_seletivo, id_servidor) => api.post(`processo-seletivo/${id_processo_seletivo}/inscrito/${id_servidor}/solicitacao-movimentacao`);

export {
    getProcessoSeletivoInscritos,
    updateClassificacaoInscrito,
    gerarSolicitarMovimentacaoInscrito
}
