<template>
    <div>
        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :to="{name: 'solicitacao-movimentacao-form'}"
            >
                Novo Registro
            </v-btn>
        </div>
        <v-sheet
            v-if="$store.getters['auth/usuarioPermissao'](['carreira'])"
            class="mb-5 pa-md-5"
            :outlined="$vuetify.breakpoint.mdAndUp"
            rounded
        >
            <h2 class="subtitle-1 mx-4 mx-md-0 mb-3 font-weight-bold">Minhas Movimentações</h2>
            <v-data-table
                :headers="headers()"
                :items="itemsMinhasMovimentacoes"
                :items-per-page="paginationMinhasMovimentacoes.itemsPerPage"
                :footer-props="footerProps"
                :options.sync="paginationMinhasMovimentacoes"
                @update:options="filtrarMinhasMovimentacoes()"
                :server-items-length="paginationMinhasMovimentacoes.total"
                class="elevation-1"
            >
                <template v-slot:item.dt_envio_solicitacao="{ item }">
                    {{ dateFormat(item.dt_envio_solicitacao) }}
                </template>
                <template v-slot:item.nm_status_solicitacao="{ item }">
                    <div v-if="item.id_status_solicitacao === 3">
                        <span v-if="!item.st_orgao_revisao_enviada && !item.st_servidor_aceite_enviado">{{ item.nm_status_solicitacao }}</span>
                        <span v-else-if="item.st_orgao_revisao_enviada">Pendências do servidor</span>
                        <span v-else>Pendências do órgão solicitante</span>
                    </div>
                    <div v-else>
                        <span>{{ item.nm_status_solicitacao }}</span>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <EditAction
                        v-if="item.id_status_solicitacao === 3"
                        :to="{name: 'solicitacao-movimentacao-form', params: {solicitacao: item.id_solicitacao_movimentacao}}"
                    />
                    <ViewAction
                        v-else-if="item.id_status_solicitacao !== 1"
                        :to="{name: 'solicitacao-movimentacao-form', params: {solicitacao: item.id_solicitacao_movimentacao}}"
                    />
                </template>
            </v-data-table>
        </v-sheet>
        <v-sheet
            class="pa-md-5"
            :outlined="$vuetify.breakpoint.mdAndUp"
            rounded
        >
            <h2 class="subtitle-1 mx-4 mx-md-0 mb-3 font-weight-bold">
                Acompanhamento de Solicitações de Movimentação
            </h2>
            <v-card class="mb-9" v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                label="Nome"
                                outlined
                                dense
                                @keyup.enter="filtrar()"
                                :loading="loading"
                                v-model="filtro['tb_solicitacao_movimentacao.id_servidor']"
                                :search-input.sync="searchServidorByNome"
                                :items="comboboxServidores"
                                :filter="filterNormalize"
                                item-text="nm_servidor"
                                item-value="id_servidor"
                                :return-object="false"
                                hide-no-data
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <OrgaoEntidadeSIORGAutocomplete
                                clearable
                                v-model="filtro['tb_solicitacao_movimentacao.id_orgao']"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <CarreiraSelect v-model="filtro['tb_servidor.id_cargo']" />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-select
                                label="Status"
                                outlined
                                dense
                                clearable
                                v-model="filtro['tb_solicitacao_movimentacao.id_status_solicitacao']"
                                :items="status"
                                item-value="id_status_solicitacao"
                                item-text="nm_status_solicitacao"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-select
                                label="Analista"
                                outlined
                                dense
                                clearable
                                v-model="filtro['tb_analise_solicitacao.id_analista_responsavel']"
                                :items="analistas"
                                item-value="id_usuario"
                                item-text="nm_usuario"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                label="Solicitante"
                                outlined
                                dense
                                clearable
                                v-model="filtro['tb_solicitacao_movimentacao.id_usuario_solicitante']"
                                :items="solicitantes"
                                item-value="id_usuario"
                                item-text="nm_usuario"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        @click="limparFiltro()"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>
                            mdi-filter
                        </v-icon>
                        Filtrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-data-table
                :headers="headers()"
                :items="items"
                :items-per-page="pagination.itemsPerPage"
                :footer-props="footerProps"
                :options.sync="pagination"
                @update:options="filtrar()"
                :server-items-length="pagination.total"
                class="elevation-1"
            >
                <template v-slot:item.dt_envio_solicitacao="{ item }">
                    {{ dateFormat(item.dt_envio_solicitacao) }}
                </template>
                <template v-slot:item.nm_status_solicitacao="{ item }">
                    <div v-if="item.id_status_solicitacao === 3">
                        <span v-if="!item.st_orgao_revisao_enviada && !item.st_servidor_aceite_enviado">{{ item.nm_status_solicitacao }}</span>
                        <span v-else-if="item.st_orgao_revisao_enviada">Pendências do servidor</span>
                        <span v-else>Pendências do órgão solicitante</span>
                    </div>
                    <div v-else>
                        <span>{{ item.nm_status_solicitacao }}</span>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <EditAction
                        v-if="item.id_status_solicitacao == 1 ||
                        item.id_status_solicitacao == 3 ||
                        $store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        :to="{name: 'solicitacao-movimentacao-form', params: {solicitacao: item.id_solicitacao_movimentacao}}"
                    />
                    <ViewAction
                        v-else
                        :to="{name: 'solicitacao-movimentacao-form', params: {solicitacao: item.id_solicitacao_movimentacao}}"
                    />

                    <DeleteAction
                        v-if="$store.getters['auth/usuarioPermissao'](['administrador'])"
                        @delete="deletar(item.id_solicitacao_movimentacao)"
                    />

                    <ChangeUsuarioSolicitanteAction
                        v-if="$store.getters['auth/usuarioPermissao'](['administrador'])"
                        :solicitacao="item"
                        type="solicitacao_movimentacao"
                        :solicitantes="solicitantes"
                    />
                </template>
            </v-data-table>
        </v-sheet>
    </div>
</template>

<script>
import {dataTableMixin} from "../mixins/dataTableMixin";
import {deleteSolicitacaoMovimentacao, getSolicitacoesMovimentacao} from "../api/solicitacao-movimentacao";
import {dateMixin} from "../mixins/dateMixin";
import {orgaoSecretariaUnidadeMixin} from "../mixins/orgaoSecretariaUnidadeMixin";
import _ from "lodash";
import {getServidores} from "../api/servidor";
import {getStatusSolicitacao} from "../api/status-solicitacao";
import {getUsuarios} from "../api/usuario";
import CarreiraSelect from "../components/FormInputs/CarreiraSelect.vue";
import OrgaoEntidadeSIORGAutocomplete from "../components/FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
import DeleteAction from "../components/Table/DeleteAction.vue";
import ViewAction from "../components/Table/ViewAction.vue";
import EditAction from "../components/Table/EditAction.vue";
import ChangeUsuarioSolicitanteAction from "../components/Table/ChangeUsuarioSolicitanteAction.vue";

export default {
    name: "SolicitacaoMovimentacaoListar",
    components: {
        ChangeUsuarioSolicitanteAction,
        EditAction, ViewAction, DeleteAction, OrgaoEntidadeSIORGAutocomplete, CarreiraSelect},
    mixins: [dataTableMixin, dateMixin, orgaoSecretariaUnidadeMixin],
    data() {
        return {
            status: [],
            analistas: [],
            solicitantes: [],
            itemsMinhasMovimentacoes: [],
            loading: false,
            search: null,
            searchServidorByNome: null,
            comboboxServidores: [],
            pagination: {
                sortBy: ['dt_envio_solicitacao', 'id_solicitacao_movimentacao'],
                sortDesc: [false, true],
            },
            paginationMinhasMovimentacoes: {
                ...this.pagination,
                sortBy: ['dt_envio_solicitacao', 'id_solicitacao_movimentacao'],
                sortDesc: [false, true],
            },
            filtro: {},
            headers: () => {
                let result = [
                    {text: 'Nome do Servidor', value: 'nm_servidor', sortable: true},
                    {text: 'Carreira', value: 'sg_cargo', sortable: true},
                    {text: 'Órgão ou Entidade', value: 'nm_orgao', sortable: true},
                    {text: 'Secretaria ou Departamento', value: 'nm_secretaria_departamento_exercicio', sortable: true},
                    {text: 'Nome do Solicitante', value: 'nm_usuario_solicitante', sortable: true},
                    {text: 'Tipo de Movimentação', value: 'nm_situacao', sortable: true},
                    {text: 'Data', value: 'dt_envio_solicitacao', sortable: true},
                    {text: 'Status', value: 'nm_status_solicitacao', sortable: true},
                    {text: 'Ações', value: 'actions', sortable: false},
                ]
                if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
                    result.splice(5, 0, {text: 'Analista', value: 'nm_usuario', sortable: true})
                }
                return result
            }
        }
    },
    watch: {
        searchServidorByNome: _.debounce(function (val) {
            val && val !== this.filtro.nm_servidor && this.getServidoresByNome(val)
        }, 600)
    },
    created() {
        if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
            getStatusSolicitacao().then(response => {
                this.status = response.data.data;
            });
            getUsuarios(1, 10000, {}).then(response => {
                this.analistas = response.data.data.data.filter(usuario => usuario.in_perfis.includes('analista_cgcat'));
                this.solicitantes = response.data.data.data;
            });
        }
    },
    methods: {
        getServidoresByNome(nome) {
            this.loading = true
            const filtro = {nm_servidor: nome}
            getServidores(1, 30, filtro)
                .then(response => {
                    this.comboboxServidores = response.data.data.data
                })
                .finally(() => {
                    this.loading = false
                });
        },
        filtrar() {
            if (!this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat', 'rh'])) {
                this.filtro.id_usuario_solicitante = this.$store.getters['auth/usuarioAutenticado']
            }
            this.handlerPaginatePromise(getSolicitacoesMovimentacao(this.pagination, this.filtro))
        },
        filtrarMinhasMovimentacoes() {
            this.handlerPaginatePromise(
                getSolicitacoesMovimentacao(
                    this.paginationMinhasMovimentacoes,
                    {'tb_solicitacao_movimentacao.id_servidor': this.$store.getters['auth/usuarioAutenticadoServidor']}
                ),
                'itemsMinhasMovimentacoes'
            )
        },
        limparFiltro() {
            this.filtro = {}
            this.filtrar()
        },
        deletar(id_solicitacao_movimentacao) {
            deleteSolicitacaoMovimentacao(id_solicitacao_movimentacao)
                .then(response => {
                    this.items = this.items.filter(item => item.id_solicitacao_movimentacao !== id_solicitacao_movimentacao);
                    this.itemsMinhasMovimentacoes = this.itemsMinhasMovimentacoes.filter(item => item.id_solicitacao_movimentacao !== id_solicitacao_movimentacao);

                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        },
    },
}
</script>
