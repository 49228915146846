<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12">
                <v-autocomplete
                    v-show="!form.nm_situacao"
                    label="Definir tipo de movimentação *"
                    outlined
                    dense
                    v-model="form.id_situacao"
                    :rules="validate('required', 'definir tipo de movimentação')"
                    :filter="filterNormalize"
                    :items="situacoes"
                    item-value="id_situacao"
                    item-text="nm_situacao"
                ></v-autocomplete>
                <v-text-field
                    v-show="!!form.nm_situacao"
                    label="Definir tipo de movimentação *"
                    outlined
                    dense
                    clearable
                    readonly
                    v-model="form.nm_situacao"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <AtribuirResponsavelSelect
                    v-model="form.id_analista_responsavel"
                    :rules="validate('required', 'atribuir responsável')"
                />
            </v-col>
            <v-col cols="12">
                <v-select
                    label="Atribuir status *"
                    outlined
                    dense
                    v-model="form.id_status_solicitacao"
                    :rules="validate('required', 'atribuir status')"
                    :items="status"
                    item-value="id_status_solicitacao"
                    item-text="nm_status_solicitacao"
                ></v-select>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Definir prazo de entrega"
                    type="date"
                    outlined
                    dense
                    v-model="form.dt_prazo"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    label="Orientações para Analista CGCAT"
                    outlined
                    dense
                    v-model="form.ds_orientacoes"
                ></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Nº processo SEI"
                    outlined
                    dense
                    disabled
                    v-mask="'#####.######/####-##'"
                    :value="numeroSEI"
                ></v-text-field>
            </v-col>
        </v-row>
        <div class="d-flex flex-wrap justify-end mb-5">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                outlined
                class="mb-2"
                :disabled="disableBtnGerarDocumentosPDF"
                @click="gerarDocumentosPDF"
            >
                Gerar Documentos PDF
            </v-btn>
            <v-btn
                color="primary"
                outlined
                class="ml-2 mb-2"
                :disabled="disableBtnProcessoSei"
                @click="dialog = true"
            >
                Abrir Processo no SEI
            </v-btn>
            <v-btn
                class="ml-2 mb-2"
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>
        <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>
                    Abrir Processo no SEI
                </v-card-title>
                <v-card-text>
                    Tem certeza que deseja abrir o processo no SEI?
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="processoSEI"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <SelecaoArquivosDownloadDialog
            :id_solicitacao="id_solicitacao"
            :nm_servidor="nm_servidor"
            v-if="showSelecaoArquivosDownload"
            @close="showSelecaoArquivosDownload = false"
        />
    </v-form>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao.vue";
import {getSituacoesByTipoHistorico} from "../../api/situacao";
import {getStatusSolicitacao} from "../../api/status-solicitacao";
import {abrirProcessoSEI, upsertAnaliseSolicitacao} from "../../api/analise-solicitacao";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import SelecaoArquivosDownloadDialog from "./SelecaoArquivosDownloadDialog.vue";
import AtribuirResponsavelSelect from "../FormInputs/AtribuirResponsavelSelect.vue";

export default {
    name: "AnaliseSolicitacaoGeral",
    props: ['analiseSolicitacao', 'id_cargo', 'id_solicitacao', 'id_status_solicitacao', 'nm_servidor'],
    components: {AtribuirResponsavelSelect, SelecaoArquivosDownloadDialog, RowDataUsuarioAlteracao},
    mixins: [validationMixin, dataUsuarioAlteracaoMixin, filterNormalizeMixin],
    data() {
        return {
            dialog: false,
            showSelecaoArquivosDownload: false,
            situacoes: [],
            status: [],
            numeroSEI: null,
            form: {
                id_situacao: null,
                nm_situacao: null,
                id_analista_responsavel: null,
                id_status_solicitacao: null,
                dt_prazo: null,
                ds_orientacoes: null,
            }
        }
    },
    watch: {
        analiseSolicitacao(obj) {
            this.form.id_situacao = obj.id_situacao
            this.form.nm_situacao = obj.nm_situacao
            this.form.id_analista_responsavel = obj.id_analista_responsavel
            this.form.dt_prazo = obj.dt_prazo
            this.form.ds_orientacoes = obj.ds_orientacoes
            this.usuarioAlteracao = obj.usuario?.nm_usuario
            this.numeroSEI = obj.nr_autorizacao_processo_sei
            this.setDataAlteracao(obj.updated_at)
        },
        'form.id_situacao'(value) {
            if (!this.form.nm_situacao) {
                let situacao = this.situacoes.filter(situacao => situacao.id_situacao == value)
                this.form.nm_situacao = situacao[0].nm_situacao
            }
        },
        id_status_solicitacao(value) {
            this.form.id_status_solicitacao = value
        }
    },
    computed: {
        disableBtnProcessoSei(){
            return this.numeroSEI !== null || this.analiseSolicitacao == null
        },
        disableBtnGerarDocumentosPDF(){
            return this.analiseSolicitacao == null
        }
    },
    created() {
        getSituacoesByTipoHistorico(this.id_cargo, 3).then(response => {
            this.situacoes = response.data.data;
        });
        getStatusSolicitacao().then(response => {
            this.status = response.data.data;
        });
        this.form.id_status_solicitacao = this.id_status_solicitacao;
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                upsertAnaliseSolicitacao(this.id_solicitacao, this.form).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.setDataUsuarioAlteracaoByResponse(response);
                    if (!this.analiseSolicitacao) {
                        this.$emit('setAnaliseSolicitacao', response.data.data);
                    }
                    if (!this.form.id_status_solicitacao != this.id_status_solicitacao) {
                        this.$emit('setIdStatusSolicitacao', this.form.id_status_solicitacao);
                    }
                    this.$vuetify.goTo(0);
                });
            }
        },
        processoSEI() {
            abrirProcessoSEI(this.id_solicitacao, this.analiseSolicitacao.id_analise_solicitacao)
                .then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.numeroSEI = response.data.data.nr_autorizacao_processo_sei
                })
                .catch(error => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Erro',
                        titulo: error.response.data.message,
                    });
                })
                .finally(() => {
                    this.dialog = false;
                    this.$vuetify.goTo(0);
                })
        },
        gerarDocumentosPDF() {
            this.showSelecaoArquivosDownload = true;
        }
    },
}
</script>
