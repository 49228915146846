import api from './index'

const getAreasConcentracao = (id_cargo) => api.get('area-concentracao', {
    params: {
        id_cargo: id_cargo,
    },
    showLoader: false
})

const createAreaConcentracao = (dados) => api.post('area-concentracao', dados)
const updateAreaConcentracao = (id, dados) => api.put(`area-concentracao/${id}`, dados)
const deleteAreaConcentracao = (id) => api.delete(`area-concentracao/${id}`)

export {
    getAreasConcentracao,
    createAreaConcentracao,
    updateAreaConcentracao,
    deleteAreaConcentracao,
}
