<template>
    <div>
        <h1 class="text-h5">Listagem de Secretaria ou Departamento</h1>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="10">
                        <v-combobox
                            label="Secretaria ou departamento*"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            :loading="loading"
                            v-model="filtro.nm_unidade"
                            :items="itemsUnidades"
                            :search-input.sync="search"
                            :filter="filterNormalize"
                            item-value="nm_unidade"
                            item-text="nm_unidade"
                            :return-object="false"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            label="Status"
                            outlined
                            dense
                            clearable
                            v-model="filtro['st_ativo']"
                            :items="[
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'},
                            ]"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="showModalOrgao = true"
            >
                Novo Registro
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >
            <template v-slot:item.st_ativo="{ item }">
                <v-chip
                    v-if="item.st_ativo"
                    small
                    outlined
                    color="primary"
                >
                    Ativo
                </v-chip>
                <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                >
                    Inativo
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction v-if="item.st_cadastrado_manual" @click="editar(item)" />
                <DeleteAction @delete="deletar(item.id_unidade_siorg)"/>
            </template>
        </v-data-table>


        <v-dialog
            v-model="showModalOrgao"
            transition="dialog-bottom-transition"
            persistent
            max-width="600px"
        >
            <v-form ref="form">
                <v-card>
                    <v-card-title>Cadastro de Secretaria ou Departamento</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Nome secretaria ou departamento *"
                                    outlined
                                    dense
                                    v-model="form.nm_unidade"
                                    :rules="validate('required', 'nome secretaria ou departamento')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    label="Status *"
                                    outlined
                                    dense
                                    v-model="form.st_ativo"
                                    :items="[
                                        {value: true, text: 'Ativo'},
                                        {value: false, text: 'Inativo'},
                                    ]"
                                    :rules="validate('required', 'status')"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            @click="limparEFechar()"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            elevation="0"
                            color="primary"
                            @click="salvar()"
                        >
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>

import {dataTableMixin} from "../mixins/dataTableMixin";
import {createUnidade, deleteUnidade, getUnidadesPaginate, updateUnidade} from "../api/unidade";
import _ from "lodash";
import {validationMixin} from "../mixins/validationMixin";
import {filterNormalizeMixin} from "../mixins/filterNormalizeMixin";
import DeleteAction from "./Table/DeleteAction.vue";
import EditAction from "./Table/EditAction.vue";

export default {
    name: "SecretariaDepartamento",
    components: {EditAction, DeleteAction},
    mixins: [dataTableMixin, validationMixin, filterNormalizeMixin],
    data() {
        return {
            showModalOrgao: false,
            loading: false,
            itemsUnidades: [],
            search: null,
            filtro: {
                id_unidade_pai: this.$route.params.orgao,
                nm_unidade: null,
                st_ativo: true,
            },
            headers: [
                { text: 'Secretaria ou Departamento', value: 'nm_unidade', sortable: false },
                { text: 'Status', value: 'st_ativo', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            form: {
                id_unidade_pai: this.$route.params.orgao,
                st_ativo: true
            }
        }
    },
    watch: {
        search: _.debounce(function (val) {
            val && val !== this.filtro.nm_unidade && this.getUnidadesByNome(val)
        }, 600)
    },
    methods: {
        getUnidadesByNome(nome) {
            this.loading = true
            const filtro = {
                id_unidade_pai: this.$route.params.orgao,
                nm_unidade: nome,
                st_ativo: true,
            }
            getUnidadesPaginate(1, 30, filtro)
                .then(response => {
                    this.itemsUnidades = response.data.data.data
                })
                .finally(() => {
                    this.loading = false
                });
        },
        limparFiltro() {
            this.filtro = {
                id_unidade_pai: this.$route.params.orgao,
                nm_unidade: null,
                st_ativo: null,
            }
            this.filtrar()
        },
        filtrar() {
            this.handlerPaginatePromise(getUnidadesPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro))
        },
        editar(unidade) {
            this.form = {...unidade}
            this.showModalOrgao = true
        },
        salvar() {
            if (this.$refs.form.validate()) {
                let promise
                if (!!this.form.id_unidade_siorg) {
                    promise = updateUnidade(this.form.id_unidade_siorg, this.form)
                } else {
                    promise = createUnidade(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.filtrar()
                    this.limparEFechar()
                    this.$vuetify.goTo(0)
                })
            }
        },
        deletar(id_unidade) {
            deleteUnidade(id_unidade)
                .then(response => {
                    this.items = this.items.filter(item => item.id_unidade_siorg !== id_unidade);
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        },
        limparEFechar() {
            this.form = {
                id_unidade_pai: this.$route.params.orgao,
                st_ativo: true
            }
            this.showModalOrgao = false
        }
    },
}
</script>
