import api from './index';

const getOportunidadesMovimentacoes = async (optionsPagination, filtrosAplicados = []) => await api.get('oportunidade-movimentacao', {
    params: {
        options: optionsPagination,
        filtros: filtrosAplicados,
    }
});

const divulgarOportunidadeMovimentacao = (id) => api.get(
    'oportunidade-movimentacao/' + id + '/divulgar'
);

const inscreverOportunidadeMovimentacao = (id) => api.post(
    'oportunidade-movimentacao/' + id + '/inscrever'
);

export {
    getOportunidadesMovimentacoes,
    divulgarOportunidadeMovimentacao,
    inscreverOportunidadeMovimentacao,
}
