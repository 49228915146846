import api from './index';

const getStatusPactuacao = () => api.get('status-pactuacao');
const createStatusPactuacao = (dados) => api.post('status-pactuacao', dados);
const updateStatusPactuacao = (status_pactuacao_id, dados) => api.put(`status-pactuacao/${status_pactuacao_id}`, dados);
const deleteStatusPactuacao = (status_pactuacao_id) => api.delete(`status-pactuacao/${status_pactuacao_id}`);

export {
    getStatusPactuacao,
    createStatusPactuacao,
    updateStatusPactuacao,
    deleteStatusPactuacao
}
