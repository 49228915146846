<template>
    <v-row>
        <v-col
            v-if="hasPermissaoFichaFuncional"
            class="d-flex col-12"
        >
            <v-spacer />
            <v-btn
                color="primary"
                class="mr-2"
                @click="showFichaFuncional = !showFichaFuncional"
            >
                {{ showFichaFuncional ? 'Solicitação de Movimentação' : 'Ficha Funcional' }}
            </v-btn>
        </v-col>

        <v-col
            v-if="showAnaliseSolicitacao"
            :class="showAnaliseSolicitacao ? 'col-12 col-md-6 col-lg-4' : 'col-12'"
        >
            <analise-solicitacao
                v-if="solicitacao.id_solicitacao_movimentacao"
                :solicitacao="solicitacao"
                @setIdStatusSolicitacao="(status) => solicitacao.id_status_solicitacao = status"
            />
        </v-col>


        <v-col :class="showAnaliseSolicitacao ? 'col-12 col-md-6 col-lg-8' : 'col-12'">
            <solicitacao-movimentacao-stepper
                v-show="!showFichaFuncional"
                :solicitacao="solicitacao"
            />

            <ficha-funcional-tabs
                v-if="hasPermissaoFichaFuncional"
                v-show="showFichaFuncional"
                :servidor="servidor"
            />
        </v-col>
    </v-row>
</template>

<script>
import {getSolicitacaoMovimentacao} from "../api/solicitacao-movimentacao";
import AnaliseSolicitacao from "../components/AnaliseSolicitacao/AnaliseSolicitacao";
import SolicitacaoMovimentacaoStepper from "../components/SolicitacaoMovimentacao/SolicitacaoMovimentacaoStepper.vue";
import FichaFuncionalTabs from "../components/FichaFuncional/FichaFuncionalTabs.vue";
import {getServidor} from "../api/servidor";

export default {
    name: "SolicitacaoMovimentacao",
    components: {FichaFuncionalTabs, SolicitacaoMovimentacaoStepper, AnaliseSolicitacao},
    data() {
        return {
            showFichaFuncional: false,
            solicitacao: {},
            servidor: {},
        }
    },
    computed: {
        hasPermissaoFichaFuncional() {
            return this.solicitacao.id_status_solicitacao > 1 &&
                (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']) ||
                    this.solicitacao.id_servidor === this.$store.getters['auth/usuarioAutenticadoServidor']);
        },
        showAnaliseSolicitacao() {
            let result = false;
            result = this.$store.getters['auth/usuarioPermissao'](['solicitante']) &&
                this.solicitacao.id_status_solicitacao === 3;

            if (!result) {
                result = this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']) &&
                    this.solicitacao.id_status_solicitacao > 1;
            }

            return result;
        }
    },
    async created() {
        if (!!this.$route.params.solicitacao) {
            await getSolicitacaoMovimentacao(this.$route.params.solicitacao).then(response => {
                this.solicitacao = response.data.data
            });
            if (this.hasPermissaoFichaFuncional) {
                await getServidor(this.solicitacao.id_servidor).then((response) => this.servidor = {...response.data.data});
            }
        }
    },
}
</script>
