import api from './index';

const getTipoAnexos = () => api.get('tipo-anexo');
const createTipoAnexo = (dados) => api.post('tipo-anexo', dados);
const updateTipoAnexo = (tipo_anexo_id, dados) => api.put(`tipo-anexo/${tipo_anexo_id}`, dados);
const deleteTipoAnexo = (tipo_anexo_id) => api.delete(`tipo-anexo/${tipo_anexo_id}`);

export {
    getTipoAnexos,
    createTipoAnexo,
    updateTipoAnexo,
    deleteTipoAnexo
}
