import api from './index';

const getOrgaos = (with_trashed) => api.get('orgao', {
    params: {
        with_trashed: with_trashed,
    }
});

const getOrgaosSharepoint = () => api.get('orgao-sharepoint');
const getOrgaosPaginate = (page, itemsPerPage, filtrosAplicados = []) => api.get('orgao-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});
const getOrgao = (id_orgao) => api.get('orgao/' + id_orgao);
const createOrgao = (dados) => api.post('orgao', dados);
const updateOrgao = (id_orgao, dados) => api.put('orgao/' + id_orgao, dados);
const deleteOrgao = (id_orgao) => api.delete('orgao/' + id_orgao);

export {
    getOrgaos,
    getOrgaosSharepoint,
    getOrgaosPaginate,
    getOrgao,
    createOrgao,
    updateOrgao,
    deleteOrgao
}
