<template>
    <v-app-bar color="white" height="100" max-height="100" class="px-md-10">
        <v-app-bar-nav-icon v-if="loggedIn" @click.stop="toggleSideBar" id="btnMenu"
                            color="primary"></v-app-bar-nav-icon>
        <v-toolbar-title :title=" ENV_MIX_GIT_VERSION ">
            {{ ENV_MIX_APP_NAME }}
            <div v-if="ENV_MIX_APP_DESCRIPTION !== null"
                 class="subtitle-2 grey--text lighten-1--text font-weight-regular">
                {{ ENV_MIX_APP_DESCRIPTION }}
            </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
            <v-menu v-model="showNotificacoes" :close-on-content-click="false" offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-badge
                        color="orange"
                        bordered
                        overlap
                        :content="totalNotificacoes"
                        :dot="!totalNotificacoes"
                    >
                        <v-btn
                            fab
                            color="primary"
                            elevation="0"
                            x-small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>
                                mdi-bell
                            </v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <v-card
                    class="mx-auto"
                    min-width="400"
                    tile
                >
                    <div
                        v-if="notificacoes.length > 0"
                        class="overflow-y-auto"
                        style="height: 350px; max-width: 400px; width: 100%;"
                    >
                        <v-list-item
                            v-for="item in notificacoes"
                            :key="item.id"
                            class="border-notification"
                            style="position: unset !important;"
                            :class="item.read_at ? 'border-notification-read' : 'border-notification-unread'"
                        >
                            <v-list-item-content @click="markAsReadAndRedirect(item.id, item.data.link)" style="cursor: pointer">
                                <v-list-item-title class="text-wrap subtitle-2 mb-2">{{ item.data.mensagem }}</v-list-item-title>
                                <v-list-item-subtitle class="text-wrap caption">{{ dateFromNow(item.created_at) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn
                                    fab
                                    color="error"
                                    elevation="0"
                                    x-small
                                    @click="deletarNotificacao(item.id)"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                    </div>
                    <v-sheet v-else height="300" class="d-flex align-center justify-center">
                        <h3>Você não possui notificações</h3>
                    </v-sheet>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text rounded @click="showNotificacoes = false">
                            Fechar
                        </v-btn>
                        <v-btn v-show="!confirmarExclusao && notificacoes.length" color="error" text rounded @click="confirmarExclusao = true">
                            Excluir Tudo
                        </v-btn>
                        <div v-show="confirmarExclusao">
                            <v-subheader>Excluir tudo?</v-subheader>
                            <v-btn text rounded @click="confirmarExclusao = false">
                                Cancelar
                            </v-btn>
                            <v-btn color="error" text rounded @click="deletarNoficiacoes">
                                Confirmar
                            </v-btn>
                        </div>

                    </v-card-actions>
                </v-card>
            </v-menu>
            <v-menu v-if="loggedIn" v-model="showPerfis" :close-on-content-click="false" :nudge-width="200" offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text rounded v-bind="attrs" v-on="on" class="grey--text">
                        <v-icon dark left color="primary">mdi-account</v-icon>
                        {{ usuario.nm_usuario }}
                        <v-icon dark right color="black">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon dark left color="primary">mdi-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ usuario.nm_usuario }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    Perfis:
                                    <span v-for="item in perfis" :key="item">
                                        ( {{ item }} )
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text rounded @click="showPerfis = false">
                            Fechar
                        </v-btn>
                        <v-btn v-if="perfis.filter(perfil => perfil == 'carreira').length == 0" color="primary" rounded
                               @click="dialogMeusDados = true">
                            <v-icon left>mdi-pencil</v-icon>
                            Meus Dados
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
            <v-btn @click="logout" color="primary" rounded>
                Sair
            </v-btn>
        </div>

        <v-dialog
            v-model="dialogMeusDados"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card elevation="0">
                <v-card-text>
                    <usuario-form :usuario="usuario" @closeDialog="dialogMeusDados = false" />
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-termo-uso
            v-if="!!usuario.id_usuario"
            :show="!usuario.st_termo_uso"
        />
    </v-app-bar>
</template>
<script>
import {getMe} from "../api/usuario";
import DialogTermoUso from "./DialogTermoUso";
import UsuarioForm from "./UsuarioForm";
import {deleteNotificacao, deleteNotificacoes, getNotificacoes, markAsReadNotificacao} from "../api/notificacoes";
import moment from 'moment';
moment.locale('pt-br');

export default {
    name: 'app-header',
    components: {UsuarioForm, DialogTermoUso},

    props: {
        loggedIn: Boolean
    },
    data() {
        return {
            ENV_MIX_GIT_VERSION: process.env.MIX_GIT_VERSION,
            ENV_MIX_APP_NAME: process.env.MIX_APP_NAME,
            ENV_MIX_APP_DESCRIPTION: process.env.MIX_APP_DESCRIPTION,
            interval: null,
            showPerfis: false,
            showNotificacoes: false,
            dialogMeusDados: false,
            confirmarExclusao: false,
            perfis: [],
            usuario: {nm_usuario: ''},
            notificacoes: [],
        };
    },
    created() {
        this.getPerfis();
        this.getDadosUsuario()
        this.setNotificacoes()
        this.interval = setInterval(() => {
            this.setNotificacoes()
        }, 30000)
    },
    destroyed() {
        clearInterval(this.interval)
    },
    computed: {
        completarDados() {
            return (!this.usuario.nm_usuario || !this.usuario.nr_cpf || !this.usuario.nr_telefone ||
                !this.usuario.ds_email_institucional)
        },
        totalNotificacoes() {
            return this.notificacoes.filter(notificacao => notificacao.read_at == null).length ?? 0
        }
    },
    methods: {
        logout() {
            let url = 'https://sso.staging.acesso.gov.br/logout';
            if (location.origin.includes('sgc.economia.gov.br')) {
                url = 'https://sso.acesso.gov.br/logout';
            }
            const params = 'post_logout_redirect_uri=' + location.origin;

            this.$store.dispatch('auth/logout').then(
                () => {
                    window.open(`${url}?${params}`, '_self');
                },
                error => {
                    this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        toggleSideBar() {
            this.$store.commit('menu/toggleSideBar');
        },
        async getDadosUsuario() {
            if (this.loggedIn) {
                await getMe().then(response => {
                    this.usuario = {...response.data}
                    if (!this.usuario.id_servidor) {
                        this.dialogMeusDados = this.completarDados
                    }
                });
            }
        },
        getPerfis() {
            this.perfis = this.$store.getters['auth/usuarioAutenticadoRoles'] ? this.$store.getters['auth/usuarioAutenticadoRoles'] : [];
        },

        dateFromNow(date) {
            return moment(date).fromNow()
        },

        setNotificacoes() {
            getNotificacoes().then(response => {
                this.notificacoes = response.data.data
            })
        },

        markAsReadAndRedirect(id, link) {
            markAsReadNotificacao(id).then(response => {
                this.notificacoes.find(notificacao => notificacao.id == id).read_at = new Date()
                this.showNotificacoes = false
                this.$router.push(link)
            })
        },

        deletarNotificacao(id) {
            deleteNotificacao(id).then(response => {
                this.notificacoes = this.notificacoes.filter(notificacao => notificacao.id != id)
            })
        },

        deletarNoficiacoes() {
            this.confirmarExclusao = false
            deleteNotificacoes().then(response => {
                this.notificacoes = []
                this.showNotificacoes = false
            })
        }
    }
}
</script>

<style>
header {
    z-index: 100 !important;
}
.border-notification {
    border-left-width: 4px;
    border-left-style: solid;
}
.border-notification-read {
    border-left-color: rgb(19, 81, 180, 0.2);
}
.border-notification-unread {
    border-left-color: rgb(19, 81, 180);
}
.border-notification:hover {
    border-left-width: 8px;
}
</style>
