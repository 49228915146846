import api from './index';

const getRacas = () => api.get('racas');
const createRaca = (dados) => api.post('racas', dados);
const updateRaca = (raca_id, dados) => api.put(`racas/${raca_id}`, dados);
const deleteRaca = (raca_id) => api.delete(`racas/${raca_id}`);

export {
    getRacas,
    createRaca,
    updateRaca,
    deleteRaca
}
