<template>
    <v-form :disabled="disabled" ref="form">
        <v-card outlined>
            <v-card-text>
                <instrucoes-sheet option-title="Solicitações de Movimentação" />
                <v-sheet class="pa-4  mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Identificação</h2>
                    <p>Preencha abaixo o Nome do(a) servidor(a) desejado(a), clique sobre o nome completo
                        correspondente, confira as informações apresentadas e clique em <strong>Avançar</strong>.</p>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-show="servidor.nm_servidor"
                                label="Nome do(a) servidor(a)*"
                                outlined
                                dense
                                readonly
                                clearable
                                @click:clear="servidor = {}"
                                :value="servidor.nm_servidor"
                            >
                            </v-text-field>
                            <v-autocomplete
                                v-show="!servidor.nm_servidor"
                                label="Nome do(a) servidor(a)*"
                                outlined
                                dense
                                :loading="loading"
                                v-model="form.id_servidor"
                                :search-input.sync="searchServidorByNome"
                                :filter="filterNormalize"
                                :items="itemsServidores"
                                :rules="validate('required', 'nome')"
                                hide-no-data
                                @change="setServidor"
                                item-text="nm_servidor"
                                item-value="id_servidor"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row v-show="servidor.id_servidor">
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Carreira"
                                outlined
                                dense
                                disabled
                                :value="servidor.nm_cargo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="CPF"
                                outlined
                                dense
                                disabled
                                :value="servidor.nr_cpf"
                                v-mask="'###.###.###-##'"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-sheet v-show="servidor.id_servidor" class="pa-4  mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Histórico</h2>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                label="Tipo *"
                                outlined
                                dense
                                disabled
                                :value="historico.id_tipo_historico"
                                :items="itemsTipoHistorico"
                                item-value="id_tipo_historico"
                                item-text="nm_tipo_historico"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                label="Situação *"
                                outlined
                                dense
                                disabled
                                :value="historico.id_situacao"
                                :items="itemsSituacoes"
                                item-value="id_situacao"
                                item-text="nm_situacao"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-sheet v-show="servidor.id_servidor" class="pa-4  mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Órgão Atual</h2>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Órgão ou entidade de exercício"
                                outlined
                                dense
                                disabled
                                :value="historico.nm_orgao"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Secretaria ou departamento de exercício"
                                outlined
                                dense
                                disabled
                                :value="historico.nm_secretaria_departamento_exercicio"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Unidade de exercício"
                                outlined
                                dense
                                disabled
                                :value="historico.nm_unidade_exercicio"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <CidadeEstadoPaisFields
                        :form="{id_pais: historico.id_pais, ds_estado: historico.ds_estado, nm_cidade: historico.nm_cidade}"
                        :required="false"
                        field_pais_disabled
                        field_estado_disabled
                        field_cidade_disabled
                    />
                </v-sheet>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="disabled" @click="salvar">Avançar</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import {getServidoresSemVacancia, getServidorHistoricoAtual} from "../../api/servidor";
import _ from "lodash";
import {enderecoMixin} from "../../mixins/enderecoMixin";
import {getSituacoes} from "../../api/situacao";
import {validationMixin} from "../../mixins/validationMixin";
import {createSolicitacaoMovimentacao, updateSolicitacaoIdentificacao} from "../../api/solicitacao-movimentacao";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import tipoHistorico from "../../api/tipo-historico";
import InstrucoesSheet from "../InstrucoesSheet.vue";
import CidadeEstadoPaisFields from "../FormInputs/CidadeEstadoPaisFields.vue";

export default {
    name: "SolicitacaoIdentificacao",
    components: {CidadeEstadoPaisFields, InstrucoesSheet},
    mixins: [validationMixin, filterNormalizeMixin],
    props: ['disabled', 'solicitacao'],
    data() {
        return {
            filtro: {nm_servidor: null},
            loading: false,
            searchServidorByNome: null,
            itemsServidores: [],
            itemsSituacoes: [],
            itemsTipoHistorico: tipoHistorico,
            servidor: {},
            historico: {},
            form: {
                id_servidor: null,
                id_historico: null,
            }
        }
    },
    created() {
        getSituacoes().then(response => this.itemsSituacoes = response.data.data);
    },
    watch: {
        solicitacao(object) {
            if (object.id_solicitacao_movimentacao) {
                this.form.id_servidor = object.id_servidor;
                this.form.id_historico = object.id_historico;

                this.servidor = {
                    id_servidor: object.servidor.id_servidor,
                    nm_servidor: object.servidor.nm_servidor,
                    nr_cpf: object.servidor.nr_cpf,
                    nm_cargo: object.servidor.cargo.nm_cargo,
                }

                this.historico = {
                    id_historico: object.historico.id_historico,
                    id_tipo_historico: object.historico.id_tipo_historico,
                    id_situacao: object.historico.id_situacao,
                    nm_orgao: object.historico.nm_orgao,
                    nm_secretaria_departamento_exercicio: object.historico.nm_secretaria_departamento_exercicio,
                    nm_unidade_exercicio: object.historico.nm_unidade_exercicio,
                    id_pais: object.historico.id_pais,
                    ds_estado: object.historico.ds_estado,
                    nm_cidade: object.historico.nm_cidade,
                }
            }
        },
        searchServidorByNome: _.debounce(function (val) {
            val && val !== this.filtro.nm_servidor && this.getServidoresByNome(val)
        }, 600)
    },
    methods: {
        setServidor(id_servidor) {
            this.servidor = {};
            this.historico = {};
            if (id_servidor) {
                this.servidor = {...this.itemsServidores.find(servidor => servidor.id_servidor === id_servidor)};
                getServidorHistoricoAtual(id_servidor).then(response => {
                    this.historico = response.data.data;
                    this.form.id_historico = this.historico.id_historico;
                });
            }
        },
        getServidoresByNome(nome) {
            this.loading = true
            const filtro = {nm_servidor: nome}
            getServidoresSemVacancia(1, 30, filtro)
                .then(response =>this.itemsServidores = response.data.data.data)
                .finally(() => {
                    this.loading = false
                });
        },
        salvar() {
            if (this.form.id_servidor === this.$store.getters['auth/usuarioAutenticadoServidor']) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Não é possível abrir uma solicitação para si próprio(a).',
                })
                this.$vuetify.goTo(0)
            } else if (this.$refs.form.validate()) {
                let promise
                if (this.solicitacao.id_solicitacao_movimentacao) {
                    promise = updateSolicitacaoIdentificacao(this.solicitacao.id_solicitacao_movimentacao, this.form)
                } else {
                    promise = createSolicitacaoMovimentacao(this.form);
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    this.$emit('nextStep');
                    this.$router.push({
                        name: 'solicitacao-movimentacao-form',
                        params: {solicitacao: response.data.data.id_solicitacao_movimentacao}
                    })
                }).catch(error => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Erro',
                        titulo: error.response.data.message,
                    })
                }).finally(() => this.$vuetify.goTo(0));
            }
        }
    },
}
</script>
