import api from './index';

const getSolicitacoesMovimentacao = async (optionsPagination, filtrosAplicados = []) => await api.get('solicitacao-movimentacao', {
    params: {
        options: optionsPagination,
        filtros: filtrosAplicados,
    }
});
const getSolicitacaoMovimentacao = async (id) => await api.get(`solicitacao-movimentacao/${id}`);
const getSolicitacaoMovimentacaoAnexoDownload = (id, id_anexo) => api.get(`solicitacao-movimentacao/${id}/anexo/${id_anexo}`, {responseType: 'blob'})
const getSolicitacaoMovimentacaoAnexos = (id, page, itemsPerPage) => api.get(`solicitacao-movimentacao/${id}/anexo`, {
    params: {
        page: page,
        limit: itemsPerPage,
    }
});
const createSolicitacaoMovimentacao = (dados) => api.post('solicitacao-movimentacao', dados);
const createSolicitacaoMovimentacaoAnexo = (id, dados) => api.post(`solicitacao-movimentacao/${id}/anexo`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});
const updateSolicitacaoMovimentacaoAnexo = (id, id_anexo, dados) => api.post(`solicitacao-movimentacao/${id}/anexo/${id_anexo}`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
})
const updateSolicitacaoIdentificacao = (id, dados) => api.put(`solicitacao-movimentacao/${id}/identificacao`, dados);
const updateSolicitacaoOrgaoSolitante = (id, dados) => api.put(`solicitacao-movimentacao/${id}/orgao-solicitante`, dados);
const updateSolicitacaoInformacoesQualitativas = (id, dados) => api.put(`solicitacao-movimentacao/${id}/informacoes-qualitativas`, dados);
const updateSolicitacaoStatus = (id, dados) => api.put(`solicitacao-movimentacao/${id}/status`, dados);
const deleteSolicitacaoMovimentacaoAnexo = (id, id_anexo) => api.delete(`solicitacao-movimentacao/${id}/anexo/${id_anexo}`);

const deleteSolicitacaoMovimentacao = (id) => api.delete(`solicitacao-movimentacao/${id}`);

export {
    getSolicitacoesMovimentacao,
    getSolicitacaoMovimentacao,
    getSolicitacaoMovimentacaoAnexoDownload,
    getSolicitacaoMovimentacaoAnexos,
    createSolicitacaoMovimentacao,
    createSolicitacaoMovimentacaoAnexo,
    updateSolicitacaoMovimentacaoAnexo,
    updateSolicitacaoIdentificacao,
    updateSolicitacaoOrgaoSolitante,
    updateSolicitacaoInformacoesQualitativas,
    updateSolicitacaoStatus,
    deleteSolicitacaoMovimentacaoAnexo,
    deleteSolicitacaoMovimentacao,
}
