<template>
    <div>
        <v-form :disabled="disabled" ref="form">
            <h2 class="subtitle-1 mb-3 font-weight-bold">Responsabilidades do servidor</h2>
            <h3 v-if="showInstrucoes" class="mb-3 subtitle-2">
                Antes de registrar o aceite na movimentação, é necessário que você atualize algumas informações em sua
                Ficha Funcional, conforme check-list a seguir:
            </h3>
            <v-row>
                <v-col
                    v-for="(item, key) in itemsCheckLists"
                    :key="key"
                    cols="12"
                    class="d-flex flex-wrap align-center justify-space-between mt-n4"
                >
                    <span class="col-12 col-lg-5 col-xl-6 pa-0">{{ item.title }}</span>
                    <div class="d-flex align-center">
                        <v-radio-group
                            @change="salvar"
                            v-model="form[item.model]"
                            :rules="validate('required', '')"
                            row
                        >
                            <v-radio label="OK" :value="true"></v-radio>
                            <v-radio label="Não" :value="false"></v-radio>
                        </v-radio-group>
                        <v-btn @click="showObservacao(item)" icon>
                            <v-icon v-show="!item.showObservacao" :color="form[item.observacao] ? 'primary' : ''">
                                {{ form[item.observacao] ? 'mdi-comment-text' : 'mdi-comment-text-outline' }}
                            </v-icon>
                            <v-icon v-show="item.showObservacao">mdi-comment-off</v-icon>
                        </v-btn>
                    </div>
                    <v-expand-transition>
                        <div v-if="item.showObservacao" class="col-12 pa-0">
                            <VueEditor
                                class="col-12 pa-0 mb-3"
                                :disabled="disabled"
                                :editor-toolbar="customToolbar"
                                v-model="form[item.observacao]"
                            />
                            <div class="d-flex justify-end">
                                <v-btn :disabled="disabled" @click="salvar" outlined color="primary">
                                    Salvar
                                </v-btn>
                            </div>
                        </div>
                    </v-expand-transition>
                </v-col>
            </v-row>

            <h2 class="subtitle-1 mb-3 font-weight-bold">Documentos obrigatórios do servidor</h2>
            <v-row>
                <v-col
                    v-for="(item, key) in itemsCheckListsDocumentos"
                    :key="key"
                    cols="12"
                    class="d-flex flex-wrap align-center justify-space-between mt-n4"
                >
                    <v-checkbox
                        class="col-12 col-lg-5 col-xl-6 pa-0"
                        :label="item.title + (form[item.obrigatorio] ? '*' : '')"
                        v-model="form[item.obrigatorio]"
                        @change="changeObrigatorio(item)"
                    />
                    <div class="d-flex align-center">
                        <v-radio-group
                            :disabled="!form[item.obrigatorio]"
                            @change="salvar"
                            v-model="form[item.model]"
                            :rules="form[item.obrigatorio] ? validate('required', '') : []"
                            row
                        >
                            <v-radio label="OK" :value="true"></v-radio>
                            <v-radio label="Não" :value="false"></v-radio>
                        </v-radio-group>
                        <v-btn :disabled="!form[item.obrigatorio]" @click="showObservacao(item)" icon>
                            <v-icon v-show="!item.showObservacao" :color="form[item.observacao] ? 'primary' : ''">
                                {{ form[item.observacao] ? 'mdi-comment-text' : 'mdi-comment-text-outline' }}
                            </v-icon>
                            <v-icon v-show="item.showObservacao">mdi-comment-off</v-icon>
                        </v-btn>
                    </div>
                    <v-expand-transition>
                        <div v-if="item.showObservacao && form[item.obrigatorio]" class="col-12 pa-0">
                            <VueEditor
                                class="col-12 pa-0 mb-3"
                                :disabled="disabled"
                                :editor-toolbar="customToolbar"
                                v-model="form[item.observacao]"
                            />
                            <div class="d-flex justify-end">
                                <v-btn :disabled="disabled" @click="salvar" outlined color="primary">
                                    Salvar
                                </v-btn>
                            </div>
                        </div>
                    </v-expand-transition>
                </v-col>
            </v-row>
            <h3 v-if="showInstrucoes" class="mb-3 subtitle-2">
                Para atualizar as informações em sua Ficha Funcional, navegue nas opções disponíveis do lado direito da
                página e salve as alterações.
            </h3>
            <h3 v-if="showInstrucoes" class="mb-3 subtitle-2">
                Em seguida, confira os detalhes da proposta de trabalho apresentada na Solicitação de Movimentação,
                anexe documentos pendentes, e registre a sua concordância com a movimentação, clicando em ENVIAR.
            </h3>
            <div class="d-flex mt-10">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="openDialog"
                >
                    Enviar
                </v-btn>
            </div>
        </v-form>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>
                    {{ this.showInstrucoes ? 'Enviar Aceite' : 'Enviar Análise' }}
                </v-card-title>
                <v-card-text>
                    <v-form v-if="showInstrucoes">
                        <p class="font-weight-bold">Você está de acordo com os termos dessa movimentação?</p>

                        <div class="d-flex align-center">
                            <v-radio-group
                                :disabled="false"
                                v-model="formAceiteServidor.checklist_servidor_concordancia"
                                row
                            >
                                <v-radio label="Sim" :value="true"></v-radio>
                                <v-radio label="Não" :value="false"></v-radio>
                            </v-radio-group>
                        </div>
                        <div>
                            <v-textarea
                                label="Observação (Opcional)"
                                :disabled="false"
                                outlined
                                dense
                                clearable
                                v-model="form.observacao_servidor_concordancia"
                                :rules="validate('max:5000', 'observação')"
                                counter
                                maxlength="5000"
                            ></v-textarea>
                        </div>
                    </v-form>
                    <p v-else>
                        Deseja confirmar a mudança de status da solicitação e enviar notificação ao servidor?
                    </p>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        {{ this.showInstrucoes ? 'Cancelar' : 'Não' }}
                    </v-btn>
                    <v-btn
                        :disabled="disabledBtnEnviar"
                        color="primary"
                        @click="enviar"
                    >
                        {{ this.showInstrucoes ? 'Enviar' : 'Sim' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    enviarAnaliseServidor,
    registrarAceite,
    updateAnaliseChecklistServidor
} from "../../api/analise-solicitacao";
import {analiseChecklistMixin} from "../../mixins/analiseChecklistMixin";
import { VueEditor } from "vue2-editor";

export default {
    name: "AnaliseSolicitacaoServidor",
    mixins: [analiseChecklistMixin],
    components: { VueEditor },
    data() {
        return {
            salvarFunction: updateAnaliseChecklistServidor,
            itemsCheckLists: [
                {
                    title: 'Dados pessoais atualizado*',
                    model: 'checklist_servidor_dados_pessoais',
                    observacao: 'observacao_servidor_dados_pessoais',
                    observacaoPadrao: '<p>informe a sua raça e se possui algum grau de deficiência.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Contatos atualizado*',
                    model: 'checklist_servidor_contato',
                    observacao: 'observacao_servidor_contato',
                    observacaoPadrao: '<p>atualize seu endereço residencial, telefones e e-mails de contato.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Dados funcionais atualizado*',
                    model: 'checklist_servidor_dados_funcionais',
                    observacao: 'observacao_servidor_dados_funcionais',
                    observacaoPadrao: '<p>informe a área de concentração do seu concurso de admissão e a sua turma.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Histórico de movimentação atualizado*',
                    model: 'checklist_servidor_historico_movimentacao',
                    observacao: 'observacao_servidor_historico_movimentacao',
                    observacaoPadrao: '<p>clique em editar o registro do seu atual órgão de exercício e atualize as seguintes informações:</p><ul><li>Secretaria ou Departamento de exercício;</li><li>Unidade de exercício;</li><li>Nome, cargo, e-mail e telefone da sua atual chefia imediata;</li><li>Informe a sua modalidade de trabalho atual (presencial, teletrabalho ou híbrido);</li><li>Informe se você coordenou equipes e qual o tamanho da equipe;</li><li>Informe o nome do(s) principais projetos que você participou;</li><li>Selecione as principais áreas de atuação no órgão atual;</li><li>Selecione as principais políticas públicas com a qual você trabalhou no órgão atual.</li></ul>',
                    showObservacao: false,
                },
                {
                    title: 'Resultados pactuados atualizados e avaliados*',
                    model: 'checklist_servidor_resultados_pactuados',
                    observacao: 'observacao_servidor_resultados_pactuados',
                    observacaoPadrao: '<p>Clique no botão <strong>Pactuações</strong> (do lado direito, próximo ao cabeçalho superior da Aba Histórico da sua Ficha Funcional) e para cada um dos resultados pactuados, clique em editar e atualize a redação da entrega (se necessário), o prazo de conclusão, o status, e o seu papel na execução. Caso deseje, adicione um comentário.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Interesses atualizado*',
                    model: 'checklist_servidor_interesses',
                    observacao: 'observacao_servidor_interesses',
                    observacaoPadrao: '<p>preencha os campos com informações relativas às áreas de atuação, políticas públicas e posições de liderança <strong>em que você gostaria de trabalhar no futuro.</strong></p>',
                    showObservacao: false,
                },
                {
                    title: 'Currículo do Banco de Talentos atualizado*',
                    model: 'checklist_servidor_banco_talentos',
                    observacao: 'observacao_servidor_banco_talentos',
                    observacaoPadrao: '<p>acesse o módulo “<strong>Currículo e Oportunidades</strong>” do SouGov <a href="http://www.gov.br/sougov" rel="noopener noreferrer" target="_blank">www.gov.br/sougov</a> e atualize suas informações, incluindo os dados referentes à sua atual unidade de exercício. Não é mais necessário enviar cópia em pdf do seu CV à Seges. Em 24h, os dados atualizados estarão disponíveis no SGC. Caso você esteja cadastrando o seu CV pela primeira vez no SouGov, saiba que o link do seu CV no SGC só é atualizado com essa informação semanalmente, aos domingos. Portanto, caso seja urgente, envie na aba Anexos uma cópia pdf do seu currículo. Para obter dicas de como preencher o currículo da forma adequada, pedimos que acesse: <a href="https://view.genial.ly/62ed6dbbd281c40011227c03/personal-branding-busca-ativa" rel="noopener noreferrer" target="_blank">https://view.genial.ly/62ed6dbbd281c40011227c03/personal-branding-busca-ativa</a>.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Linkedin e Genially atualizado*',
                    model: 'checklist_servidor_linkedin_genially',
                    observacao: 'observacao_servidor_linkedin_genially',
                    observacaoPadrao: '<p>Informe o endereço do seu <strong>Portfólio individual profissional</strong> (opcional) e o endereço do seu perfil no <strong>Linkedin</strong> (opcional). Para criar o seu <strong>Portfólio individual profissional no Genially,</strong> siga essas <strong>instruções</strong>&nbsp;(link para pagina com instruções).</p>',
                    showObservacao: false,
                },
                {
                    title: 'Mapeamento de competências*',
                    model: 'checklist_servidor_competencias',
                    observacao: 'observacao_servidor_competencias',
                    observacaoPadrao: '<p>Um novo link para acesso à pesquisa foi encaminhado para o e-mail xxxxxx em xx/xx/2023.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Concordância do servidor*',
                    model: 'checklist_servidor_concordancia',
                    observacao: 'observacao_servidor_concordancia',
                    observacaoPadrao: '<p>após atualizar as informações em sua Ficha Funcional, confira os detalhes da proposta de trabalho apresentada pelo órgão solicitante, role a barra de rolagem do check-list até o fim, registre a sua concordância com a movimentação e clique em Enviar.</p>',
                    showObservacao: false,
                },
            ],
            itemsCheckListsDocumentos: [
                {
                    title: 'Ciência da chefia imediata',
                    obrigatorio: 'obrigatorio_servidor_chefia_imediata',
                    model: 'checklist_servidor_chefia_imediata',
                    observacao: 'observacao_servidor_chefia_imediata',
                    observacaoPadrao: '<p>na Aba Anexos da Solicitação de Movimentação, anexe a cópia em pdf de e-mail dando ciência do convite de movimentação à sua chefia imediata.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Portaria de exoneração do cargo',
                    obrigatorio: 'obrigatorio_servidor_portaria_exoneracao_cargo',
                    model: 'checklist_servidor_portaria_exoneracao_cargo',
                    observacao: 'observacao_servidor_portaria_exoneracao_cargo',
                    observacaoPadrao: '<p>na Aba Anexos da Solicitação de Movimentação, anexe a cópia em pdf da portaria de exoneração do cargo comissionado que você ocupava.</p>',
                    showObservacao: false,
                },
                {
                    title: 'Outros',
                    obrigatorio: 'obrigatorio_servidor_outros',
                    model: 'checklist_servidor_outros',
                    observacao: 'observacao_servidor_outros',
                    observacaoPadrao: null,
                    showObservacao: false,
                },
            ],
            form: {
                checklist_servidor_dados_pessoais: null,
                observacao_servidor_dados_pessoais: null,
                checklist_servidor_contato: null,
                observacao_servidor_contato: null,
                checklist_servidor_dados_funcionais: null,
                observacao_servidor_dados_funcionais: null,
                checklist_servidor_historico_movimentacao: null,
                observacao_servidor_historico_movimentacao: null,
                checklist_servidor_resultados_pactuados: null,
                observacao_servidor_resultados_pactuados: null,
                checklist_servidor_interesses: null,
                observacao_servidor_interesses: null,
                checklist_servidor_banco_talentos: null,
                observacao_servidor_banco_talentos: null,
                checklist_servidor_linkedin_genially: null,
                observacao_servidor_linkedin_genially: null,
                checklist_servidor_competencias: null,
                observacao_servidor_competencias: null,
                checklist_servidor_concordancia: null,
                observacao_servidor_concordancia: null,

                obrigatorio_servidor_chefia_imediata: null,
                checklist_servidor_chefia_imediata: null,
                observacao_servidor_chefia_imediata: null,
                obrigatorio_servidor_portaria_exoneracao_cargo: null,
                checklist_servidor_portaria_exoneracao_cargo: null,
                observacao_servidor_portaria_exoneracao_cargo: null,
                obrigatorio_servidor_outros: null,
                checklist_servidor_outros: null,
                observacao_servidor_outros: null,

                st_servidor_aceite_enviado: false,
            },
            formAceiteServidor : {
                checklist_servidor_concordancia: null,
            },
        }
    },
    watch: {
        'formAceiteServidor.checklist_servidor_concordancia': function (val) {
            this.form.checklist_servidor_concordancia = val;
        },
    },
    created() {
        this.form.checklist_servidor_dados_pessoais = this.analiseSolicitacao.checklist_servidor_dados_pessoais;
        this.form.observacao_servidor_dados_pessoais = this.analiseSolicitacao.observacao_servidor_dados_pessoais;
        this.form.checklist_servidor_contato = this.analiseSolicitacao.checklist_servidor_contato;
        this.form.observacao_servidor_contato = this.analiseSolicitacao.observacao_servidor_contato;
        this.form.checklist_servidor_dados_funcionais = this.analiseSolicitacao.checklist_servidor_dados_funcionais;
        this.form.observacao_servidor_dados_funcionais = this.analiseSolicitacao.observacao_servidor_dados_funcionais;
        this.form.checklist_servidor_historico_movimentacao = this.analiseSolicitacao.checklist_servidor_historico_movimentacao;
        this.form.observacao_servidor_historico_movimentacao = this.analiseSolicitacao.observacao_servidor_historico_movimentacao;
        this.form.checklist_servidor_resultados_pactuados = this.analiseSolicitacao.checklist_servidor_resultados_pactuados;
        this.form.observacao_servidor_resultados_pactuados = this.analiseSolicitacao.observacao_servidor_resultados_pactuados;
        this.form.checklist_servidor_interesses = this.analiseSolicitacao.checklist_servidor_interesses;
        this.form.observacao_servidor_interesses = this.analiseSolicitacao.observacao_servidor_interesses;
        this.form.checklist_servidor_banco_talentos = this.analiseSolicitacao.checklist_servidor_banco_talentos;
        this.form.observacao_servidor_banco_talentos = this.analiseSolicitacao.observacao_servidor_banco_talentos;
        this.form.checklist_servidor_linkedin_genially = this.analiseSolicitacao.checklist_servidor_linkedin_genially;
        this.form.observacao_servidor_linkedin_genially = this.analiseSolicitacao.observacao_servidor_linkedin_genially;
        this.form.checklist_servidor_competencias = this.analiseSolicitacao.checklist_servidor_competencias;
        this.form.observacao_servidor_competencias = this.analiseSolicitacao.observacao_servidor_competencias;
        this.form.checklist_servidor_concordancia = this.analiseSolicitacao.checklist_servidor_concordancia;
        this.form.observacao_servidor_concordancia = this.analiseSolicitacao.observacao_servidor_concordancia;
        this.form.obrigatorio_servidor_chefia_imediata = this.analiseSolicitacao.obrigatorio_servidor_chefia_imediata;
        this.form.checklist_servidor_chefia_imediata = this.analiseSolicitacao.checklist_servidor_chefia_imediata;
        this.form.observacao_servidor_chefia_imediata = this.analiseSolicitacao.observacao_servidor_chefia_imediata;
        this.form.obrigatorio_servidor_portaria_exoneracao_cargo = this.analiseSolicitacao.obrigatorio_servidor_portaria_exoneracao_cargo;
        this.form.checklist_servidor_portaria_exoneracao_cargo = this.analiseSolicitacao.checklist_servidor_portaria_exoneracao_cargo;
        this.form.observacao_servidor_portaria_exoneracao_cargo = this.analiseSolicitacao.observacao_servidor_portaria_exoneracao_cargo;
        this.form.obrigatorio_servidor_outros = this.analiseSolicitacao.obrigatorio_servidor_outros;
        this.form.checklist_servidor_outros = this.analiseSolicitacao.checklist_servidor_outros;
        this.form.observacao_servidor_outros = this.analiseSolicitacao.observacao_servidor_outros;

        if (this.showInstrucoes) {
            this.form.st_servidor_aceite_enviado = true;
        }
    },
    computed: {
        enviarFunction() {
            return this.showInstrucoes ? registrarAceite : enviarAnaliseServidor
        },
        disabledBtnEnviar() {
            if (this.id_status_solicitacao > 3) {
                return true
            } else if (this.showInstrucoes && this.formAceiteServidor.checklist_servidor_concordancia == null) {
                return true
            }

            return false
        },
        customToolbar() {
            if (!this.disabled) {
                return [
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"]
                ]
            }
            return [[]]
        },
    },
    methods: {
        showObservacao(item) {
            item.showObservacao = !item.showObservacao
            if (
                item.showObservacao &&
                item.observacaoPadrao &&
                typeof this.form[item.model] === "boolean" && !this.form[item.model] &&
                !this.form[item.observacao]
            ) {
                this.form[item.observacao] = item.observacaoPadrao
            }
        }
    },
}
</script>
