<template>
    <v-dialog
        :value="true"
        persistent
        max-width="600"
    >
        <v-card>
            <v-card-title>
                Gerênciar movimentação em lote
            </v-card-title>
            <v-card-subtitle class="yellow--text text--darken-3 font-weight-bold">
                {{ historicos.length }} servidores selecionados
            </v-card-subtitle>
            <v-card-text>
                <v-form ref="form">
                    <OrgaoSecretariaUnidadeFields
                        :form="formOrgaoSecretariaUnidade"
                        @update:form="updateForm"
                        :required-fields="false"
                    />

                    <v-row>
                        <v-col cols="12" v-if="verifyHistoricosTipoMovimentacao">
                            <v-autocomplete
                                label="Situação do servidor"
                                outlined
                                dense
                                v-model="form.id_situacao"
                                :filter="filterNormalize"
                                :items="itemsSituacao"
                                item-value="id_situacao"
                                item-text="nm_situacao"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                label="Observação"
                                outlined
                                dense
                                v-model="form.ds_autorizacao_observacao"
                                height="80"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn outlined @click="$emit('close')">
                    cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="disabledSalvar"
                    @click="salvar()"
                >
                    salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {orgaoSecretariaUnidadeMixin} from "../../mixins/orgaoSecretariaUnidadeMixin";
import {validationMixin} from "../../mixins/validationMixin";
import {getSituacoes} from "../../api/situacao";
import {updateHistoricosAtuais} from "../../api/historico";
import OrgaoSecretariaUnidadeFields from "../FormInputs/OrgaoSecretariaUnidadeFields.vue";

export default {
    name: "MovimentacaoLoteFormDialog",
    components: {OrgaoSecretariaUnidadeFields},
    props: ["tiposHistoricos", "historicos", "cargos"],
    mixins: [orgaoSecretariaUnidadeMixin, validationMixin],
    data() {
        return {
            form: {
                id_orgao: null,
                id_secretaria_departamento_exercicio: null,
                nm_unidade_exercicio: null,
                id_situacao: null,
                nm_situacao: null,
                ds_autorizacao_observacao: null,
            },
            itemsSituacao: [],
        }
    },
    watch: {
        'form.id_situacao'(value) {
            if (value) {
                this.form.nm_situacao = this.itemsSituacao.find((situacao) => situacao.id_situacao === value).nm_situacao;
            }
        }
    },
    async created() {
        if (this.verifyHistoricosTipoMovimentacao) {
            await getSituacoes().then(response => {
                this.itemsSituacao = response.data.data.filter((situacao) => {
                    return situacao.id_tipo_historico === 3 &&
                        this.cargos.every((id_cargo) => situacao.cargos.some((cargoSituacao) => cargoSituacao.id_cargo === id_cargo));
                });
            });
        }
    },
    computed: {
        disabledSalvar() {
            return !this.form.id_orgao &&
                !this.form.id_secretaria_departamento_exercicio &&
                !this.form.nm_unidade_exercicio &&
                !this.form.id_situacao
        },
        verifyHistoricosTipoMovimentacao() {
            return this.tiposHistoricos.every((tipoHistorico) => tipoHistorico === 3);
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                updateHistoricosAtuais(this.historicos, this.form).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$emit('salvar', this.form);
                });
            }
        },
    }
}
</script>
