import api from './index'

const getPosicoesLideranca = () => api.get('posicao-lideranca');
const createPosicaoLideranca = (dados) => api.post('posicao-lideranca', dados);
const updatePosicaoLideranca = (posicoes_lideranca_id, dados) => api.put(`posicao-lideranca/${posicoes_lideranca_id}`, dados);
const deletePosicaoLideranca = (posicoes_lideranca_id) => api.delete(`posicao-lideranca/${posicoes_lideranca_id}`);

export {
    getPosicoesLideranca,
    createPosicaoLideranca,
    updatePosicaoLideranca,
    deletePosicaoLideranca,
}
