import {getTipoAnexos} from "../api/tipo-anexo";
import {dataTableMixin} from "./dataTableMixin";
import {dateMixin} from "./dateMixin";
import {fileMixin} from "./fileMixin";

export const anexoMixin = {
    mixins: [dataTableMixin, dateMixin, fileMixin],
    data() {
        return {
            headers: [
                {text: 'Tipo', value: 'tipo_anexo.nm_anexo', sortable: false},
                {text: 'Número', value: 'nr_anexo', sortable: false},
                {text: 'Descrição', value: 'ds_anexo', sortable: false},
                {text: 'Data publicação', value: 'dt_anexo', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            form: {...this.fields},
            error: {},
            itemsTipoAnexo: [],
        }
    },
    created() {
        getTipoAnexos().then(response => this.itemsTipoAnexo = response.data.data);
    },
    methods: {
        limpar() {
            this.form = {...this.fields};
            this.$refs.form.resetValidation();
        }
    },
}
