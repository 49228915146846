import AuthService from '../../services/auth.service';

const user = AuthService.getLocalUser();
const servidor = AuthService.getLocalServidor();
const servidorCargo = AuthService.getLocalServidorCargo();
const roles = AuthService.getLocalRoles();
const token = AuthService.getLocalToken();
const dataUltimoAcesso = AuthService.getLocalDataUltimoAcesso();

export const auth = {
    namespaced: true,
    state: {
        usuarioAutenticado: user,
        usuarioAutenticadoServidor: servidor,
        usuarioAutenticadoServidorCargo: servidorCargo,
        usuarioAutenticadoRoles: roles,
        usuarioAutenticadoToken: token,
        usuarioAutenticadoDataUltimoAcesso: dataUltimoAcesso,
        isLoggedIn: !!user,
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        usuarioAutenticado(state) {
            return state.usuarioAutenticado;
        },
        usuarioAutenticadoServidor(state) {
            return state.usuarioAutenticadoServidor;
        },
        usuarioAutenticadoServidorCargo(state) {
            return state.usuarioAutenticadoServidorCargo;
        },
        usuarioAutenticadoRoles(state) {
            return state.usuarioAutenticadoRoles;
        },
        usuarioAutenticadoToken(state) {
            return state.usuarioAutenticadoToken;
        },
        usuarioAutenticadoDataUltimoAcesso(state) {
            return state.usuarioAutenticadoDataUltimoAcesso;
        },
        usuarioPermissao: (state) => (roles = []) => {
            return !!roles.filter(role => state.usuarioAutenticadoRoles.includes(role)).length
        }
    },
    actions: {
        login({ commit }, payload) {
            return AuthService.login(payload.code, payload.state).then(
                () => {
                    commit('loginSuccess');
                    return Promise.resolve();
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('menu/closeSideBar', null, { root: true });
            commit('logout');
        }
    },
    mutations: {
        loginSuccess(state) {
            state.isLoggedIn = true;
            state.usuarioAutenticado = AuthService.getLocalUser();
            state.usuarioAutenticadoServidor = AuthService.getLocalServidor();
            state.usuarioAutenticadoServidorCargo = AuthService.getLocalServidorCargo();
            state.usuarioAutenticadoRoles = AuthService.getLocalRoles();
            state.usuarioAutenticadoToken = AuthService.getLocalToken();
            state.usuarioAutenticadoDataUltimoAcesso = AuthService.getLocalDataUltimoAcesso();
        },
        loginFailure(state) {
            state.isLoggedIn = false;
            state.usuarioAutenticado = null;
            state.usuarioAutenticadoServidor = null;
            state.usuarioAutenticadoServidorCargo = null;
            state.usuarioAutenticadoRoles = null;
            state.usuarioAutenticadoDataUltimoAcesso = null;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.usuarioAutenticado = null;
            state.usuarioAutenticadoServidor = null;
            state.usuarioAutenticadoServidorCargo = null;
            state.usuarioAutenticadoRoles = null;
            state.usuarioAutenticadoDataUltimoAcesso = null;
        }
    }
};
