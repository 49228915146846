import api from './index';

const getHistoricosAtuais = (filtro = {}) => api.get('historicos-atuais', {
    params: {
        ...filtro,
    }
});

const updateHistoricosAtuais = (ids_historicos, dados) => api.put('historicos-atuais', {
    ids_historicos,
    ...dados,
});

export {
    getHistoricosAtuais,
    updateHistoricosAtuais,
}
