import api from './index'

const getTurmas = (id_cargo) => api.get('turma', {
    params: {
        id_cargo: id_cargo,
    },
    showLoader: false
})

const createTurma = (dados) => api.post('turma', dados)
const updateTurma = (id, dados) => api.put(`turma/${id}`, dados)
const deleteTurma = (id) => api.delete(`turma/${id}`)

export {
    getTurmas,
    createTurma,
    updateTurma,
    deleteTurma,
}
