import {
    publicarDivulgacaoProcessoSeletivo,
    upsertDivulgacaoProcessoSeletivo
} from "../api/divulgacao-processo-seletivo";
import { divulgarOportunidadeMovimentacao } from "../api/oportunidade-movimentacao";
import moment from 'moment';

moment.locale('pt-br');

export const reabrirInscricoesMixin = {
    data() {
        return {
            dialogReabrirInscricoes: false,
        }
    },
    methods: {
        reabrirInscricoes() {
            upsertDivulgacaoProcessoSeletivo(
                this.processoSeletivo.id_processo_seletivo,
                {
                    nm_perfil_vaga: this.processoSeletivo.divulgacao.nm_perfil_vaga,
                    dt_prazo_inscricao: moment().add(15, 'days').format('YYYY-MM-DD'), // add 15 days use moment.js,
                    nr_vagas: this.processoSeletivo.divulgacao.nr_vagas,
                    ds_divulgacao_processo_seletivo: this.processoSeletivo.divulgacao.ds_divulgacao_processo_seletivo,
                }
            ).then(response => {
                publicarDivulgacaoProcessoSeletivo(this.processoSeletivo.id_processo_seletivo).then(reponse => {
                    divulgarOportunidadeMovimentacao(this.processoSeletivo.id_processo_seletivo).then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Incrições reabertas com sucesso!',
                        })
                        this.dialogReabrirInscricoes = false;
                        this.$vuetify.goTo(0);
                        this.$root.$emit('update-status-processo-seletivo', 4)
                    })
                })
            })
        },
    },
}
