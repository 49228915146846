import moment from 'moment';
moment.locale('pt-br');

export const dateMixin = {
    methods: {
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY")
            }
            return null;
        },
    }
}
