<template>
    <v-menu :close-on-content-click="true">
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip}">
                    <v-btn
                        fab
                        color="error"
                        elevation="0"
                        x-small
                        :disabled="disabled"
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...dialog }"
                    >
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </template>
                <span>Excluir</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-subtitle class="text-center font-weight-medium">
                Excluir
            </v-card-subtitle>
            <v-card-text>
                Tem certeza que deseja excluir este registro?
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="default">
                    Cancelar
                </v-btn>
                <v-btn
                    color="error"
                    @click="$emit('delete')"
                >
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "DeleteAction",
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
