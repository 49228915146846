import {filterNormalizeMixin} from "./filterNormalizeMixin";

export const orgaoSecretariaUnidadeMixin = {
    mixins: [filterNormalizeMixin],
    data() {
        return {
            dataLoaded: false,
            loading: false,
            search: null,
            form: {
                id_orgao: null,
                nm_orgao: null,
                id_secretaria_departamento_exercicio: null,
                nm_secretaria_departamento_exercicio: null,
                nm_unidade_exercicio: null,
            }
        }
    },
    computed: {
        formOrgaoSecretariaUnidade() {
            return {
                id_orgao: this.form.id_orgao,
                nm_orgao: this.form.nm_orgao,
                nm_secretaria_departamento_exercicio: this.form.nm_secretaria_departamento_exercicio,
                id_secretaria_departamento_exercicio: this.form.id_secretaria_departamento_exercicio,
                nm_unidade_exercicio: this.form.nm_unidade_exercicio,
            }
        }
    },
    methods: {
        updateForm(index, value) {
            this.form[index] = value;
        }
    },
}
