<template>
    <v-main>
        <v-sheet class="d-flex justify-center align-center" color="grey lighten-3" height="100vh" width="100vw">
            <v-sheet class="col-lg-8 col-xl-7">
                <v-row>
                    <v-col style="background-color: #002051" cols="0" md="6" lg="7" xl="8" class="d-none d-md-block px-0 py-4">
                        <v-img src="images/imagem_gestao_carreiras.jpg" />
                        <div class="px-5 white--text text-body-2">
                            <p class="font-weight-bold">Bem vindo(a),</p>

                            <p class="text-justify">
                                O <strong>Sistema de Gestão de Carreiras – SGC</strong> é a plataforma disponibilizada pela
                                Secretaria de Gestão e Inovação do Ministério da Gestão e da Inovação em Serviços Públicos para apoiar os órgãos e as entidades
                                da Administração Pública Federal a encontrarem os servidores das carreiras de <strong>Analista
                                de Comércio Exterior – ACE , Especialista em Políticas Públicas e Gestão Governamental –
                                EPPGG, Analista de Infraestrutura – AIE e do cargo isolado de Especialista em
                                Infraestrutura Sênior - EIS</strong>  que mais se adequam às suas políticas e projetos estratégicos.
                            </p>

                            <p class="text-end">
                                <a class="font-weight-bold white--text" href="https://www.gov.br/economia/pt-br/assuntos/gestao/outros/gestao-publica/gestao-de-carreiras/gestao-das-carreiras-transversais" target="_blank">Saiba mais</a>
                            </p>
                        </div>

                    </v-col>
                    <v-col :style="$vuetify.breakpoint.smAndDown ? 'height: 100vh' : ''" cols="12" md="6" lg="5" xl="4" class="d-flex flex-column  pa-5 white">
                        <v-spacer></v-spacer>
                        <div class="text-center mt-n16">
                            <img src="images/Logo_SGC.png" alt="Logo SGC" width="310">
                            <Alerta class="mt-md-5"/>
                            <p class="font-weight-bold text-h6 mt-8">Acesso</p>
                            <v-btn
                                rounded
                                color="primary"
                                x-large
                                href="/auth/redirect"
                                class="font-weight-light"
                            >
                                <v-icon large left>mdi-account</v-icon>
                                Entrar com <span class="ml-1 font-weight-bold">gov.br</span>
                            </v-btn>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="d-flex justify-space-between align-center">
                            <span class="grey--text text--lighten-1">v{{ ENV_MIX_GIT_VERSION }}</span>
                            <img src="images/govbr-colorido.png" alt="Logo gov.br" width="75">
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-sheet>
    </v-main>
</template>

<script>
import Alerta from "./Alerta";
export default {
    name: "Login",
    components: {Alerta},
    data() {
        return {
            ENV_MIX_GIT_VERSION: process.env.MIX_GIT_VERSION
        }
    },
}
</script>

<style scoped>

</style>
