<template>
    <v-row class="text-caption align-center">
        <v-col cols="6" md="2">
            Fonte: {{ fonte }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" md="4" class="text-right">
            <p class="mb-0">Data de atualização: {{ !!data ? data : '-' }}</p>
            <p v-if="fonte == 'CGCAT'">Reponsável: {{ !!usuario ? usuario : '-' }}</p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "RowDataUsuarioAlteracao",
    props: {
        fonte: {
            type: String,
            default: 'CGCAT'
        },
        data: {
            type: String
        },
        usuario: {
            type: String
        },
    },
}
</script>
