import api from './index';

const getFuncoes = () => api.get('funcao');
const createFuncao = (dados) => api.post('funcao', dados);
const updateFuncao = (id, dados) => api.put(`funcao/${id}`, dados);
const deleteFuncao = (id) => api.delete(`funcao/${id}`);

export {
    getFuncoes,
    createFuncao,
    updateFuncao,
    deleteFuncao,
}
