<template>
    <div>
        <v-sheet class="pa-4 mb-5" rounded outlined>
            <slot name="instrucao">
                <h2 class="subtitle-1 mb-3 font-weight-bold">Resultados estratégicos da unidade</h2>
                <p><strong>ATENÇÃO!</strong></p>
                <ul>
                    <li><strong>Inclua no mínimo três e no máximo cinco Entregas ou Resultados</strong> mais
                        relevantes da unidade <strong>nos próximos 2 anos</strong> em que haverá contribuição da
                        servidora ou do servidor.
                    </li>
                    <li>Descreva apenas <strong>Entregas ou Resultados</strong>.</li>
                    <li><strong>Não</strong> inclua atribuições ou atividades de rotina.</li>
                    <li><strong>Não</strong> utilize termos como participação em reuniões, elaboração de relatórios,
                        notas técnicas ou pareceres.
                    </li>
                    <li>A Entrega ou o Resultado deve ser registrado <strong>uma única vez</strong>, informando a
                        data prevista para a sua <strong>conclusão completa</strong>.
                    </li>
                    <li>Confira <a href="https://www.canva.com/design/DAEhw0rGYWc/5BsOpi4sxxvZwseTqROgHA/view"
                                   class="font-weight-bold" target="_blank">aqui</a> o vídeo com dicas para
                        elaborar Entregas e Resultados consistentes.
                    </li>
                </ul>
            </slot>
            <v-form ref="form" :disabled="disabled">
                <v-row class="mt-5">
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Descrição do resultado *"
                            outlined
                            dense
                            @keyup.enter="salvar()"
                            v-model="form.ds_resultado"
                            :rules="validate('required', 'descrição do resultado')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" :md="typeHistorico ? '2' : '3'">
                        <v-text-field
                            label="Prazo *"
                            outlined
                            dense
                            type="date"
                            v-model="form.dt_prazo"
                            :rules="validate('required', 'prazo')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" :md="typeHistorico ? '2' : '3'">
                        <v-select
                            label="Papel do servidor *"
                            outlined
                            dense
                            @keyup.enter="salvar()"
                            v-model="form.id_papel_pactuacao"
                            :items="itemsPapelPactuacao"
                            item-value="id_papel_pactuacao"
                            item-text="nm_papel_pactuacao"
                            :rules="validate('required', 'papel do servidor')"
                        ></v-select>
                    </v-col>
                    <v-col v-if="typeHistorico" cols="6" md="2">
                        <v-select
                            label="Status"
                            outlined
                            dense
                            v-model="form.id_status_pactuacao"
                            :items="itemsStatusPactuacao"
                            item-value="id_status_pactuacao"
                            item-text="nm_status_pactuacao"
                        ></v-select>
                    </v-col>
                    <v-col v-if="typeHistorico" cols="12">
                        <v-textarea
                            label="Comentários"
                            outlined
                            dense
                            v-model="form.ds_comentario_resultado"
                            :rules="validate('max:5000', 'comentários')"
                            counter
                            maxlength="5000"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <row-data-usuario-alteracao v-if="typeHistorico" :data="dataAlteracao" :usuario="usuarioAlteracao"/>
            </v-form>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="disabled"
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    :disabled="disabled"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-sheet>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1 mb-7"
        >
            <template v-slot:item.dt_prazo="{ item }">
                <span>{{ dateFormat(item.dt_prazo) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction :disabled="disabled" @click="form = {...item}"/>
                <DeleteAction
                    v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']) ||
                        !typeHistorico"
                    :disabled="disabled"
                    @delete="deletar(item.id_pactuacao_resultado)"
                />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {createPactuacao, deletePactuacao, getPactuacoes, updatePactuacao} from "../../api/pactuacao_resultado";
import EditAction from "../Table/EditAction.vue";
import DeleteAction from "../Table/DeleteAction.vue";
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {dateMixin} from "../../mixins/dateMixin";
import {validationMixin} from "../../mixins/validationMixin";
import {getPapelPactuacoes} from "../../api/papel-pactuacao";
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao.vue";
import {getStatusPactuacao} from "../../api/status-pactuacao";
import moment from 'moment';

moment.locale('pt-br');

export default {
    name: "PactuacaoResultadoFields",
    components: {RowDataUsuarioAlteracao, EditAction, DeleteAction},
    mixins: [dataTableMixin, dateMixin, validationMixin],
    props: {
        model: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        fields: {
            type: Object,
            default: () => ({
                ds_resultado: null,
                dt_prazo: null,
                id_papel_pactuacao: null,
                id_status_pactuacao: null,
                ds_comentario_resultado: null
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {...this.fields},
            itemsPapelPactuacao: [],
            itemsStatusPactuacao: [],
        }
    },
    created() {
        getPapelPactuacoes().then(response => this.itemsPapelPactuacao = response.data.data);
        if (this.typeHistorico) {
            getStatusPactuacao().then(response => this.itemsStatusPactuacao = response.data.data);
        }
    },
    computed: {
        idColumnName() {
            return 'id_' + this.type;
        },
        typeHistorico() {
            return this.type === 'historico'
        },
        headers() {
            let result = [
                {text: 'Descrição', value: 'ds_resultado', sortable: false},
                {text: 'Prazo', value: 'dt_prazo', sortable: false},
                {text: 'Papel do Servidor', value: 'papel.nm_papel_pactuacao', sortable: false},
                {text: 'Status', value: 'status.nm_status_pactuacao', sortable: false},
                {text: 'Comentário', value: 'ds_comentario_resultado', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ];
            if (!this.typeHistorico) {
                result.splice(3, 2);
            }

            return result;
        },
        dataAlteracao() {
            return !!this.form.updated_at ? moment(this.form.updated_at).format("DD/MM/YYYY LT") : null
        },
        usuarioAlteracao() {
            return !!this.form.usuario_alteracao ? this.form.usuario_alteracao.nm_usuario : ''
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                let callback;
                this.form.id_servidor = this.model.id_servidor;
                this.form[this.idColumnName] = this.model[this.idColumnName];
                if (!!this.form.id_pactuacao_resultado) {
                    callback = updatePactuacao(this.form.id_pactuacao_resultado, this.form);
                } else {
                    callback = createPactuacao(this.form);
                }

                callback.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.limpar()
                    this.filtrar()
                });
            }
        },
        limpar() {
            this.form = {...this.fields}
            this.$refs.form.resetValidation()
        },
        filtrar() {
            getPactuacoes(this.model[this.idColumnName], this.type, this.pagination.page, this.pagination.itemsPerPage)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    this.$emit('update:items', this.items);
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        deletar(id_pactuacao) {
            deletePactuacao(id_pactuacao)
                .then(response => {
                    this.items = this.items.filter(item => item.id_pactuacao_resultado !== id_pactuacao);
                    this.$emit('update:items', this.items);
                    this.pagination.total--;
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        }
    },
}
</script>
