<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Nome do perfil da vaga que será divulgado *"
                    outlined
                    dense
                    :disabled="disabled"
                    v-model="form.nm_perfil_vaga"
                    :rules="validate('required', 'nome do perfil da vaga')"
                ></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-text-field
                    label="Prazo de inscrição *"
                    outlined
                    dense
                    type="date"
                    :disabled="disabled"
                    v-model="form.dt_prazo_inscricao"
                    :rules="validate('required', 'prazo de inscrição')"
                ></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-text-field
                    label="Quantidade de vagas *"
                    outlined
                    dense
                    type="number"
                    :disabled="disabled"
                    v-model="form.nr_vagas"
                    :rules="validate('required|gte:1', 'quantidade de vagas')"
                ></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-input
                    :value="form.ds_divulgacao_processo_seletivo"
                    :rules="validate('required', 'texto de divulgação')"
                    :disabled="disabledTextoDivulgacao"
                >
                    <VueEditor
                        class="col-12 pa-0"
                        placeholder="Texto de divulgação"
                        :disabled="disabledTextoDivulgacao"
                        v-model="form.ds_divulgacao_processo_seletivo"
                    />
                </v-input>
            </v-col>
        </v-row>
        <div class="d-flex flex-wrap justify-end mb-5">
            <v-spacer></v-spacer>
            <v-btn
                class="ml-2 mb-2"
                color="primary"
                outlined
                :disabled="disabled || !id_divulgacao_processo_seletivo"
                @click="setAcaoDialog('publicar')"
            >
                Publicar
            </v-btn>
            <v-btn
                class="ml-2 mb-2"
                color="primary"
                outlined
                :disabled="disabledDivulgar || !id_divulgacao_processo_seletivo"
                @click="setAcaoDialog('divulgar')"
            >
                Divulgar
            </v-btn>
            <v-btn
                class="ml-2 mb-2"
                color="primary"
                :disabled="disabledTextoDivulgacao"
                @click="salvar"
            >
                Salvar
            </v-btn>
        </div>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>{{ acaoDialog.charAt(0).toUpperCase() + acaoDialog.slice(1) }} Processo Seletivo</v-card-title>
                <v-card-text>
                    Tem certeza que deseja {{ acaoDialog }} o processo seletivo?
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="enviar()"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
    </v-form>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao.vue";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import { VueEditor } from "vue2-editor";
import moment from 'moment';
import {
    getDivulgacaoProcessoSeletivo,
    publicarDivulgacaoProcessoSeletivo,
    upsertDivulgacaoProcessoSeletivo
} from "../../api/divulgacao-processo-seletivo";
import {getPactuacoes} from "../../api/pactuacao_resultado";
import {divulgarOportunidadeMovimentacao} from "../../api/oportunidade-movimentacao";

moment.locale('pt-br');

export default {
    name: "DivulgacaoProcessoSeletivo",
    props: ['processoSeletivo'],
    components: {RowDataUsuarioAlteracao, VueEditor},
    mixins: [validationMixin, dataUsuarioAlteracaoMixin],
    data() {
        return {
            dialog: false,
            acaoDialog: '',
            id_divulgacao_processo_seletivo: null,
            pactuacoes: [],
            form: {
                nm_perfil_vaga: null,
                dt_prazo_inscricao: null,
                nr_vagas: null,
                ds_divulgacao_processo_seletivo: null,
            },
        }
    },
    computed: {
        disabled() {
            return this.processoSeletivo.id_status_processo_seletivo > 3;
        },
        disabledDivulgar() {
            return this.processoSeletivo.id_status_processo_seletivo !== 4;
        },
        disabledTextoDivulgacao() {
            return this.processoSeletivo.id_status_processo_seletivo > 4;
        },
        textoPactuacao() {
            return this.pactuacoes.map(obj => `<p>✅ Prepare ou Desenhe ou Crie ou Elabore ou Produza ou Coordene ou Conduza ou Negocie ou Realize ou Avalie ou Implemente ou Apoie ${obj.ds_resultado}.</p>`).join('<br>');
        },
    },
    async created() {
        await getPactuacoes(this.processoSeletivo.id_processo_seletivo, 'processo_seletivo', 1, 50)
            .then((response) => {
                this.pactuacoes = response.data.data.data;
            });
        getDivulgacaoProcessoSeletivo(this.processoSeletivo.id_processo_seletivo).then((response) => {
            if (response.data.data) {
                this.id_divulgacao_processo_seletivo = response.data.data.id_divulgacao_processo_seletivo;
                this.form.nm_perfil_vaga = response.data.data.nm_perfil_vaga;
                this.form.dt_prazo_inscricao = response.data.data.dt_prazo_inscricao;
                this.form.nr_vagas = response.data.data.nr_vagas;
                this.form.ds_divulgacao_processo_seletivo = response.data.data.ds_divulgacao_processo_seletivo;
                this.setDataUsuarioAlteracaoByResponse(response);
            } else {
                this.form.dt_prazo_inscricao = moment().add(15, 'days').format('YYYY-MM-DD'); // add 15 days use moment.js
                this.form.ds_divulgacao_processo_seletivo = `<p>🗣 Que tal contribuir com <em>xxxxx</em>?</p><!--
                    --><p>‼️ Faça parte do/a <em>${this.processoSeletivo.nm_secretaria_departamento_exercicio}</em> do/a <em>${this.processoSeletivo.nm_orgao}</em>.</p><!--
                    --><p>🖋️ Cargo comissionado: <em>${this.textoNivel()}</em>.</p><!--
                    --><p>💜 No processo seletivo, serão priorizadas <em>${this.textoDiversidade()}</em>.</p><!--
                    -->${this.textoPactuacao}<!--
                    --><p>📆 Inscrições até <em>${moment(this.form.dt_prazo_inscricao).format('DD/MM/YYYY')}</em></p><!--
                    --><p>🌐 Acesse a página da oportunidade, clicando <a href="${window.location.href}" target="_blank">aqui</a>.</p><!--
                    --><p>👩🏽‍💼👨🏻‍💼 Modalidade de trabalho: <em>${this.processoSeletivo.modalidade_trabalho.nm_modalidade_trabalho}</em>.</p><!--
                    --><p>🔍 Conheça as <em>oportunidades abertas</em>, clicando <a href="${this.linkPaginaOportunidades()}" target="_blank">aqui</a></p>`
            }
        });
    },
    methods: {
        linkPaginaOportunidades() {
            return window.location.origin + '/oportunidades-movimentacao'
        },
        textoNivel() {
            return this.processoSeletivo.nivel ? this.processoSeletivo.nivel.nm_nivel : '';
        },
        textoDiversidade() {
            const nmCriterioDiversidade = this.processoSeletivo.criterios_diversidade.map(obj => obj.nm_criterio_diversidade);
            if (nmCriterioDiversidade.length === 1) {
                return nmCriterioDiversidade[0];
            }
            const lastItem = nmCriterioDiversidade.pop();
            return nmCriterioDiversidade.join(", ") + " e " + lastItem + ".";
        },
        setAcaoDialog(acao) {
            this.acaoDialog = acao;
            this.dialog = true;
        },
        salvar() {
            if (this.$refs.form.validate()) {
                upsertDivulgacaoProcessoSeletivo(this.processoSeletivo.id_processo_seletivo, this.form).then((response) => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.id_divulgacao_processo_seletivo = response.data.data.id_divulgacao_processo_seletivo;
                    this.setDataUsuarioAlteracaoByResponse(response);
                    this.$vuetify.goTo(0);
                })
            }
        },
        enviar() {
            let promise;
            if (this.acaoDialog === 'publicar') {
                promise = publicarDivulgacaoProcessoSeletivo(this.processoSeletivo.id_processo_seletivo).then((response) => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$emit('setIdStatusProcessoSeletivo', response.data.data.id_status_processo_seletivo);
                });
            } else if (this.acaoDialog === 'divulgar') {
                promise = divulgarOportunidadeMovimentacao(this.processoSeletivo.id_processo_seletivo).then((response) => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });

                });
            }

            promise.finally(() => {
                this.dialog = false;
                this.$vuetify.goTo(0);
            })
        },
    },
}
</script>
