<template>
    <v-stepper v-model="step" @change="addStepVisited" elevation="0">
        <h1 class="text-h5 my-4">Solicitação de Movimentação <span v-if="solicitacao.id_servidor">| {{
                solicitacao.servidor?.cargo.sg_cargo
            }} | {{ solicitacao.servidor.nm_servidor }}</span></h1>
        <v-stepper-header style="height: auto">
            <v-stepper-step
                v-for="item in steppers"
                :key="item.step"
                :editable="editableStep(item.step)"
                :complete="completeStep(item.step)"
                :step="item.step"
            >
                {{ item.title }}
            </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content v-for="item in steppers" :key="item.step" :step="item.step" class="pa-0">
                <component
                    v-if="item.component && stepVisited.includes(item.step)"
                    :is="item.component"
                    :solicitacao="solicitacao"
                    :disabled="item.disabled"
                    @nextStep="nextStep"
                    @previousStep="addStepVisited(--step)"
                />
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import SolicitacaoIdentificacao from "./SolicitacaoIdentificacao.vue";
import SolicitacaoOrgaoSolicitante from "./SolicitacaoOrgaoSolicitante.vue";
import SolicitacaoInformacoesQualitativas from "./SolicitacaoInformacoesQualitativas.vue";
import SolicitacaoPactuacao from "./SolicitacaoPactuacao.vue";
import SolicitacaoAnexo from "./SolicitacaoAnexo.vue";
import {stepperMixin} from "../../mixins/stepperMixin";

export default {
    name: "SolicitacaoMovimentacaoStepper",
    props: ['solicitacao'],
    mixins: [stepperMixin],
    watch: {
        solicitacao: function (val) {
            if (this.$route.params.step) {
                this.step = this.$route.params.step
            } else if (val.id_solicitacao_movimentacao) {
                if (this.solicitacao?.pactuacoes.length >= 3 && this.validaPrazoSegundoAnoSubsequente(this.solicitacao.pactuacoes)) {
                    this.stepEditable = 5
                } else if (!!this.solicitacao.id_modalidade_trabalho) {
                    this.stepEditable = 4;
                } else if (!!this.solicitacao.id_orgao) {
                    this.stepEditable = 3;
                } else if (!!this.solicitacao.id_servidor) {
                    this.stepEditable = 2;
                }
                if (val.id_status_solicitacao === 1) {
                    this.step = this.stepEditable;
                }
            }
            this.addStepVisited(this.step);
        }
    },
    computed: {
        steppers() {
            return [
                {step: 1, title: 'Identificação', component: SolicitacaoIdentificacao, disabled: this.disabled()},
                {step: 2, title: 'Órgão Solicitante', component: SolicitacaoOrgaoSolicitante, disabled: this.disabled()},
                {step: 3, title: 'Informações Qualitativas', component: SolicitacaoInformacoesQualitativas, disabled: this.disabled()},
                {step: 4, title: 'Pactuação de Resultados', component: SolicitacaoPactuacao, disabled: this.disabled()},
                {step: 5, title: 'Anexos', component: SolicitacaoAnexo, disabled: this.disabled(true)},
            ]
        },
    },
    methods: {
        validaPrazoSegundoAnoSubsequente(items) {
            return items.some(item => {
                const ano = item.dt_prazo.split('-')[0];
                const mes = item.dt_prazo.split('-')[1];
                const dia = item.dt_prazo.split('-')[2];
                const date = new Date(ano, (mes - 1), dia);

                const createdDate = new Date(this.solicitacao.created_at);
                return date.getFullYear() >= (createdDate.getFullYear() + 2);
            });
        },
        disabled(stepAnexo = false) {
            if (!this.solicitacao.id_solicitacao_movimentacao) {
                return false;
            }

            if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
                return this.solicitacao.id_status_solicitacao > 3;
            }

            const statusEnable = [1, 3];
            if (statusEnable.includes(this.solicitacao.id_status_solicitacao)) {
                const verificaUsuarioSolicitanteEPerfilRH = (
                    this.solicitacao.id_usuario_solicitante !== this.$store.getters['auth/usuarioAutenticado'] &&
                    !this.solicitacao.solicitantes.find((solicitante) => solicitante.id_usuario === this.$store.getters['auth/usuarioAutenticado']) &&
                    !this.$store.getters['auth/usuarioPermissao'](['rh'])
                );
                if (stepAnexo) {
                    return verificaUsuarioSolicitanteEPerfilRH &&
                        this.solicitacao.id_servidor !== this.$store.getters['auth/usuarioAutenticadoServidor'];
                } else {
                    return verificaUsuarioSolicitanteEPerfilRH;
                }
            } else {
                return true;
            }
        }
    },
}
</script>

<style>
.theme--light.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error) .v-stepper__step__step {
    background: rgba(0,0,0,.54) !important;
}
.theme--light.v-stepper .v-stepper__step--inactive.v-stepper__step--editable:not(.v-stepper__step--error) .v-stepper__label{
    text-shadow: 0 0 0 #000 !important;
}
</style>
