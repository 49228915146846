import api from './index';

const getEstados = (sg_uf) => api.get('municipios', {
    params: {
        sg_uf: sg_uf
    }
});

export {
    getEstados
}
