import api from './index'

const getModalidadesTrabalho = () => api.get('modalidade-trabalho');
const createModalidadeTrabalho = (dados) => api.post('modalidade-trabalho', dados);
const updateModalidadeTrabalho = (id, dados) => api.put(`modalidade-trabalho/${id}`, dados);
const deleteModalidadeTrabalho = (id) => api.delete(`modalidade-trabalho/${id}`);

export {
    getModalidadesTrabalho,
    createModalidadeTrabalho,
    updateModalidadeTrabalho,
    deleteModalidadeTrabalho,
}
