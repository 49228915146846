<template>
    <v-autocomplete
        :label="label"
        outlined
        dense
        :value="value"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :rules="rules"
        :filter="filterNormalize"
        :items="unidades"
        item-value="id_unidade_siorg"
        item-text="nm_unidade"
    />
</template>

<script>
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import {getUnidades} from "../../api/unidade";

export default {
    name: "SecretariaDepartamentoSIORGAutocomplete",
    mixins: [filterNormalizeMixin],
    props: {
        label: {
            type: String,
            default: 'Secretaria ou departamento'
        },
        value: {
            type: Number,
        },
        rules: {
            type: Array,
            default: () => []
        },
        clearable: {
            type: Boolean,
            default: false
        },
        withTrashed: {
            type: Boolean,
            default: false
        },
        idOrgao: {
            type: Number,
            default: null
        },
        nmSecretaria: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            unidades: [],
            dataLoaded: false
        }
    },
    watch: {
        idOrgao(value) {
            if (this.dataLoaded) {
                this.$emit('input', null)
                this.$emit('update:nmSecretaria', null)
            }

            this.setUnidades(value)
        },
        value(value) {
            if (!this.nmSecretaria && !!value && this.unidades.length > 0) {
                let unidade = this.unidades.filter(unidade => unidade.id_unidade_siorg == value)
                this.$emit('update:nmSecretaria', unidade[0].nm_unidade)
            }
        }
    },
    created() {
        if (!!this.idOrgao) {
            this.setUnidades(this.idOrgao)
        }
    },
    methods: {
        setUnidades(idOrgao) {
            this.unidades = []
            if (!!idOrgao) {
                getUnidades(idOrgao, this.withTrashed).then(response => this.unidades = response.data.data)
            }
            this.dataLoaded = true
        }
    },
}
</script>
