<template>
    <v-form :disabled="disabled" ref="form">
        <v-card outlined>
            <v-card-text>
                <instrucoes-sheet option-title="Solicitação de Processo Seletivo" />

                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Detalhamento</h2>
                    <OrgaoSecretariaUnidadeFields
                        :form="formOrgaoSecretariaUnidade"
                        @update:form="updateForm"
                    />

                    <CidadeEstadoPaisFields
                        :form="{nm_cidade: form.nm_cidade, ds_estado: form.ds_estado, id_pais: form.id_pais}"
                        @update:form="(index, value) => this.form[index] = value"
                    />
                </v-sheet>

                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Contextualização</h2>
                    <p>Informe breve contexto sobre a unidade de exercício, principais desafios etc.</p>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                label="Contextualização *"
                                outlined
                                dense
                                v-model="form.ds_contextualizacao"
                                :rules="validate('required|max:5000', 'contextualização')"
                                counter
                                maxlength="5000"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-sheet>

                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Projeto</h2>
                    <p>Nome do principal projeto em que haverá contribuição do servidor</p>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Nome do projeto ou política pública *"
                                outlined
                                dense
                                v-model="form.nm_projeto_politica_publica"
                                :rules="validate('required', 'nome do projeto ou política pública')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-sheet>

                <CargoFuncaoFields :form="form" @update:form="form = $event">
                    <v-col cols="12">
                        <v-autocomplete
                            label="Critério de Diversidade *"
                            outlined
                            clearable
                            multiple
                            chips
                            v-model="form.lt_criterio_diversidade"
                            :rules="validate('required', 'critério de diversidade')"
                            :filter="filterNormalize"
                            :items="itemsCriterioDiversidade"
                            item-value="id_criterio_diversidade"
                            item-text="nm_criterio_diversidade"
                        >
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip
                                    :disabled="parent.disabled"
                                    v-bind="attrs"
                                    :input-value="selected"
                                >
                                    <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                    >
                                        $delete
                                    </v-icon>
                                    <span class="pl-2">{{ item.nm_criterio_diversidade }}</span>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </CargoFuncaoFields>

                <ChefiaImediataFields
                    :form="form"
                    @update:form="form = $event"

                    title="Responsável por receber as candidaturas"

                    field_name_label="Nome *"
                    :field_name_rules="validate('required', 'nome do responsável')"
                    field_name_value="nm_responsavel_candidatura"

                    field_cargo_label="Cargo *"
                    :field_cargo_rules="validate('required', 'cargo do responsável')"
                    field_cargo_value="nm_cargo_responsavel_candidatura"

                    field_email_label="Email *"
                    :field_email_rules="validate('required|email', 'email do responsável')"
                    field_email_value="ds_email_responsavel_candidatura"

                    field_telefone_label="Telefone *"
                    :field_telefone_rules="validate('required', 'telefone do responsável')"
                    field_telefone_value="ds_telefone_responsavel_candidatura"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="disabled" @click="salvar">Avançar</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import InstrucoesSheet from "../InstrucoesSheet.vue";
import {validationMixin} from "../../mixins/validationMixin";
import {orgaoSecretariaUnidadeMixin} from "../../mixins/orgaoSecretariaUnidadeMixin";
import {createProcessoSeletivo, updateProcessoSeletivoOrgaoSolitante} from "../../api/processo-seletivo";
import {getCriteriosDiversidades} from "../../api/criterio-diversidade";
import ChefiaImediataFields from "../FormInputs/ChefiaImediataFields.vue";
import CargoFuncaoFields from "../FormInputs/CargoFuncaoFields.vue";
import OrgaoEntidadeSIORGAutocomplete from "../FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
import SecretariaDepartamentoSIORGAutocomplete from "../FormInputs/SecretariaDepartamentoSIORGAutocomplete.vue";
import OrgaoSecretariaUnidadeFields from "../FormInputs/OrgaoSecretariaUnidadeFields.vue";
import CidadeEstadoPaisFields from "../FormInputs/CidadeEstadoPaisFields.vue";

export default {
    name: "ProcessoSeletivoOrgaoSolicitante",
    mixins: [validationMixin, orgaoSecretariaUnidadeMixin],
    components: {
        CidadeEstadoPaisFields,
        OrgaoSecretariaUnidadeFields,
        SecretariaDepartamentoSIORGAutocomplete,
        OrgaoEntidadeSIORGAutocomplete, CargoFuncaoFields, ChefiaImediataFields, InstrucoesSheet},
    props: ['disabled', 'processoSeletivo'],
    data() {
        return {
            itemsCriterioDiversidade: [],
            form: {
                id_pais: 21,
                ds_estado: null,
                nm_cidade: null,
                nm_responsavel_candidatura: '',
                nm_cargo_responsavel_candidatura: '',
                ds_email_responsavel_candidatura: '',
                ds_telefone_responsavel_candidatura: '',
            },
        }
    },
    async created() {
        await getCriteriosDiversidades().then(response => this.itemsCriterioDiversidade = response.data.data);
    },
    watch: {
        processoSeletivo: {
            deep: true,
            immediate: true,
            handler(object) {
                if (object.id_processo_seletivo) {
                    this.form = {
                        id_orgao: object.id_orgao,
                        nm_orgao: object.nm_orgao,
                        id_secretaria_departamento_exercicio: object.id_secretaria_departamento_exercicio,
                        nm_secretaria_departamento_exercicio: object.nm_secretaria_departamento_exercicio,
                        nm_unidade_exercicio: object.nm_unidade_exercicio,
                        id_pais: object.id_pais ? object.id_pais : 21,
                        ds_estado: object.ds_estado,
                        nm_cidade: object.nm_cidade,
                        ds_contextualizacao: object.ds_contextualizacao,
                        nm_projeto_politica_publica: object.nm_projeto_politica_publica,
                        nm_cargo_funcao: object.nm_cargo_funcao,
                        id_funcao: object.id_funcao,
                        id_nivel: object.id_nivel,
                        nm_responsavel_candidatura: object.nm_responsavel_candidatura,
                        ds_email_responsavel_candidatura: object.ds_email_responsavel_candidatura,
                        nm_cargo_responsavel_candidatura: object.nm_cargo_responsavel_candidatura,
                        ds_telefone_responsavel_candidatura: object.ds_telefone_responsavel_candidatura,

                        lt_criterio_diversidade: object.criterios_diversidade.map(item => item.id_criterio_diversidade)
                    }
                }
            }
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                let promise
                if (this.processoSeletivo.id_processo_seletivo) {
                    promise = updateProcessoSeletivoOrgaoSolitante(this.processoSeletivo.id_processo_seletivo, this.form)
                } else {
                    promise = createProcessoSeletivo(this.form);
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    this.$emit('nextStep');
                    this.$router.push({
                        name: 'processo-seletivo-form',
                        params: {processo: response.data.data.id_processo_seletivo}
                    })
                }).catch(error => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Erro',
                        titulo: error.response.data.message,
                    })
                }).finally(() => this.$vuetify.goTo(0));
            }
        }
    },
}
</script>
