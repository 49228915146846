import api from './index';

const getNiveisByFuncao = (id_funcao) => api.get('nivel-funcao-hierarquia', {params: {id_funcao: id_funcao}});
const getHierarquiaByFuncaoAndNivel = (id_funcao, id_nivel) => api.get('nivel-funcao-hierarquia', {
    params: {
        id_funcao: id_funcao,
        id_nivel: id_nivel,
    }
});
const getNiveisFuncoesHierarquias = () => api.get('nivel-funcao-hierarquia');
const createNivelFuncaoHierarquia = (dados) => api.post('nivel-funcao-hierarquia', dados);
const updateNivelFuncaoHierarquia = (id, dados) => api.put('nivel-funcao-hierarquia/' + id, dados);
const deleteNivelFuncaoHierarquia = (id) => api.delete('nivel-funcao-hierarquia/' + id);

export {
    getNiveisByFuncao,
    getHierarquiaByFuncaoAndNivel,
    getNiveisFuncoesHierarquias,
    createNivelFuncaoHierarquia,
    updateNivelFuncaoHierarquia,
    deleteNivelFuncaoHierarquia
}
