<template>
    <v-form :disabled="disabled" ref="form">
        <h2 class="subtitle-1 mb-3 font-weight-bold">Solicitação de abertura de processo seletivo</h2>
        <h3 v-show="showInstrucoes" class="mb-3 subtitle-2">
            Verifique no check-list a seguir as pendências que precisam ser sanadas para que a solicitação de
            abertura de prcesso seletivo ao lado possa ter prosseguimento:
        </h3>
        <v-row>
            <v-col
                v-for="(item, key) in itemsCheckLists"
                :key="key"
                cols="12"
                class="d-flex flex-wrap align-center justify-space-between mt-n4"
            >
                <span class="col-12 col-lg-5 col-xl-6 pa-0">{{ item.title }}</span>
                <div class="d-flex align-center">
                    <v-radio-group
                        @change="salvar"
                        v-model="form[item.model]"
                        :rules="validate('required', '')"
                        row
                    >
                        <v-radio label="OK" :value="true"></v-radio>
                        <v-radio label="Não" :value="false"></v-radio>
                    </v-radio-group>
                    <v-btn @click="item.showObservacao = !item.showObservacao" icon>
                        <v-icon v-show="!item.showObservacao" :color="form[item.observacao] ? 'primary' : ''">
                            {{ form[item.observacao] ? 'mdi-comment-text' : 'mdi-comment-text-outline' }}
                        </v-icon>
                        <v-icon v-show="item.showObservacao">mdi-comment-off</v-icon>
                    </v-btn>
                </div>
                <v-expand-transition>
                    <div v-if="item.showObservacao" class="col-12 pa-0">
                        <v-textarea
                            label="Observação"
                            outlined
                            dense
                            clearable
                            v-model="form[item.observacao]"
                            :rules="validate('max:5000', 'observação')"
                            counter
                            maxlength="5000"
                        ></v-textarea>
                        <div class="d-flex justify-end">
                            <v-btn :disabled="disabled" @click="salvar" outlined color="primary">
                                Salvar
                            </v-btn>
                        </div>
                    </div>
                </v-expand-transition>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea
                    label="Observações Gerais"
                    outlined
                    dense
                    clearable
                    v-model="form.observacao_orgao_geral"
                    :rules="validate('max:5000', 'observações gerais')"
                    counter
                    maxlength="5000"
                ></v-textarea>
                <div class="d-flex justify-end">
                    <v-btn :disabled="disabled" @click="salvar" outlined color="primary">
                        Salvar
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-divider class="my-5" />
        <div class="d-flex mt-10">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :disabled="disableEnviar"
                @click="openDialog"
            >
                Enviar
            </v-btn>
        </div>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>Enviar Análise</v-card-title>
                <v-card-text>
                    {{
                        this.showInstrucoes ?
                        'Você sanou todas as pendências dessa solicitação de abertura de processo seletivo ?' :
                        'Deseja confirmar a mudança de status da solicitação e enviar notificação ao órgão solicitante ?'
                    }}
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn outlined @click="dialog=false">
                        Não
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="enviar"
                    >
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";
import {enviarAjustes, enviarAnaliseOrgao, updateAnaliseChecklistOrgao} from "../../api/analise-processo-seletivo";

export default {
    name: "AnaliseProcessoSeletivoOrgao",
    mixins: [validationMixin],
    props: ['analiseProcessoSeletivo', 'id_status_processo_seletivo', 'showInstrucoes', 'disableEnviar'],
    data() {
        return {
            dialog: false,
            itemsCheckLists: [
                {
                    title: 'Identificação do Órgão Solicitante*',
                    model: 'checklist_orgao_identificacao_orgao_solicitante',
                    observacao: 'observacao_orgao_identificacao_orgao_solicitante',
                    showObservacao: false,
                },
                {
                    title: 'Contextualização do Órgão*',
                    model: 'checklist_orgao_contextualizacao',
                    observacao: 'observacao_orgao_contextualizacao',
                    showObservacao: false,
                },
                {
                    title: 'Projeto*',
                    model: 'checklist_orgao_projeto',
                    observacao: 'observacao_orgao_projeto',
                    showObservacao: false,
                },
                {
                    title: 'Cargo ou Função*',
                    model: 'checklist_orgao_cargo_funcao',
                    observacao: 'observacao_orgao_cargo_funcao',
                    showObservacao: false,
                },
                {
                    title: 'Responsável pela Candidatura*',
                    model: 'checklist_orgao_responsavel_candidatura',
                    observacao: 'observacao_orgao_responsavel_candidatura',
                    showObservacao: false,
                },
                {
                    title: 'Informações Qualitativas*',
                    model: 'checklist_orgao_informacoes_qualitativas',
                    observacao: 'observacao_orgao_informacoes_qualitativas',
                    showObservacao: false,
                },
{
                    title: 'Pactuação de Resultados*',
                    model: 'checklist_orgao_pactuacao_resultados',
                    observacao: 'observacao_orgao_pactuacao_resultados',
                    showObservacao: false,
                },
            ],
            form: {
                checklist_orgao_identificacao_orgao_solicitante: null,
                observacao_orgao_identificacao_orgao_solicitante: null,
                checklist_orgao_contextualizacao: null,
                observacao_orgao_contextualizacao: null,
                checklist_orgao_projeto: null,
                observacao_orgao_projeto: null,
                checklist_orgao_cargo_funcao: null,
                observacao_orgao_cargo_funcao: null,
                checklist_orgao_responsavel_candidatura: null,
                observacao_orgao_responsavel_candidatura: null,
                checklist_orgao_informacoes_qualitativas: null,
                observacao_orgao_informacoes_qualitativas: null,
                checklist_orgao_pactuacao_resultados: null,
                observacao_orgao_pactuacao_resultados: null,
                observacao_orgao_geral: null,

                st_orgao_revisao_enviada: false,
            }
        }
    },
    computed: {
        disabled() {
            if (this.showInstrucoes) {
                return true;
            }

            return this.id_status_processo_seletivo > 3;
        }
    },
    created() {
        this.form.checklist_orgao_identificacao_orgao_solicitante = this.analiseProcessoSeletivo.checklist_orgao_identificacao_orgao_solicitante;
        this.form.observacao_orgao_identificacao_orgao_solicitante = this.analiseProcessoSeletivo.observacao_orgao_identificacao_orgao_solicitante;
        this.form.checklist_orgao_contextualizacao = this.analiseProcessoSeletivo.checklist_orgao_contextualizacao;
        this.form.observacao_orgao_contextualizacao = this.analiseProcessoSeletivo.observacao_orgao_contextualizacao;
        this.form.checklist_orgao_projeto = this.analiseProcessoSeletivo.checklist_orgao_projeto;
        this.form.observacao_orgao_projeto = this.analiseProcessoSeletivo.observacao_orgao_projeto;
        this.form.checklist_orgao_cargo_funcao = this.analiseProcessoSeletivo.checklist_orgao_cargo_funcao;
        this.form.observacao_orgao_cargo_funcao = this.analiseProcessoSeletivo.observacao_orgao_cargo_funcao;
        this.form.checklist_orgao_responsavel_candidatura = this.analiseProcessoSeletivo.checklist_orgao_responsavel_candidatura;
        this.form.observacao_orgao_responsavel_candidatura = this.analiseProcessoSeletivo.observacao_orgao_responsavel_candidatura;
        this.form.checklist_orgao_informacoes_qualitativas = this.analiseProcessoSeletivo.checklist_orgao_informacoes_qualitativas;
        this.form.observacao_orgao_informacoes_qualitativas = this.analiseProcessoSeletivo.observacao_orgao_informacoes_qualitativas;
        this.form.checklist_orgao_pactuacao_resultados = this.analiseProcessoSeletivo.checklist_orgao_pactuacao_resultados;
        this.form.observacao_orgao_pactuacao_resultados = this.analiseProcessoSeletivo.observacao_orgao_pactuacao_resultados;
        this.form.observacao_orgao_geral = this.analiseProcessoSeletivo.observacao_orgao_geral;

        if (this.showInstrucoes) {
            this.form.st_orgao_revisao_enviada = true;
        }

    },
    methods: {
        openDialog() {
            if (this.$refs.form.validate()) {
                this.dialog = true;
            }
        },
        salvar() {
            updateAnaliseChecklistOrgao(
                this.analiseProcessoSeletivo.id_processo_seletivo,
                this.analiseProcessoSeletivo.id_analise_processo_seletivo,
                this.form
            )
        },
        enviar() {
            if (this.$refs.form.validate()) {
                let response;
                if (this.showInstrucoes) {
                    response = enviarAjustes(this.analiseProcessoSeletivo.id_processo_seletivo, this.form)
                } else {
                    response = enviarAnaliseOrgao(this.analiseProcessoSeletivo.id_processo_seletivo, this.form)
                }

                response.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: response.data.success ? 'Sucesso': 'Warning',
                        titulo: response.data.message,
                    });
                    if (this.showInstrucoes) {
                        this.$router.push({name: 'processo-seletivo-listar'});
                    } else {
                        this.$emit('setIdStatusProcessoSeletivo', response.data.data.id_status_processo_seletivo)
                    }
                    this.$vuetify.goTo(0);
                });
                this.dialog = false;
            }
        }
    },
}
</script>
