<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="4">
                        <v-select
                            label="Status"
                            outlined
                            dense
                            clearable
                            v-model="filtro['tb_unidade_siorg.st_ativo']"
                            :items="[
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'},
                            ]"
                        ></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-select
                            label="Esfera"
                            outlined
                            dense
                            clearable
                            v-model="filtro['tb_unidade_siorg.id_esfera']"
                            :items="itemsEsfera"
                            item-value="id_esfera"
                            item-text="nm_esfera"
                        ></v-select>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-select
                            label="Poder"
                            outlined
                            dense
                            clearable
                            v-model="filtro['tb_unidade_siorg.id_poder']"
                            :items="itemsPoder"
                            item-value="id_poder"
                            item-text="nm_poder"
                        ></v-select>
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-text-field
                            label="Sigla"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.sg_unidade"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="10">
                        <v-autocomplete
                            label="Órgão ou Entidade"
                            outlined
                            dense
                            clearable
                            @keyup.enter="filtrar()"
                            :loading="loading"
                            v-model="filtro.nm_unidade"
                            :filter="filterNormalize"
                            :items="itemsOrgaos"
                            item-value="nm_unidade"
                            item-text="nm_unidade"
                            :return-object="false"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :to="{name: 'orgao-form'}"
            >
                Novo Registro
            </v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >
            <template v-slot:item.st_ativo="{ item }">
                <v-chip
                    v-if="item.st_ativo"
                    small
                    outlined
                    color="primary"
                >
                    Ativo
                </v-chip>
                <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                >
                    Inativo
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction :to="{name: 'orgao-form', params: {orgao: item.id_unidade_siorg}}" />
                <DeleteAction v-if="item.st_cadastrado_manual" @delete="deletar(item.id_unidade_siorg)" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {dataTableMixin} from "../mixins/dataTableMixin";
import {deleteOrgao, getOrgaosPaginate} from "../api/orgao";
import {getEsferas} from "../api/esfera";
import {getPoderes} from "../api/poder";
import {orgaoSecretariaUnidadeMixin} from "../mixins/orgaoSecretariaUnidadeMixin";
import {filterNormalizeMixin} from "../mixins/filterNormalizeMixin";
import DeleteAction from "../components/Table/DeleteAction.vue";
import EditAction from "../components/Table/EditAction.vue";

export default {
    name: "OrgaosListar",
    components: {EditAction, DeleteAction},
    mixins: [dataTableMixin, orgaoSecretariaUnidadeMixin],
    data() {
        return {
            loading: false,
            itemsOrgaos: [],
            filtro: {
                'tb_unidade_siorg.st_ativo': true,
                'tb_unidade_siorg.id_esfera': null,
                'tb_unidade_siorg.id_poder': null,
                nm_unidade: null,
                sg_unidade: null,
            },
            headers: [
                { text: 'Esfera', value: 'nm_esfera', sortable: false },
                { text: 'Poder', value: 'nm_poder', sortable: false },
                { text: 'Sigla', value: 'sg_unidade', sortable: false },
                { text: 'Orgão ou Entidade', value: 'nm_unidade', sortable: false },
                { text: 'Status', value: 'st_ativo', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            itemsEsfera: [],
            itemsPoder: [],
        }
    },
    created() {
        getEsferas().then(response => this.itemsEsfera = response.data.data);
        getPoderes().then(response => this.itemsPoder = response.data.data);
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                'tb_unidade_siorg.st_ativo': null,
                'tb_unidade_siorg.id_esfera': null,
                'tb_unidade_siorg.id_poder': null,
                nm_unidade: null,
                sg_unidade: null,
            }
            this.filtrar()
        },
        filtrar() {
            this.handlerPaginatePromise(getOrgaosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro))
        },
        deletar(id_unidade_siorg) {
            deleteOrgao(id_unidade_siorg)
                .then(response => {
                    this.items = this.items.filter(item => item.id_unidade_siorg !== id_unidade_siorg);
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        }
    }
}
</script>
