import api from './index';

const getNiveis = () => api.get('nivel');
const createNivel = (dados) => api.post('nivel', dados);
const updateNivel = (id, dados) => api.put('nivel/' + id, dados);
const deleteNivel = (id) => api.delete('nivel/' + id);

export {
    getNiveis,
    createNivel,
    updateNivel,
    deleteNivel
}
