<template>
    <v-row>
        <v-col class="d-flex col-12">
            <v-spacer />
            <v-btn
                v-for="view in views"
                v-show="show.value != view.value"
                :key="view.value"
                color="primary"
                class="mr-2"
                @click="show = view"
            >
                {{ view.title }}
            </v-btn>
            <v-btn
                v-if="hasPermissaoIncrever"
                color="primary"
                @click="showOportunidadeDialog = true"
            >Quero me inscrever!</v-btn>
        </v-col>
        <v-col
            v-if="showAnaliseProcessoSeletivo"
            :class="showAnaliseProcessoSeletivo ? 'col-12 col-md-6 col-lg-4' : 'col-12'"
        >
            <analise-processo-seletivo
                v-if="processoSeletivo.id_processo_seletivo"
                :processo-seletivo="processoSeletivo"
                @setIdStatusProcessoSeletivo="(status) => processoSeletivo.id_status_processo_seletivo = status"
            />
        </v-col>

        <v-col :class="showAnaliseProcessoSeletivo ? 'col-12 col-md-6 col-lg-8' : 'col-12'">
            <processo-seletivo-stepper
                v-show="show.value == 'solicitacao'"
                :processo-seletivo="processoSeletivo"
                :title="title"
            />

            <oportunidades-processos-tabs
                v-if="hasPermissaoListarOportunidadesMovimentacao && show.value == 'oportunidade'"
            />

            <ficha-funcional-tabs
                v-if="hasPermissaoIncrever"
                v-show="show.value == 'ficha-funcional'"
                :servidor="servidor"
            />
        </v-col>

        <OportunidadeMovimentacaoInscreverDialog
            v-if="hasPermissaoIncrever"
            v-model="showOportunidadeDialog"
            :oportunidade-movimetacao="processoSeletivo"
        />
    </v-row>
</template>

<script>
import ProcessoSeletivoStepper from "../components/ProcessoSeletivo/ProcessoSeletivoStepper.vue";
import {getProcessoSeletivo} from "../api/processo-seletivo";
import AnaliseProcessoSeletivo from "../components/AnaliseProcessoSeletivo/AnaliseProcessoSeletivo.vue";
import OportunidadesProcessosTabs from "../components/OportunidadeProcesso/OportunidadesProcessosTabs.vue";
import OportunidadeMovimentacaoInscreverDialog
    from "../components/OportunidadeProcesso/OportunidadeMovimentacaoInscreverDialog.vue";
import FichaFuncionalTabs from "../components/FichaFuncional/FichaFuncionalTabs.vue";
import {getServidor} from "../api/servidor";

export default {
    name: "ProcessoSeletivo",
    components: {
        FichaFuncionalTabs,
        OportunidadeMovimentacaoInscreverDialog,
        OportunidadesProcessosTabs, AnaliseProcessoSeletivo, ProcessoSeletivoStepper},

    data() {
        return {
            showOportunidadeDialog: false,
            show: {value: 'solicitacao', title: 'Solicitacao de Processo Seletivo'},
            processoSeletivo: {},
            servidor: {},
            title: 'Oportunidades de Movimentação',
        }
    },
    computed: {
        showAnaliseProcessoSeletivo() {
            let result = false;

            result = this.$store.getters['auth/usuarioPermissao'](['solicitante']) &&
                this.processoSeletivo.id_status_processo_seletivo === 3;

            if (!result) {
                result = this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']) &&
                    this.processoSeletivo.id_status_processo_seletivo > 1;
            }

            return result;
        },
        views() {
            let result = [{value: 'solicitacao', title: 'Oportunidades de Movimentação'}];
            if (this.hasPermissaoListarOportunidadesMovimentacao) {
                result = [{value: 'solicitacao', title: 'Solicitacao de Processo Seletivo'}];
                result.push({value: 'oportunidade', title: 'Oportunidades de Movimentação'})
            }
            if (this.hasPermissaoIncrever) {
                result.push({value: 'ficha-funcional', title: 'Ficha Funcional'})
            }
            return result
        },
        hasPermissaoListarOportunidadesMovimentacao() {
            return this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']);
        },
        hasPermissaoIncrever() {
            return this.$store.getters['auth/usuarioPermissao'](['carreira']) &&
                this.processoSeletivo.id_status_processo_seletivo === 4 &&
                this.processoSeletivo.cargos.some(cargo => cargo.id_cargo === this.$store.getters['auth/usuarioAutenticadoServidorCargo']);
        },
    },
    mounted() {
        this.$root.$on('update-status-processo-seletivo', (from) => this.processoSeletivo.id_status_processo_seletivo = from)
    },
    beforeDestroy() {
        this.$root.$off('update-status-processo-seletivo')
    },
    watch: {
        'processoSeletivo.id_status_processo_seletivo'() {
            this.showOportunidadesMovimentacao = false
        }
    },
    async created() {
        if (this.$route.name === 'processo-seletivo-form') {
            this.title = 'Solicitação de abertura de processo seletivo';
        }
        if (!!this.$route.params.processo) {
            await getProcessoSeletivo(this.$route.params.processo).then(response => {
                this.processoSeletivo = response.data.data
            });
            if (this.hasPermissaoIncrever) {
                await getServidor(this.$store.getters['auth/usuarioAutenticadoServidor']).then((response) => {
                    this.servidor = {...response.data.data}
                });
            }
        }
    },
}
</script>
