<template>
    <div>
        <v-card outlined>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    v-show="showFichaFuncional"
                    outlined
                    @click="showFichaFuncional = !showFichaFuncional"
                >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Voltar
                </v-btn>
                <v-btn
                    v-show="showFichaFuncional"
                    color="primary"
                    @click="gerarPDF"
                >
                    <v-icon left>mdi-file-pdf-box</v-icon>
                    Gerar PDF
                </v-btn>
                <v-btn
                    v-show="!showFichaFuncional"
                    :disabled="this.processoSeletivo.id_status_processo_seletivo !== 1 && this.processoSeletivo.id_status_processo_seletivo !== 5"
                    color="primary"
                    @click="dialogReabrirInscricoes = true"
                >
                    Reabrir Inscrições
                </v-btn>
                <v-btn
                    v-show="!showFichaFuncional"
                    :disabled="!processoAberto"
                    color="primary"
                    @click="dialogEncerrarSelecao = true"
                >
                    Encerrar Seleção
                </v-btn>
            </v-card-actions>
            <v-card-text v-show="!showFichaFuncional">
                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <h2 class="subtitle-1 mb-3 font-weight-bold">Resumo</h2>
                    <OportunidadeProcessoCard
                        class="mx-n4"
                        :oportunidade-movimetacao="processoSeletivo"
                    />
                </v-sheet>
                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <p>
                        Clique sobre o ícone
                        <v-icon color="primary">mdi-folder-account</v-icon>
                        para visualizar destalhes sobre o perfil profissional de cada candidata/o. Após
                        a realização das entrevistas, registre a ordem de classificação das/os candidatas/os. Clique
                        sobre o
                        ícone
                        <v-icon color="primary">mdi-arrow-decision</v-icon>
                        para solicitar movimentação da/o candidata/o aprovada/o. Após solicitar a movimentação das/os
                        aprovadas/os, clique em Encerrar Seleção. Caso deseje receber mais candidaturas, clique em
                        Reabrir
                        inscrições.
                    </p>

                    <v-data-table
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :server-items-length="pagination.total"
                        @update:options="filtrar()"
                        class="elevation-1"
                    >
                        <template v-slot:item.created_at="{ item }">
                            {{ dateFormat(item.created_at) }}
                        </template>
                        <template v-slot:item.pivot.nr_classificacao="{ item }">
                            <v-select
                                :disabled="!processoAberto"
                                placeholder="Não classificada/o."
                                clearable
                                :value="item.pivot.nr_classificacao"
                                @change="changeNrClassificacao(item, $event)"
                                :items="[
                                    {text: '1º lugar', value: 1},
                                    {text: '2º lugar', value: 2},
                                    {text: '3º lugar', value: 3},
                                    {text: '4º lugar', value: 4},
                                    {text: '5º lugar', value: 5},
                                ]"
                            ></v-select>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn
                                        fab
                                        color="primary"
                                        elevation="0"
                                        x-small
                                        @click="fichaFuncional(item.id_servidor)"
                                        v-on="{ ...tooltip}"
                                    >
                                        <v-icon>
                                            mdi-folder-account
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Ficha Funcional</span>
                            </v-tooltip>
                            <v-tooltip v-if="!!item.pivot.id_solicitacao_movimentacao" top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn
                                        fab
                                        color="success"
                                        elevation="0"
                                        x-small
                                        :to="{
                                            name: 'solicitacao-movimentacao-form',
                                            params: { solicitacao: item.pivot.id_solicitacao_movimentacao },
                                        }"
                                        v-on="{ ...tooltip}"
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar Solicitação Movimentação</span>
                            </v-tooltip>
                            <v-tooltip v-else top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn
                                        fab
                                        color="primary"
                                        :disabled="!processoAberto"
                                        elevation="0"
                                        x-small
                                        @click="openDialog(item)"
                                        v-on="{ ...tooltip}"
                                    >
                                        <v-icon>
                                            mdi-arrow-decision
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Solicitar Movimentação</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-sheet>
                <v-dialog
                    v-model="dialogSolicitarMovimentacao"
                    persistent
                    max-width="390"
                >
                    <v-card>
                        <v-card-title>Solicitar Movimentação</v-card-title>
                        <v-card-text>Tem certeza que deseja solicitar uma movimentação?</v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn outlined @click="dialogSolicitarMovimentacao=false">
                                Não
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="solicitarMovimentacao"
                            >
                                Sim
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogReabrirInscricoes"
                    max-width="500"
                >
                    <v-card>
                        <v-card-title>Atenção</v-card-title>
                        <v-card-text>Você deseja alterar as informações sobre a vaga antes de reabrir as inscrições?</v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" @click="reabrirInscricoes()">
                                Reabrir inscrições
                            </v-btn>
                            <v-btn color="success" @click="updateStatus()">
                                <v-icon left>mdi-pencil</v-icon>
                                Alterar informações
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogEncerrarSelecao"
                    persistent
                    max-width="390"
                >
                    <v-card>
                        <v-card-title>Encerrar Seleção</v-card-title>
                        <v-card-text>
                            Deseja encerrar essa seleção? Não será mais possível selecionar novos servidores
                            nem informar novos candidatos aprovados
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn outlined @click="dialogEncerrarSelecao = false">
                                Não
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="encerrarSelecao"
                            >
                                Sim
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-text>
            <v-card-text v-show="showFichaFuncional">
                <v-sheet class="pa-4 mb-5" rounded outlined>
                    <FichaFuncionaResumidaTabs v-if="servidor.id" :servidor="servidor"/>
                </v-sheet>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

import OportunidadeProcessoCard from "../OportunidadeProcesso/OportunidadeProcessoCard.vue";
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {dateMixin} from "../../mixins/dateMixin";
import {gerarPDFServidor, getServidorResumido} from "../../api/servidor";
import FichaFuncionaResumidaTabs from "../FichaFuncional/FichaFuncionaResumidaTabs.vue";
import {fileMixin} from "../../mixins/fileMixin";
import {
    gerarSolicitarMovimentacaoInscrito,
    getProcessoSeletivoInscritos,
    updateClassificacaoInscrito
} from "../../api/processo-seletivo-inscritos";
import {concluirProcessoSeletivo, updateProcessoSeletivoStatus} from "../../api/processo-seletivo";
import {reabrirInscricoesMixin} from "../../mixins/reabrirInscricoesMixin";

export default {
    name: "ProcessoSeletivoInscritos",
    components: {FichaFuncionaResumidaTabs, OportunidadeProcessoCard},
    mixins: [dataTableMixin, dateMixin, fileMixin, reabrirInscricoesMixin],
    props: ['disabled', 'processoSeletivo'],
    data() {
        return {
            headers: [
                {text: 'Nome', value: 'nm_servidor', sortable: false},
                {text: 'Carreira', value: 'cargo.sg_cargo', sortable: false},
                {text: 'Data Inscrição', value: 'created_at', sortable: false},
                {text: 'Classificação', value: 'pivot.nr_classificacao', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            showFichaFuncional: false,
            servidor: {},
            currentItem: {},
            dialogSolicitarMovimentacao: false,
            dialogEncerrarSelecao: false,
        }
    },
    computed: {
        processoAberto() {
            return this.processoSeletivo.id_status_processo_seletivo === 4 ||
                this.processoSeletivo.id_status_processo_seletivo === 5
        },
    },
    methods: {
        filtrar() {
            getProcessoSeletivoInscritos(this.processoSeletivo.id_processo_seletivo).then(response => {
                this.items = response.data.data
                this.pagination.total = response.data.data.length
            })
        },
        changeNrClassificacao(item, value) {
            updateClassificacaoInscrito(
                this.processoSeletivo.id_processo_seletivo,
                item.id_servidor,
                {nr_classificacao: value}
            ).then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                });
            })
        },
        async fichaFuncional(id_servidor) {
            if (id_servidor !== this.servidor.id) {
                this.servidor = {}
                await getServidorResumido(id_servidor).then(response => {
                    this.servidor = {...response.data.data}
                })
            }
            this.showFichaFuncional = true
        },
        gerarPDF() {
            gerarPDFServidor(this.servidor.id).then((response) => {
                this.forceFileDownload(response, `Ficha Funcional - ${this.servidor.nome}.pdf`);
            })
        },
        openDialog(item) {
            this.dialogSolicitarMovimentacao = true
            this.currentItem = item.pivot;
        },
        solicitarMovimentacao() {
            gerarSolicitarMovimentacaoInscrito(this.currentItem.id_processo_seletivo, this.currentItem.id_servidor).then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message
                });
                this.dialogSolicitarMovimentacao = false
                this.$vuetify.goTo(0);
                this.$router.push({
                    name: 'solicitacao-movimentacao-form',
                    params: {
                        step: 1,
                        solicitacao: response.data.data.id_solicitacao_movimentacao,
                    }
                })
            })
        },
        updateStatus() {
            updateProcessoSeletivoStatus(this.processoSeletivo.id_processo_seletivo, {id_status_processo_seletivo: 1})
                .then(response => {
                    this.dialogReabrirInscricoes = false;
                    this.$root.$emit('update-status-processo-seletivo', 1)
                    this.$emit('nextStep');
                })
        },
        encerrarSelecao() {
            concluirProcessoSeletivo(this.processoSeletivo.id_processo_seletivo).then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message
                })
                this.dialogEncerrarSelecao = false
                this.$vuetify.goTo(0);
                this.$router.push({name: 'processo-seletivo-listar'})
            });
        }
    },
}
</script>
