<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col cols="12" md="10 ">
                        <v-text-field
                            label="Área de Concentração *"
                            outlined
                            dense
                            v-model="form.nm_area_concentracao"
                            :rules="validate('required', 'área de concentração')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <CarreiraSelect
                            label="Carreira *"
                            v-model="form.id_cargo"
                            :rules="validate('required', 'carreira')"
                            group-carreiras
                        />
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.id_cargo="{ item }">
                <v-badge v-if="item.id_cargo == 1" color="blue" content="EPPGG" />
                <v-badge v-else-if="item.id_cargo == 2" color="green" content="ACE" />
                <v-badge v-else-if="item.id_cargo == 3" color="orange" content="AIE / EIS" />
            </template>
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)" />
                <DeleteAction @delete="deletar(item[column_id])" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {
    createAreaConcentracao,
    deleteAreaConcentracao,
    getAreasConcentracao,
    updateAreaConcentracao
} from "../../api/area_concentracao";
import CarreiraSelect from "../FormInputs/CarreiraSelect.vue";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaAreaConcentracao",
    components: {EditAction, DeleteAction, CarreiraSelect},
    mixins: [listasDominiosMixin],
    data() {
        return {
            headers: [
                {text: 'Nome', value: 'nm_area_concentracao', sortable: false},
                {text: 'Carreira', value: 'id_cargo', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            fields: {
                id_cargo: null,
                nm_area_concentracao: null,
            },
            column_id: 'id_area_concentracao',
            api: {
                index: getAreasConcentracao,
                create: createAreaConcentracao,
                update: updateAreaConcentracao,
                delete: deleteAreaConcentracao,
            }
        }
    },
}
</script>
