import api from './index'

const getPoliticasPublicas = (params) => api.get('politicas-publicas', {
    params: params
});
const createPoliticasPublicas = (dados) => api.post('politicas-publicas', dados);
const updatePoliticasPublicas = (id, dados) => api.put(`politicas-publicas/${id}`, dados);
const deletePoliticasPublicas = (id) => api.delete(`politicas-publicas/${id}`);

export {
    getPoliticasPublicas,
    createPoliticasPublicas,
    updatePoliticasPublicas,
    deletePoliticasPublicas,
}
