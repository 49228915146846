<template>
    <div>
        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :to="{name: 'processo-seletivo-form'}"
            >
                Novo Registro
            </v-btn>
        </div>
        <v-sheet
            class="pa-md-5"
            :outlined="$vuetify.breakpoint.mdAndUp"
            rounded
        >
            <h2 class="subtitle-1 mx-4 mx-md-0 mb-3 font-weight-bold">
                Acompanhamento de Solicitações de Abertura de Processos Seletivos
            </h2>
            <v-card class="mb-9" v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <OrgaoEntidadeSIORGAutocomplete
                                clearable
                                v-model="filtro['tb_processo_seletivo.id_orgao']"
                            />
                        </v-col>
                        <v-col cols="12">
                            <SecretariaDepartamentoSIORGAutocomplete
                                clearable
                                label="Secretaria ou departamento"
                                v-model="filtro['tb_processo_seletivo.id_secretaria_departamento_exercicio']"
                                :id-orgao="filtro['tb_processo_seletivo.id_orgao']"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <CarreiraSelect v-model="filtro['id_cargo']" />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-select
                                label="Status"
                                outlined
                                dense
                                clearable
                                v-model="filtro['tb_processo_seletivo.id_status_processo_seletivo']"
                                :items="status"
                                item-value="id_status_processo_seletivo"
                                item-text="nm_status_processo_seletivo"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-select
                                label="Analista"
                                outlined
                                dense
                                clearable
                                v-model="filtro['tb_analise_processo_seletivo.id_analista_responsavel']"
                                :items="analistas"
                                item-value="id_usuario"
                                item-text="nm_usuario"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-autocomplete
                                label="Solicitante"
                                outlined
                                dense
                                clearable
                                v-model="filtro['tb_processo_seletivo.id_usuario_solicitante']"
                                :items="solicitantes"
                                item-value="id_usuario"
                                item-text="nm_usuario"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        @click="limparFiltro()"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>
                            mdi-filter
                        </v-icon>
                        Filtrar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="pagination.itemsPerPage"
                :footer-props="footerProps"
                :options.sync="pagination"
                @update:options="filtrar()"
                :server-items-length="pagination.total"
                class="elevation-1"
            >
                <template v-slot:item.dt_envio_solicitacao="{ item }">
                    {{ dateFormat(item.dt_envio_solicitacao) }}
                </template>
                <template v-slot:item.cargos="{ item }" >
                    <cargos-chip :cargos="item.cargos" />
                </template>
                <template v-slot:item.actions="{ item }">
                    <EditAction
                        v-if="item.id_status_processo_seletivo == 1 ||
                        item.id_status_processo_seletivo == 3 ||
                        $store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                        :to="{name: 'processo-seletivo-form', params: {processo: item.id_processo_seletivo}}"
                    />
                    <ViewAction
                        v-else
                        :to="{name: 'processo-seletivo-form', params: {processo: item.id_processo_seletivo}}"
                    />
                </template>
            </v-data-table>
        </v-sheet>
    </div>
</template>

<script>

import {dataTableMixin} from "../mixins/dataTableMixin";
import {getProcessosSeletivos} from "../api/processo-seletivo";
import {dateMixin} from "../mixins/dateMixin";
import CargosChip from "../components/CargosChip.vue";
import {orgaoSecretariaUnidadeMixin} from "../mixins/orgaoSecretariaUnidadeMixin";
import {getStatusProcessoSeletivo} from "../api/status-processo-seletivo";
import {getUsuarios} from "../api/usuario";
import CarreiraSelect from "../components/FormInputs/CarreiraSelect.vue";
import OrgaoEntidadeSIORGAutocomplete from "../components/FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
import SecretariaDepartamentoSIORGAutocomplete
    from "../components/FormInputs/SecretariaDepartamentoSIORGAutocomplete.vue";
import ViewAction from "../components/Table/ViewAction.vue";
import EditAction from "../components/Table/EditAction.vue";

export default {
    name: "ProcessoSeletivoListar",
    components: {
        EditAction,
        ViewAction,
        SecretariaDepartamentoSIORGAutocomplete, OrgaoEntidadeSIORGAutocomplete, CarreiraSelect, CargosChip},
    mixins: [dataTableMixin, dateMixin, orgaoSecretariaUnidadeMixin],
    data() {
        return {
            loading: false,
            status: [],
            analistas: [],
            solicitantes: [],
            pagination: {
                sortBy: ['dt_envio_solicitacao', 'id_processo_seletivo'],
                sortDesc: [false, true],
            },
            filtro: {
                'tb_processo_seletivo.id_orgao': null,
                'tb_processo_seletivo.id_secretaria_departamento_exercicio': null,
                'id_cargo': null,
                'tb_processo_seletivo.id_unidade_exercicio': null,
                'tb_analise_processo_seletivo.id_analista_responsavel': null,
                'tb_processo_seletivo.id_usuario_solicitante': null,
            },
            headers: [
                {text: 'Órgão ou Entidade', value: 'nm_orgao', sortable: true},
                {text: 'Secretaria ou Departamento', value: 'nm_secretaria_departamento_exercicio', sortable: true},
                {text: 'Unidade', value: 'nm_unidade_exercicio', sortable: true},
                {text: 'Carreiras', value: 'cargos', sortable: false},
                {text: 'Nome do Solicitante', value: 'nm_usuario_solicitante', sortable: true},
                {text: 'Data', value: 'dt_envio_solicitacao', sortable: true},
                {text: 'Status', value: 'nm_status_processo_seletivo', sortable: true},
                {text: 'Ações', value: 'actions', sortable: false},
            ]
        }
    },
    watch: {
        filtro: {
            handler: function(newValue, oldValue) {
                if (newValue['tb_processo_seletivo.id_orgao']) {
                    this.searchUnidades(newValue['tb_processo_seletivo.id_orgao'])
                }
            },
            deep: true
        }
    },
    created() {
        if (this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])) {
            getStatusProcessoSeletivo().then(response => {
                this.status = response.data.data;
            });
            getUsuarios(1, 10000, {}).then(response => {
                this.analistas = response.data.data.data.filter(usuario => usuario.in_perfis.includes('analista_cgcat'));
                this.solicitantes = response.data.data.data;
            });
        }
    },
    methods: {
        filtrar() {
            if (!this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat', 'rh'])) {
                this.filtro.id_usuario_solicitante = this.$store.getters['auth/usuarioAutenticado']
            }
            this.handlerPaginatePromise(getProcessosSeletivos(this.pagination, this.filtro))
        },
        limparFiltro() {
            this.filtro = {}
            this.filtrar()
        }
    },
}
</script>
