export const stepperMixin = {
    data() {
        return {
            step: 1,
            stepEditable: 1,
            stepVisited: [1],
        }
    },
    methods: {
        editableStep(value) {
            return value <= this.stepEditable;
        },
        completeStep(value) {
            return value < this.step;
        },
        nextStep() {
            this.step++;
            this.addStepVisited(this.step);
            if (this.stepEditable < this.step) {
                this.stepEditable = this.step;
            }
        },
        addStepVisited(value) {
            if (!this.stepVisited.includes(value)) {
                this.stepVisited.push(value);
            }
        },
    }
}

