<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12">
                <AtribuirResponsavelSelect
                    v-model="form.id_analista_responsavel"
                    :rules="validate('required', 'atribuir responsável')"
                />
            </v-col>
            <v-col cols="12">
                <v-select
                    label="Atribuir status *"
                    outlined
                    dense
                    v-model="form.id_status_processo_seletivo"
                    :rules="validate('required', 'atribuir status')"
                    :items="status"
                    item-value="id_status_processo_seletivo"
                    item-text="nm_status_processo_seletivo"
                ></v-select>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Definir prazo de entrega"
                    type="date"
                    outlined
                    dense
                    v-model="form.dt_prazo"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-textarea
                    label="Orientações para Analista CGCAT"
                    outlined
                    dense
                    v-model="form.ds_orientacoes"
                ></v-textarea>
            </v-col>
        </v-row>
        <div class="d-flex flex-wrap justify-end mb-5">
            <v-spacer></v-spacer>
            <v-btn
                class="ml-2 mb-2"
                color="primary"
                @click="salvar"
            >
                Salvar
            </v-btn>
        </div>
        <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
    </v-form>
</template>

<script>
import AtribuirResponsavelSelect from "../FormInputs/AtribuirResponsavelSelect.vue";
import {validationMixin} from "../../mixins/validationMixin";
import {getStatusProcessoSeletivo} from "../../api/status-processo-seletivo";
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao.vue";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {upsertAnaliseProcessoSeletivo} from "../../api/analise-processo-seletivo";

export default {
    name: "AnaliseProcessoSeletivoGeral",
    props: ['analiseProcessoSeletivo', 'id_processo_seletivo', 'id_status_processo_seletivo'],
    components: {RowDataUsuarioAlteracao, AtribuirResponsavelSelect},
    mixins: [validationMixin, dataUsuarioAlteracaoMixin],
    data() {
        return {
            status: [],
            form: {
                id_analista_responsavel: null,
                id_status_processo_seletivo: null,
                dt_prazo: null,
                ds_orientacoes: null,
            }
        }
    },
    watch: {
        analiseProcessoSeletivo(obj) {
            this.form.id_analista_responsavel = obj.id_analista_responsavel;
            this.form.dt_prazo = obj.dt_prazo;
            this.form.ds_orientacoes = obj.ds_orientacoes;
            this.usuarioAlteracao = obj.usuario?.nm_usuario
            this.setDataAlteracao(obj.updated_at);
        },
        id_status_processo_seletivo(value) {
            this.form.id_status_processo_seletivo = value;
        }
    },
    created() {
        getStatusProcessoSeletivo().then(response => this.status = response.data.data);
        this.form.id_status_processo_seletivo = this.id_status_processo_seletivo;
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                upsertAnaliseProcessoSeletivo(this.id_processo_seletivo, this.form).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.setDataUsuarioAlteracaoByResponse(response);
                    if (!this.analiseProcessoSeletivo) {
                        this.$emit('setAnaliseProcessoSeletivo', response.data.data);
                    }
                    if (!this.form.id_status_processo_seletivo != this.id_status_processo_seletivo) {
                        this.$emit('setIdStatusProcessoSeletivo', this.form.id_status_processo_seletivo);
                    }
                    this.$vuetify.goTo(0);
                });
            }
        }
    },
}
</script>
