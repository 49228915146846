<template>
    <div>
        <h1 class="text-h5 my-4">
            Perfil | {{ !!this.servidor.cargo ? this.servidor.cargo.sigla : '' }} | {{ servidor.nome }}
        </h1>
        <v-tabs v-model="tab">
            <v-tab v-for="(item, key) in items" :key="key">
                {{ item.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, key) in items" :key="key">
                <v-sheet class="pa-md-4" :outlined="$vuetify.breakpoint.mdAndUp" rounded>
                    <component :is="item.component" v-bind="item.data"/>
                </v-sheet>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import FichaDadosPessoais from "./FichaDadosPessoais.vue";
import FichaInteresses from "./FichaInteresses.vue";
import FichaCurriculo from "./FichaCurriculo.vue";
import moment from 'moment';
import FichaHistoricoForm from "./FichaHistoricoForm.vue";
import {getServidorHistoricoAtual} from "../../api/servidor";
import FichaHistoricoAtual from "./FichaHistoricoAtual.vue";

moment.locale('pt-br');

export default {
    name: "FichaFuncionaResumidaTabs",
    props: {
        servidor: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            tab: null,
        }
    },
    computed: {
        dataAlteracao() {
            if (this.servidor.updated_at) {
                return moment(this.servidor.updated_at).format('DD/MM/YYYY')
            }
            return null
        },
        items() {
            return [
                {
                    text: 'Dados Pessoais',
                    component: FichaDadosPessoais,
                    data: {servidor: this.servidor, resumido: true, dataAlteracaoServidor: this.dataAlteracao}
                },
                {
                    text: 'Órgão Atual',
                    component: FichaHistoricoAtual,
                    data: {servidor: this.servidor}
                },
                {text: 'Interesses', component: FichaInteresses, data: {servidorId: this.servidor.id, resumido: true}},
                {text: 'Currículo', component: FichaCurriculo, data: {servidorId: this.servidor.id, resumido: true}},
            ]
        }
    }
}
</script>
