export const menu = {
    namespaced: true,
    state: {
        sideBarOpen: false
    },
    getters: {
        g_sideBarOpen(state){
            return state.sideBarOpen
        }
    },
    mutations: {
        toggleSideBar(state){
            state.sideBarOpen = !state.sideBarOpen;
        },
        closeSideBar(state) {
            state.sideBarOpen = false;
        },
    }
};
