<template>
    <div>
        <v-form :id="column_id" ref="form">
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Modalidade de trabalho *"
                            outlined
                            dense
                            v-model="form.nm_modalidade_trabalho"
                            :rules="validate('required', 'modalidade de trabalho')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            hide-default-footer
            class="elevation-1 mt-7"
        >
            <template v-slot:item.actions="{ item }">
                <EditAction @click="editar(item)"/>
                <DeleteAction @delete="deletar(item[column_id])" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {listasDominiosMixin} from "../../mixins/listasDominiosMixin";
import {
    createModalidadeTrabalho, deleteModalidadeTrabalho,
    getModalidadesTrabalho,
    updateModalidadeTrabalho
} from "../../api/modalidade-trabalho";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

export default {
    name: "ListaModalidadeTrabalho",
    components: {EditAction, DeleteAction},
    mixins: [listasDominiosMixin],
    data() {
        return {
            headers: [
                {text: 'Nome', value: 'nm_modalidade_trabalho', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            fields: {
                nm_modalidade_trabalho: null,
            },
            column_id: 'id_modalidade_trabalho',
            api: {
                index: getModalidadesTrabalho,
                create: createModalidadeTrabalho,
                update: updateModalidadeTrabalho,
                delete: deleteModalidadeTrabalho,
            },
        }
    },
}
</script>
