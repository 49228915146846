import api from './index';

const getUnidades = (idUnidadePai, with_trashed) => api.get('unidade', {
    params: {
        id_unidade_pai: idUnidadePai,
        with_trashed: with_trashed,
    },
});

const getUnidadesSharepoint = () => api.get('unidade-sharepoint');

const getUnidadesPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('unidade-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
})

const createUnidade = (dados) => api.post('unidade', dados)

const updateUnidade = (id_unidade, dados) => api.put('unidade/' + id_unidade, dados)

const deleteUnidade = (id_unidade) => api.delete('unidade/' + id_unidade)

export {
    getUnidades,
    getUnidadesSharepoint,
    getUnidadesPaginate,
    createUnidade,
    updateUnidade,
    deleteUnidade,
}
