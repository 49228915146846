import api from './index'

const getInstituicoesEnsino = () => api.get('instituicao-ensino');
const createInstituicaoEnsino = (dados) => api.post('instituicao-ensino', dados);
const updateInstituicaoEnsino = (id, dados) => api.put(`instituicao-ensino/${id}`, dados);
const deleteInstituicaoEnsino = (id) => api.delete(`instituicao-ensino/${id}`);

export {
    getInstituicoesEnsino,
    createInstituicaoEnsino,
    updateInstituicaoEnsino,
    deleteInstituicaoEnsino,
}
