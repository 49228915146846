<template>
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        max-width="900"
        @click:outside="$emit('input', false)"
    >
        <v-form ref="form">
            <v-sheet class="pa-5">
                <h2 class="mb-3">Inscrição</h2>
                <v-divider></v-divider>
                <OportunidadeProcessoCard
                    class="mx-n4"
                    :oportunidade-movimetacao="oportunidadeMovimetacao"
                />
                <v-divider class="mb-4"></v-divider>

                <v-alert v-if="servidor.inscricao" type="info" prominent>
                    Você já está inscrito nesta oportunidade.
                </v-alert>
                <div v-else-if="servidor.curriculo">
                    <p>Olá, <strong>{{ servidor.nome }}</strong>, antes de se inscrever nesta oportunidade,
                        dê uma última olhada se os seus dados de contato em sua <strong>Ficha Funcional</strong>
                        estão atualizados, e se o seu <strong>currículo no SouGov</strong> está  refletindo os
                        pontos fortes que você quer destacar para essa vaga!</p>

                    <v-checkbox
                        v-model="authorized"
                        label="Autorizo o compartilhamento dos meus dados de contato, raça, deficiência, bem como
                        currículo e mapeamento de competências para o responsável pelo processo seletivo."
                        :rules="validate('accepted', '')"
                    />
                </div>
                <v-alert v-else type="warning" prominent>
                    Não é possível se inscrever nessa oportunidade, pois você não possui Currículo cadastrado no SouGov.
                    Acesse o módulo <strong>“Currículo e Oportunidades”</strong> em <a href="https://www.gov.br/sougov" target="_blank">www.gov.br/sougov</a>,
                    e clique em <strong>Meu Currículo</strong>. Após o preenchimento de todas as informações solicitadas,
                    o seu CV será disponibilizado no SGC no próximo domingo, e a partir deste dia você poderá se inscrever
                    nas oportunidades de movimentação do seu interesse.
                </v-alert>

                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-4" outlined @click="$emit('input', false)">Voltar</v-btn>
                    <v-btn
                        color="primary"
                        @click="inscrever()"
                        :disabled="!authorized"
                    >
                        Enviar
                    </v-btn>
                </div>
            </v-sheet>
        </v-form>
    </v-dialog>
</template>

<script>
import OportunidadeProcessoCard from "./OportunidadeProcessoCard.vue";
import {validationMixin} from "../../mixins/validationMixin";
import {inscreverOportunidadeMovimentacao} from "../../api/oportunidade-movimentacao";
import {getServidor, getServidorCurriculo, getServidorOportunidades} from "../../api/servidor";

export default {
    name: "OportunidadeMovimentacaoInscreverDialog",
    components: {OportunidadeProcessoCard},
    mixins: [validationMixin],
    props: {
        value: {
            type: Boolean,
        },
        oportunidadeMovimetacao: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            authorized: false,
            servidor: {
                nome: null,
                curriculo: false,
                inscricao: false,
            },
        }
    },
    async created() {
        await getServidor(this.$store.getters['auth/usuarioAutenticadoServidor']).then(response => {
            this.servidor.nome = response.data.data.nome;
        })
        await getServidorCurriculo(this.$store.getters['auth/usuarioAutenticadoServidor']).then(response => {
            this.servidor.curriculo = !!response.data.data.url_banco_talentos;
        })
        await getServidorOportunidades(this.$store.getters['auth/usuarioAutenticadoServidor']).then(response => {
            this.servidor.inscricao = !!response.data.data.oportunidades.filter((oportunidade) => {
                return oportunidade.id_processo_seletivo === this.oportunidadeMovimetacao.id_processo_seletivo
            }).length
        })
    },
    methods: {
        inscrever() {
            if (this.$refs.form.validate()) {
                inscreverOportunidadeMovimentacao(this.oportunidadeMovimetacao.id_processo_seletivo)
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.servidor.inscricao = true
                    })
                    .finally(() => {
                        this.$vuetify.goTo(0);
                        this.$emit('input', false);
                    })
            }
        }
    }
}
</script>
