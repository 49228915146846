<template>
    <v-form ref="form">
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="6" md="3">
                    <v-autocomplete
                        label="País *"
                        outlined
                        dense
                        v-model="form.id_pais"
                        :rules="validate('required', 'país')"
                        :items="itemsPaises"
                        item-value="id_pais"
                        item-text="nm_pais"
                        @change="limparCamposEndereco()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="CEP *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.nr_cep"
                        :rules="validate('required', 'cep')"
                        :error-messages="error.nr_cep"
                        v-mask="form.id_pais == 21 ? '#####-###': ''"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Logradouro *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_logradouro"
                        :rules="validate('required', 'logradouro')"
                        :error-messages="error.ds_logradouro"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Bairro *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_bairro"
                        :rules="validate('required', 'bairro')"
                        :error-messages="error.ds_bairro"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Número *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_numero"
                        :rules="validate('required', 'número')"
                        :error-messages="error.ds_numero"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Complemento *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_complemento"
                        :rules="validate('required', 'complemento')"
                        :error-messages="error.ds_complemento"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-autocomplete
                        v-if="form.id_pais == 21"
                        label="Estado *"
                        outlined
                        dense
                        clearable
                        v-model="form.ds_estado"
                        :rules="validate('required', 'estado')"
                        :error-messages="error.ds_estado"
                        :items="['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE',
                        'PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']"
                        @change="form.nm_cidade = null"
                    ></v-autocomplete>
                    <v-text-field
                        v-else
                        label="Estado *"
                        outlined
                        @keyup.enter="salvar()"
                        dense
                        v-model="form.ds_estado"
                        :rules="validate('required', 'estado')"
                        :error-messages="error.ds_estado"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-autocomplete
                        v-if="form.id_pais == 21"
                        label="Cidade *"
                        outlined
                        dense
                        clearable
                        :disabled="!form.ds_estado"
                        v-model="form.nm_cidade"
                        :rules="validate('required', 'cidade')"
                        :error-messages="error.nm_cidade"
                        :items="itemsMunicipios"
                        item-value="nm_municipio"
                        item-text="nm_municipio"
                    ></v-autocomplete>
                    <v-text-field
                        v-else
                        label="Cidade *"
                        outlined
                        @keyup.enter="salvar()"
                        dense
                        v-model="form.nm_cidade"
                        :rules="validate('required', 'cidade')"
                        :error-messages="error.nm_cidade"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Telefone Residencial *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.nr_telefone_residencial"
                        :rules="validate('required', 'telefone residencial')"
                        :error-messages="error.nr_telefone_residencial"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                    <v-text-field
                        label="Celular *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.nr_telefone_celular"
                        :rules="validate('required', 'celular')"
                        :error-messages="error.nr_telefone_celular"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Email Pessoal *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_email_pessoal_1"
                        :rules="validate('required|email', 'email pessoal')"
                        :error-messages="error.ds_email_pessoal_1"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Email Pessoal 2"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_email_pessoal_2"
                        :rules="validate('email', 'email pessoal 2')"
                        :error-messages="error.ds_email_pessoal_2"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field
                        label="Email Institucional *"
                        outlined
                        dense
                        @keyup.enter="salvar()"
                        v-model="form.ds_email_pessoal_institucional"
                        :rules="validate('required|email', 'email institucional')"
                        :error-messages="error.ds_email_pessoal_institucional"
                    ></v-text-field>
                </v-col>
            </v-row>
            <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
        </v-sheet>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {getServidorContatos, upsertServidorContatos} from "../../api/servidor";
import moment from 'moment';
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {enderecoMixin} from "../../mixins/enderecoMixin";
import {validationMixin} from "../../mixins/validationMixin";

moment.locale('pt-br');

export default {
    name: "FichaContatos",
    mixins: [enderecoMixin, dataUsuarioAlteracaoMixin, validationMixin],
    components: {RowDataUsuarioAlteracao},
    props: {
        servidorId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            form: {
                id_pais: 21, // Brasil
                nr_cep: null,
                ds_logradouro: null,
                ds_bairro: null,
                ds_numero: null,
                ds_complemento: null,
                ds_estado: null,
                nm_cidade: null,
                nr_telefone_celular: null,
                ds_email_pessoal_1: null,
                ds_email_pessoal_2: null,
                ds_email_pessoal_institucional: null,
            },
            error: {},
        }
    },
    created() {
        getServidorContatos(this.servidorId)
            .then(response => {
                this.form = {
                    ...response.data.data
                }

                this.setDataUsuarioAlteracaoByResponse(response)
            });
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                upsertServidorContatos(this.servidorId, JSON.stringify(this.form))
                    .then((response) => {
                        this.setDataUsuarioAlteracaoByResponse(response)

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$vuetify.goTo(0);
                    })
                    .catch((error) => {
                        this.error = {...error.response.data.errors}
                    });
            }
        }
    },
}
</script>

<style scoped>

</style>
