<template>
    <v-sheet class="pa-4  mb-5" rounded outlined>
        <h2 class="subtitle-1 mb-3 font-weight-bold">Instruções</h2>

        <p>Antes de preencher o formulário, tenha em mãos as Entregas ou os Resultados mais relevantes da
            unidade nos próximos 2 anos em que haverá contribuição da servidora ou do servidor.</p>

        <p>Para mais informações, consulte o <a
            href="https://www.gov.br/economia/pt-br/assuntos/gestao/outros/gestao-publica/gestao-de-carreiras/gestao-das-carreiras-transversais"
            target="_blank">site</a>
            das carreiras supervisionadas pela Secretaria de Gestão e Inovação, em especial, as <strong>Perguntas
                Frequentes</strong> e a seção sobre <strong>Mobilidade</strong>.</p>

        <p>Lembre-se que você poderá salvar os dados já preenchidos e continuar o preenchimento do
            formulário a qualquer tempo. Após o envio final, acesse o Menu <strong>{{ optionTitle }}</strong>
            para acompanhar o andamento da demanda.</p>
    </v-sheet>
</template>

<script>
export default {
    name: "InstrucoesSheet",
    props: ['optionTitle']
}
</script>
