<template>
    <v-form ref="form">
        <h1 class="text-h5 my-4">
            Termo de Uso e Aviso de Privacidade
        </h1>
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="12" md="8">
                    <v-text-field
                        label="Título *"
                        outlined
                        dense
                        v-model="form.ds_titulo"
                        :rules="validate('required', 'título')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                    <v-text-field
                        label="Versão *"
                        outlined
                        dense
                        v-model="form.nr_versao"
                        :rules="validate('required', 'versão')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                    <v-text-field
                        label="Data *"
                        outlined
                        dense
                        type="date"
                        hide-details
                        v-model="form.dt_termo_politica"
                        :rules="validate('required', 'data')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-input
                        v-model="form.ds_descricao"
                        :rules="validate('required', 'descrição')"
                    >
                        <VueEditor
                            class="col-12 pa-0"
                            placeholder="Descrição"
                            v-model="form.ds_descricao"
                        />
                    </v-input>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Endereço do termo e política *"
                        :append-icon="validateUrl(form.url_termo_politica) ? 'mdi-open-in-new' : ''"
                        outlined
                        dense
                        @click:append="openLink(form.url_termo_politica)"
                        v-model="form.url_termo_politica"
                        :rules="validate('required|url', 'endereço do termo e política')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        label="Resetar aceite de todos os usuários da base?"
                        v-model="form.st_novo_termo"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-sheet>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                outlined
                @click="visualizar()"
                class="mr-3"
            >
                Visualizar
            </v-btn>
            <v-btn
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>

        <dialog-termo-uso
            v-if="!!show"
            :show="show"
            :preview="preview"
            @closePreview="preview = {}"
        />
    </v-form>
</template>

<script>
import {getTermoPolitica, updateTermoPolitica} from "../api/termo-politica";
import DialogTermoUso from "../components/DialogTermoUso";
import {validationMixin} from "../mixins/validationMixin";
import { VueEditor } from "vue2-editor";

export default {
    name: "TermoPolitica",
    components: {DialogTermoUso, VueEditor},
    mixins: [validationMixin],
    data() {
        return {
            form: {st_novo_termo: false},
            preview: {}
        }
    },
    created() {
        getTermoPolitica().then(response => this.form = {...response.data.data})
    },
    computed: {
        show() {
            return !_.isEmpty(this.preview)
        }
    },
    methods: {
        visualizar() {
            if (this.$refs.form.validate()) {
                this.preview = {...this.form}
            }
        },
        salvar() {
            if (this.$refs.form.validate()) {
                updateTermoPolitica(this.form)
                    .then((response) => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.form.st_novo_termo = false
                        this.$vuetify.goTo(0);
                    })
            }
        },
        openLink(url) {
            window.open(url, '_blank');
        }
    },
}
</script>
