<template>
    <v-dialog
        :value="true"
        persistent
        max-width="900"
    >
        <v-card>
            <v-card-title>
                Gerar Documentos PDF
            </v-card-title>
            <v-card-text>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="footerProps"
                    :options.sync="pagination"
                    :server-items-length="pagination.total"
                    class="elevation-1 mb-7"
                    show-select
                    item-key="id_tipo_anexo"
                >
                    <template v-slot:item.dt_anexo="{ item }">
                        <span>{{ dateFormat(item.dt_anexo) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn outlined @click="closeDialog">voltar</v-btn>
                <v-btn
                    :disabled="selected.length === 0"
                    color="primary"
                    @click="downloadArquivos()"
                >
                    Baixar selecionados
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {getSolicitacaoMovimentacaoAnexos} from "../../api/solicitacao-movimentacao";
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {dateMixin} from "../../mixins/dateMixin";
import {downloadZip} from "../../api/analise-solicitacao";
import {fileMixin} from "../../mixins/fileMixin";

export default {
    name: "SelecaoArquivosDownloadDialog",
    mixins: [dataTableMixin, dateMixin, fileMixin],
    props: ['id_solicitacao', 'nm_servidor'],
    data() {
        return {
            selected: [],
            items: [],
            headers: [
                {text: 'Tipo', value: 'tipo_anexo.nm_anexo', sortable: false},
                {text: 'Número', value: 'nr_anexo', sortable: false},
                {text: 'Descrição', value: 'ds_anexo', sortable: false},
                {text: 'Data publicação', value: 'dt_anexo', sortable: false},
            ],
        }
    },
    async created() {
        await this.handlerPaginatePromise(
            getSolicitacaoMovimentacaoAnexos(this.id_solicitacao, this.pagination.page, this.pagination.itemsPerPage)
        )
        this.items.push(
            {'id_tipo_anexo': 'form', 'tipo_anexo.nm_anexo': 'Formulário de Pactuação de Resultados'},
            {'id_tipo_anexo': 'checklist', 'tipo_anexo.nm_anexo': 'Checklist CGCAT'}
        )
        this.pagination.total += 2
    },
    methods: {
        downloadArquivos() {
            console.log(this.selected.map(item => item.id_tipo_anexo))
            downloadZip(this.id_solicitacao, this.selected.map(item => item.id_tipo_anexo)).then(response => {
                this.forceFileDownload(response, `${this.nm_servidor}.zip`);
            })
            this.closeDialog()
        },
        closeDialog() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>
