<template>
    <div>
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Órgão exercício"
                        outlined
                        dense
                        disabled
                        :value="servidor.orgao_exercicio?.nome"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Secretaria ou departamento"
                        outlined
                        dense
                        disabled
                        :value="servidor.unidade_exercicio?.nome"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4">
                    <v-text-field
                        label="UF"
                        outlined
                        dense
                        disabled
                        :value="servidor.orgao_exercicio?.uf"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4">
                    <v-text-field
                        label="Data de ingresso no órgão"
                        outlined
                        dense
                        disabled
                        :value="dataIngressoOrgao"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-text-field
                        label="Data de exclusão no órgão"
                        outlined
                        dense
                        disabled
                        :value="dataExclusaoOrgao"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-row>
                <v-col cols="6" sm="8">
                    <v-text-field
                        label="Nome do cargo ou função"
                        outlined
                        dense
                        disabled
                        :value="servidor.funcao?.nome"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4">
                    <v-text-field
                        label="Sigla"
                        outlined
                        dense
                        disabled
                        :value="servidor.funcao?.sigla"
                    ></v-text-field>
                </v-col>
            </v-row>
            <row-data-usuario-alteracao fonte="SIAPE" :data="dataAlteracaoServidor" />
        </v-sheet>
    </div>
</template>

<script>
import moment from 'moment';
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
moment.locale('pt-br');

export default {
    name: "FichaOrgaoAtualSiape",
    components: {RowDataUsuarioAlteracao},

    props: {
        servidor: {
            type: Object
        },
        dataAlteracaoServidor: {
            type: String
        }
    },
    computed: {
        dataIngressoOrgao() {
            if (this.servidor.data_ingresso_orgao_exercicio) {
                return moment(this.servidor.data_ingresso_orgao_exercicio).format('DD/MM/YYYY');
            }
            return '';
        },
        dataExclusaoOrgao() {
            if (!!this.servidor.data_exclusao_orgao_exercicio) {
                return moment(this.servidor.data_exclusao_orgao_exercicio).format("DD/MM/YYYY");
            }
            return '';
        }
    },
}
</script>
