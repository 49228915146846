<template>
    <div>
        <v-form ref="form">
            <v-card class="mb-9">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                label="Tipo"
                                outlined
                                dense
                                clearable
                                v-model="filtro.id_tipo_historico"
                                :items="itemsTipoHistorico"
                                item-value="id_tipo_historico"
                                item-text="nm_tipo_historico"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                label="Situação"
                                outlined
                                dense
                                :disabled="!filtro.id_tipo_historico"
                                clearable
                                v-model="filtro.id_situacao"
                                :items="itemsSituacao"
                                item-value="id_situacao"
                                item-text="nm_situacao"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-input label="fonte:" dense>
                                <v-btn-toggle
                                    class="ml-5"
                                    v-model="toggle_fonte"
                                    @change="filtro.nm_orgao = null; filtro.nm_secretaria_departamento_exercicio = null; id_orgao = null; id_secretaria_departamento_exercicio = null;"
                                    dense
                                    mandatory
                                    active-class="primary white--text"
                                >
                                    <v-btn outlined>SIORG</v-btn>
                                    <v-btn outlined>SHAREPOINT</v-btn>
                                </v-btn-toggle>
                            </v-input>
                        </v-col>
                    </v-row>
                    <v-row v-if="toggle_fonte === 0">
                        <v-col cols="12">
                            <OrgaoEntidadeSIORGAutocomplete
                                v-show="!filtro.nm_orgao"
                                label="Órgão ou entidade de exercício *"
                                v-model="id_orgao"
                                :rules="validate('required', 'Orgão ou entidade de exercício')"
                                with-trashed
                                @update:nmOrgao="filtro.nm_orgao = $event"
                            />
                            <v-text-field
                                v-show="!!filtro.nm_orgao"
                                label="Órgão ou entidade de exercício *"
                                outlined
                                dense
                                clearable
                                readonly
                                v-model="filtro.nm_orgao"
                                @click:clear="id_orgao = null"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <SecretariaDepartamentoSIORGAutocomplete
                                v-show="!filtro.nm_secretaria_departamento_exercicio"
                                label="Secretaria ou departamento exercício"
                                v-model="id_secretaria_departamento_exercicio"
                                :id-orgao="id_orgao"
                                @update:nmSecretaria="filtro.nm_secretaria_departamento_exercicio = $event"
                                with-trashed
                            />
                            <v-text-field
                                v-show="!!filtro.nm_secretaria_departamento_exercicio"
                                label="Secretaria ou departamento exercício"
                                outlined
                                dense
                                clearable
                                readonly
                                v-model="filtro.nm_secretaria_departamento_exercicio"
                                @click:clear="id_secretaria_departamento_exercicio = null"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12">
                            <v-autocomplete
                                label="Órgão ou entidade de exercício *"
                                outlined
                                dense
                                clearable
                                v-model="filtro.nm_orgao"
                                :items="itemsOrgaosSharepoint"
                                :rules="validate('required', 'Orgão ou entidade de exercício')"
                                :filter="filterNormalize"
                                item-value="nm_orgao_sharepoint"
                                item-text="nm_orgao_sharepoint"
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                label="Secretaria ou departamento exercício"
                                outlined
                                dense
                                clearable
                                v-model="filtro.nm_secretaria_departamento_exercicio"
                                :items="itemsUnidadesSharepoint"
                                :filter="filterNormalize"
                                item-value="nm_unidade_sharepoint"
                                item-text="nm_unidade_sharepoint"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                label="Carreira"
                                outlined
                                dense
                                clearable
                                v-model="filtro.id_cargo"
                                :items="itemsCargo"
                                item-value="id"
                                item-text="sigla"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        @click="limpar()"
                    >
                        Limpar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>
                            mdi-filter
                        </v-icon>
                        Filtrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                :disabled="selected.length === 0"
                color="success"
                @click="modalForm = true"
            >
                <v-icon left>
                    mdi-pencil
                </v-icon>
                Editar
            </v-btn>
        </div>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            item-key="id_historico"
            class="elevation-1"
            show-select
            :loading="loading"
        ></v-data-table>
        <movimentacao-lote-form-dialog
            v-if="modalForm"
            :tipos-historicos="selected.map(item => item.id_tipo_historico)"
            :historicos="selected.map(item => item.id_historico)"
            :cargos="selected.map(item => item.id_cargo)"
            @salvar="filtrar"
            @close="modalForm = false"
        >
        </movimentacao-lote-form-dialog>
    </div>
</template>

<script>
import {dataTableMixin} from "../mixins/dataTableMixin";
import {orgaoSecretariaUnidadeMixin} from "../mixins/orgaoSecretariaUnidadeMixin";
import {getHistoricosAtuais} from "../api/historico";
import tipoHistorico from "../api/tipo-historico";
import {validationMixin} from "../mixins/validationMixin";
import {getSituacoes} from "../api/situacao";
import MovimentacaoLoteFormDialog from "../components/MovimentacaoLoteServidores/MovimentacaoLoteFormDialog.vue";
import {getOrgaosSharepoint} from "../api/orgao";
import {getUnidadesSharepoint} from "../api/unidade";
import CarreiraSelect from "../components/FormInputs/CarreiraSelect.vue";
import OrgaoEntidadeSIORGAutocomplete from "../components/FormInputs/OrgaoEntidadeSIORGAutocomplete.vue";
import SecretariaDepartamentoSIORGAutocomplete
    from "../components/FormInputs/SecretariaDepartamentoSIORGAutocomplete.vue";
import {getCargos} from "../api/cargo";

export default {
    name: "MovimentacaoLoteServidores",
    components: {SecretariaDepartamentoSIORGAutocomplete, OrgaoEntidadeSIORGAutocomplete, MovimentacaoLoteFormDialog},
    mixins: [dataTableMixin, orgaoSecretariaUnidadeMixin, validationMixin],
    data() {
        return {
            toggle_fonte: 0,
            id_orgao: null,
            itemsWithTrashed: true,
            id_secretaria_departamento_exercicio: null,
            filtro: {
                id_tipo_historico: null,
                id_situacao: null,
                nm_orgao: null,
                nm_secretaria_departamento_exercicio: null,
                id_cargo: null,
            },
            headers: [
                {text: 'Nome', value: 'nm_servidor', sortable: true},
                {text: 'Cargo', value: 'sg_cargo', sortable: true},
                {text: 'Secretaria ou Departamento', value: 'nm_secretaria_departamento_exercicio', sortable: true},
                {text: 'Unidade', value: 'nm_unidade_exercicio', sortable: true},
                {text: 'Situação', value: 'nm_situacao', sortable: true},
            ],
            selected: [],
            items: [],
            itemsTipoHistorico: tipoHistorico,
            itemsCargo: [],
            itemsOrgaosSharepoint: [],
            itemsUnidadesSharepoint: [],
            situacoes: [],
            modalForm: false,
        }
    },
    created() {
        getSituacoes(true).then(response => this.situacoes = response.data.data);
        getCargos().then(response => this.itemsCargo = response.data.data);
        getOrgaosSharepoint().then(response => this.itemsOrgaosSharepoint = response.data.data);
        getUnidadesSharepoint().then(response => this.itemsUnidadesSharepoint = response.data.data);
    },
    watch: {
        'filtro.id_tipo_historico': function (val) {
            this.filtro.id_situacao = null;
        },
    },
    computed: {
        itemsSituacao() {
            return this.situacoes.filter((situacao) => situacao.id_tipo_historico === this.filtro.id_tipo_historico);
        },
    },
    methods: {
        limpar() {
            this.filtro = {
                id_tipo_historico: null,
                id_situacao: null,
                nm_orgao: null,
                nm_secretaria_departamento_exercicio: null,
                id_cargo: null,
            }
            this.selected = [];
            this.items = [];
            this.$refs.form.reset();
        },
        filtrar() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                getHistoricosAtuais(this.filtro).then(response => {
                    this.items = response.data.data;
                    this.selected = [];
                    this.loading = false;
                    this.modalForm = false;
                });
            }
        },
    }
}
</script>
