<template>
    <div>
        <h2 class="text-h6">Histórico de Cargos ou Funções Ocupadas</h2>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1 mb-7"
        >
            <template v-slot:item.dt_inicio="{ item }">
                <span>{{ dateFormat(item.dt_inicio) }}</span>
            </template>
            <template v-slot:item.dt_fim="{ item }">
                <span>{{ dateFormat(item.dt_fim) }}</span>
            </template>
        </v-data-table>
        <row-data-usuario-alteracao fonte="SIAPE" :data="dataAlteracaoServidor"/>
    </div>
</template>

<script>
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {getServidorCargosFuncoesOcupados} from "../../api/servidor";
import {dataTableMixin} from "../../mixins/dataTableMixin";
import moment from 'moment';
moment.locale('pt-br');

export default {
    name: "FichaCargosFuncoes",
    components: {RowDataUsuarioAlteracao},
    mixins: [dataTableMixin],
    props: {
        servidorId: {
            type: Number,
            required: true
        },
        dataAlteracaoServidor: {
            type: String
        }
    },
    data() {
        return {
            headers: [
                {text: 'Orgão', value: 'nm_orgao', sortable: false},
                {text: 'Uorg de exercício', value: 'nm_uorg_exercicio', sortable: false},
                {text: 'Nível de Cargo/Função', value: 'nv_cargo_funcao', sortable: false},
                {text: 'Inicio', value: 'dt_inicio', sortable: false},
                {text: 'Fim', value: 'dt_fim', sortable: false},
            ],
        }
    },
    methods: {
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY")
            }
            return null;
        },
        filtrar() {
            getServidorCargosFuncoesOcupados(this.servidorId, this.pagination.page, this.pagination.itemsPerPage)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        }
    },
}
</script>
