<template>
    <v-form :disabled="resumido" ref="form">
        <v-sheet class="pa-4  mb-5" rounded outlined>
            <v-alert
                v-if="!resumido"
                dense
                outlined
                text
                type="info"
            >
                Preencha os campos abaixo com informações relativas a áreas de atuação, políticas públicas e
                posições de liderança em que você gostaria de trabalhar no futuro.
            </v-alert>
            <v-row>
                <v-col cols="12" :md="resumido ? 6 : 4">
                    <AreaAtuacaoAutocomplete
                        label="Area Atuação *"
                        v-model="form.lt_area_atuacao"
                        :error="error.lt_area_atuacao"
                        :rules="validate('required', 'area de atuação')"
                        st-tipo-interesses
                    />
                </v-col>
                <v-col cols="12" :md="resumido ? 6 : 4">
                    <PoliticasPublicasAutocomplete
                        label="Políticas Públicas *"
                        v-model="form.lt_politicas_publicas"
                        :error="error.lt_politicas_publicas"
                        :rules="validate('required', 'políticas públicas')"
                        st-tipo-interesses
                    />
                </v-col>
                <v-col v-if="!resumido" cols="12" md="4">
                    <v-autocomplete
                        label="Posições de Liderança *"
                        outlined
                        v-model="form.lt_posicoes_lideranca"
                        :rules="validate('required', 'posições de liderança')"
                        :error-messages="error.lt_posicoes_lideranca"
                        :items="itemsPosicoesLideranca"
                        :filter="filterNormalize"
                        item-value="id_posicoes_lideranca"
                        item-text="nm_posicoes_lideranca"
                        multiple
                        chips
                    >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :input-value="selected"
                            >
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)"
                                >
                                    $delete
                                </v-icon>
                                <span class="pl-2">{{ item.nm_posicoes_lideranca }}</span>
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-if="!resumido">
                <v-col cols="12" md="6">
                    <v-textarea
                        label="Preferências"
                        outlined
                        dense
                        v-model="form.ds_preferencias"
                        :rules="validate('max:5000', 'preferências')"
                        :error-messages="error.ds_preferencias"
                        hint="Indique as eventuais preferências para uma futura alocação (órgão/unidade, tema, modalidade de trabalho, cargo/nível, disponibilidade etc)."
                        counter
                        maxlength="5000"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                    <v-textarea
                        label="Restrições"
                        outlined
                        dense
                        v-model="form.ds_restricoes"
                        :rules="validate('max:5000', 'restrições')"
                        :error-messages="error.ds_restricoes"
                        hint="Indique as eventuais restrições para uma futura alocação (órgão/unidade, tema, modalidade de trabalho, cargo/nível, disponibilidade etc)."
                        counter
                        maxlength="5000"
                    ></v-textarea>
                </v-col>
            </v-row>
            <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
        </v-sheet>
        <div v-if="!resumido" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="salvar()"
            >
                Salvar
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import {getPosicoesLideranca} from "../../api/posicoes_lideranca";
import {getServidorInteresses, upsertServidorInteresses} from "../../api/servidor";
import moment from 'moment';
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {dataUsuarioAlteracaoMixin} from "../../mixins/dataUsuarioAlteracaoMixin";
import {validationMixin} from "../../mixins/validationMixin";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import AreaAtuacaoAutocomplete from "../FormInputs/AreaAtuacaoAutocomplete.vue";
import PoliticasPublicasAutocomplete from "../FormInputs/PoliticasPublicasAutocomplete.vue";

moment.locale('pt-br');

export default {
    name: "FichaInteresses",
    mixins: [dataUsuarioAlteracaoMixin, validationMixin, filterNormalizeMixin],
    components: {PoliticasPublicasAutocomplete, AreaAtuacaoAutocomplete, RowDataUsuarioAlteracao},
    props: {
        servidorId: {
            type: Number,
            required: true
        },
        resumido: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            itemsPosicoesLideranca: [],
            form: {
                lt_area_atuacao: [],
                lt_politicas_publicas: [],
                lt_posicoes_lideranca: [],
                ds_preferencias: '',
                ds_restricoes: '',
            },
            error: {}
        }
    },
    created() {
        getPosicoesLideranca().then(response => this.itemsPosicoesLideranca = response.data.data);
        getServidorInteresses(this.servidorId)
            .then(response => {
                this.form.lt_area_atuacao = response.data.data.areas_atuacao.map((object) => object.id_area_atuacao)
                this.form.lt_politicas_publicas = response.data.data.politicas_publicas.map((object) => object.id_politicas_publicas)
                this.form.lt_posicoes_lideranca = response.data.data.posicoes_lideranca.map((object) => object.id_posicoes_lideranca)
                this.form.ds_preferencias = response.data.data.ds_preferencias;
                this.form.ds_restricoes = response.data.data.ds_restricoes;

                this.setDataUsuarioAlteracaoByResponse(response)
            });
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                upsertServidorInteresses(this.servidorId, JSON.stringify(this.form))
                    .then((response) => {
                        this.setDataUsuarioAlteracaoByResponse(response)

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        });
                        this.$vuetify.goTo(0);
                    })
                    .catch((error) => {
                        this.error = {...error.response.data.errors}
                    });
            }
        }
    },
}
</script>
