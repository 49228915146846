<template>
    <v-row>
        <v-col cols="12">
            <OrgaoEntidadeSIORGAutocomplete
                v-show="!form.nm_orgao"
                :label="`Órgão ou entidade de ${label} ${labelRequiedTag}`"
                :value="form.id_orgao"
                @input="$emit('update:form', 'id_orgao', $event)"
                @update:nmOrgao="$emit('update:form', 'nm_orgao', $event)"
                :rules="requiredFields ? [() => !!form.nm_orgao || !!form.id_orgao || 'O campo órgão ou entidade é obrigatório.'] : []"
            />
            <v-text-field
                v-show="form.nm_orgao"
                :label="`Órgão ou entidade de ${label} ${labelRequiedTag}`"
                outlined
                dense
                clearable
                @click:clear="$emit('update:form', 'id_orgao', null)"
                readonly
                :disabled="disabledOrgao"
                :value="form.nm_orgao"
                @input="$emit('update:form', 'nm_orgao', $event)"
            />
        </v-col>

        <v-col cols="12">
            <SecretariaDepartamentoSIORGAutocomplete
                v-show="!form.nm_secretaria_departamento_exercicio"
                :label="`Secretaria ou departamento de ${label} ${requiredFields || form.id_orgao ? '*' : ''}`"
                :value="form.id_secretaria_departamento_exercicio"
                @input="$emit('update:form', 'id_secretaria_departamento_exercicio', $event)"
                :rules="requiredFields || form.id_orgao ? [() => !!form.nm_secretaria_departamento_exercicio || !!form.id_secretaria_departamento_exercicio || 'O campo secretaria ou departamento é obrigatório.'] : []"
                :id-orgao="form.id_orgao"
                @update:nmSecretaria="$emit('update:form', 'nm_secretaria_departamento_exercicio', $event)"
            />
            <v-text-field
                v-show="!!form.nm_secretaria_departamento_exercicio"
                :label="`Secretaria ou departamento de ${label} ${labelRequiedTag}`"
                outlined
                dense
                clearable
                @click:clear="$emit('update:form', 'id_secretaria_departamento_exercicio', null)"
                readonly
                :value="form.nm_secretaria_departamento_exercicio"
                @input="$emit('update:form', 'nm_secretaria_departamento_exercicio', $event)"
            ></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-text-field
                :label="`Unidade de ${label} ${labelRequiedTag}`"
                outlined
                dense
                :value="form.nm_unidade_exercicio"
                @input="$emit('update:form', 'nm_unidade_exercicio', $event)"
                :rules="requiredFields ? validate('required', 'unidade') : []"
            ></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
import OrgaoEntidadeSIORGAutocomplete from "./OrgaoEntidadeSIORGAutocomplete.vue";
import {validationMixin} from "../../mixins/validationMixin";
import SecretariaDepartamentoSIORGAutocomplete from "./SecretariaDepartamentoSIORGAutocomplete.vue";

export default {
    name: "OrgaoSecretariaUnidadeFields",
    components: {SecretariaDepartamentoSIORGAutocomplete, OrgaoEntidadeSIORGAutocomplete},
    mixins: [validationMixin],
    props: {
        label: {
            type: String,
            default: 'destino'
        },
        form: {
            type: Object,
            required: true
        },
        requiredFields: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        disabledOrgao() {
            return this.$route.name == 'ficha-funcional-editar' &&
                !this.$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat']);
        },
        labelRequiedTag() {
            return this.requiredFields ? '*' : '';
        },
    },
}
</script>
