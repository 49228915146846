<template>
    <v-card
        :disabled="disabled"
        :to="to"
        :elevation="elevation"
        :outlined="outlined"
        style="position: relative"
    >
        <v-chip
            v-if="showInscritosInfo || oportunidadeMovimetacao.dt_publicacao > $store.getters['auth/usuarioAutenticadoDataUltimoAcesso']"
            small
            class="orange"
            style="position: absolute; top: 5px; right: 5px;"
        >{{ chipLabel }}</v-chip>

        <v-card-title style="word-break: break-word;">{{ vaga }}</v-card-title>
        <v-card-subtitle>
            <strong>Órgão:</strong> {{ oportunidadeMovimetacao.nm_orgao }}<br>
            <strong>Secretaria ou Departamento:</strong> {{ oportunidadeMovimetacao.nm_secretaria_departamento_exercicio }}<br>
            <strong>Unidade:</strong> {{ oportunidadeMovimetacao.nm_unidade_exercicio }}<br>
        </v-card-subtitle>
        <v-card-text>
            <strong>Cargo comissionado:</strong> {{ oportunidadeMovimetacao.nivel ? oportunidadeMovimetacao.nivel.nm_nivel : 'Não se aplica' }}.<br>
            <strong>Cidade/UF:</strong> {{ oportunidadeMovimetacao.nm_cidade }}/{{ oportunidadeMovimetacao.ds_estado }}<br>
            <strong>Modalidade:</strong> {{ oportunidadeMovimetacao.modalidade_trabalho.nm_modalidade_trabalho }}<br>
            <strong>Inscrições até:</strong> {{ dateFormat(prazoInscricao) }}<br>
        </v-card-text>
    </v-card>
</template>

<script>
import {dateMixin} from "../../mixins/dateMixin";

export default {
    name: "OportunidadeProcessoCard",
    mixins: [dateMixin],
    props: {
        oportunidadeMovimetacao: {
            type: Object,
            required: true
        },
        disabled: {
            boolean: true,
            default: false
        },
        to: {
            type: Object,
            default: null
        },
        showInscritosInfo: {
            type: Boolean,
            default: false
        },
        elevation: {
            type: Number,
            default: 0
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        vaga() {
            return this.oportunidadeMovimetacao.nm_perfil_vaga || this.oportunidadeMovimetacao.divulgacao.nm_perfil_vaga;
        },
        prazoInscricao() {
            return this.oportunidadeMovimetacao.dt_prazo_inscricao || this.oportunidadeMovimetacao.divulgacao.dt_prazo_inscricao;
        },
        chipLabel() {
            return this.showInscritosInfo ? `${this.oportunidadeMovimetacao.inscritos_count} Inscritos` : 'Novo';
        }
    }
}
</script>
