<template>
    <Loader />
</template>


<script>
    import Loader from '../Loader.vue';

    export default {
        name: 'auth-valida-app',
        components: {
            Loader
        },
        mounted() {
        	var code = this.$route.query.code;
        	var state = this.$route.query.state;
            const payload = {
                code: code,
                state: state
            };

        	this.$store.dispatch('auth/login', payload).then(
                response => {
                    if (localStorage.getItem('redirect')) {
                        const redirect = localStorage.getItem('redirect');
                        localStorage.removeItem('redirect');
                        window.location.href = redirect;
                    } else {
                        this.$router.push({ name: 'home' });
                    }
                },
                error => {
                    this.$store.dispatch('auth/logout').then(
                        this.$router.push({ name: 'home'},
                            () => this.$store.dispatch('alertas/show', {
                                tipo: 'Erro',
                                titulo: error.response.data.title,
                                mensagem: error.response.data.msg
                            })
                        )
                    );
                }
            );
		},
    }
</script>
