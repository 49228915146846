<template>
    <v-sheet class="pa-4 mb-5" rounded outlined>
        <h2 class="subtitle-1 mb-3 font-weight-bold">Cargo ou Função</h2>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Nome do cargo ou função"
                    outlined
                    dense
                    :disabled="disabled"
                    :value="form.nm_cargo_funcao"
                    @input="$emit('update:form', {...form, nm_cargo_funcao: $event});"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-select
                    label="Sigla cargo ou função"
                    outlined
                    dense
                    clearable
                    :disabled="disabled"
                    :value="form.id_funcao"
                    @input="$emit('update:form', {...form, id_funcao: $event});"
                    :items="funcoes"
                    item-value="id_funcao"
                    item-text="nm_funcao"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-select
                    label="Nível cargo ou função"
                    outlined
                    dense
                    clearable
                    :disabled="!niveis.length || disabled"
                    :value="form.id_nivel"
                    @input="$emit('update:form', {...form, id_nivel: $event});"
                    :items="niveis"
                    item-value="id_nivel"
                    item-text="nm_nivel"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    label="Hierarquia cargo ou função"
                    outlined
                    dense
                    disabled
                    :value="nm_hierarquia"
                ></v-text-field>
            </v-col>


            <slot></slot>
        </v-row>
    </v-sheet>
</template>

<script>
import {getFuncoes} from "../../api/funcao";
import {getHierarquiaByFuncaoAndNivel, getNiveisByFuncao} from "../../api/nivel-funcao-hierarquia";

export default {
    name: "CargoFuncaoFields",
    props: {
        form: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dataLoaded: false,
            funcoes: [],
            niveis: [],
            nm_hierarquia: '',
        }
    },
    created() {
        getFuncoes().then(response => this.funcoes = response.data.data)

        this.setNiveisByFuncao(this.form.id_funcao)
        this.setHierarquiaByNivel(this.form.id_nivel)
    },
    watch: {
        'form.id_funcao'(value, oldValue) {
            this.niveis = []
            if (oldValue != null) {
                this.form.id_nivel = null
            }
            this.setNiveisByFuncao(value)
        },
        'form.id_nivel'(value) {
            this.form.id_hierarquia = null
            this.nm_hierarquia = ""

            this.setHierarquiaByNivel(value)
        },
    },
    methods: {
        setNiveisByFuncao(id_funcao) {
            if (!!id_funcao) {
                getNiveisByFuncao(id_funcao).then((response) => this.niveis = response.data.data)
            }
        },
        setHierarquiaByNivel(id_nivel) {
            if (!!this.form.id_funcao && !!id_nivel) {
                getHierarquiaByFuncaoAndNivel(this.form.id_funcao, id_nivel).then((response) => {
                    this.nm_hierarquia = response.data.data.nm_hierarquia
                    this.form.id_hierarquia = response.data.data.id_hierarquia
                })
            }
        }
    },
}
</script>
