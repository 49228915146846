<template>
    <span>
        <v-tooltip top>
            <template v-slot:activator="{ on: tooltip}">
                <v-btn
                    fab
                    color="primary"
                    x-small
                    elevation="0"
                    v-on="{ ...tooltip}"
                    @click="show = true"
                >
                    <v-icon>
                        mdi-account-edit
                    </v-icon>
                </v-btn>
            </template>
            <span>Trocar Solicitante</span>
        </v-tooltip>
        <v-dialog
            v-model="show"
            persistent
            max-width="900"
        >
            <v-card>
                <v-card-title>
                    <span class="headline">Trocar Solicitante</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-autocomplete
                            label="Atribuir Solicitante *"
                            outlined
                            dense
                            clearable
                            v-model="form.id_usuario"
                            :items="itemsSolicitantes"
                            item-value="id_usuario"
                            item-text="nm_usuario"
                            :rules="validate('required', 'atribuir solicitante')"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    class="mt-n1"
                                    color="primary"
                                    @click="addSolicitante()"
                                >
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Adicionar
                                </v-btn>
                            </template>
                        </v-autocomplete>
                    </v-form>
                    <v-sheet class="pa-4  mb-5" rounded outlined>
                        <strong>Solicitante Principal:</strong> {{ solicitacao.nm_usuario_solicitante }}
                    </v-sheet>
                    <v-sheet class="pa-4  mb-5" rounded outlined>
                        <h2 class="subtitle-2 mb-3">Solicitantes Atribuídos</h2>
                        <v-data-table
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        @update:options="filtrar()"
                    >
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn
                                        fab
                                        color="primary"
                                        elevation="0"
                                        x-small
                                        @click="updateSolicitantePrincipal(item)"
                                        v-on="{ ...tooltip}"
                                    >
                                        <v-icon>
                                            mdi-repeat-variant
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Trocar Solicitante Principal</span>
                            </v-tooltip>
                            <DeleteAction @delete="removerSolicitante(item)" />
                        </template>
                    </v-data-table>
                    </v-sheet>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined text @click="show = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import {validationMixin} from "../../mixins/validationMixin";
import {addSolicitante, getSolicitantes, removerSolicitante, updateSolicitantePrincipal} from "../../api/solicitantes";
import {dataTableMixin} from "../../mixins/dataTableMixin";
import DeleteAction from "./DeleteAction.vue";

export default {
    name: "ChangeUsuarioSolicitanteAction",
    components: {DeleteAction},
    mixins: [validationMixin, dataTableMixin],
    props: {
        solicitacao: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        solicitantes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            show: false,
            id: this.solicitacao[`id_${this.type}`],
            form: {
                id_usuario: null,
                id: this.solicitacao[`id_${this.type}`],
                type: this.type,
            },
            headers: [
                {text: 'Solicitantes', value: 'nm_usuario', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
        }
    },
    methods: {
        filtrar() {
            getSolicitantes(this.id, this.type).then(response => {
                this.items = response.data.data
            })
        },
        addSolicitante() {
            if (this.$refs.form.validate()) {
                addSolicitante(this.form).then(response => {
                    this.form.id_usuario = null
                    this.$refs.form.resetValidation()
                    this.filtrar()
                })
            }
        },
        updateSolicitantePrincipal(item) {
            updateSolicitantePrincipal(item.id_usuario, {id: this.id, type: this.type}).then(response => {
                this.solicitacao.nm_usuario_solicitante = item.nm_usuario
                this.filtrar()
            })
        },
        removerSolicitante(item) {
            removerSolicitante(item.id_usuario, this.id, this.type).then(response => {
                this.items = this.items.filter(i => i.id_usuario !== item.id_usuario)
            })
        },
    },
    computed: {
        itemsSolicitantes() {
            return this.solicitantes.filter(solicitante => {
                return !this.items.some(item => item.id_usuario === solicitante.id_usuario) &&
                    solicitante.nm_usuario !== this.solicitacao.nm_usuario_solicitante
            })
        }
    }
}
</script>
