import api from './index';

const getSituacoesByTipoHistorico = (id_cargo, id_tipo_historico) => api.get('situacao', {
    params: {
        id_cargo: id_cargo,
        id_tipo_historico: id_tipo_historico,
    }
});
const getSituacoes = (with_trashed) => api.get('situacao', {
    params: {
        with_trashed: with_trashed,
    }
});
const createSituacao = (dados) => api.post('situacao', dados);
const updateSituacao = (id, dados) => api.put(`situacao/${id}`, dados);
const deleteSituacao = (id) => api.delete(`situacao/${id}`);

export {
    getSituacoesByTipoHistorico,
    getSituacoes,
    createSituacao,
    updateSituacao,
    deleteSituacao,
}
