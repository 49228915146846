import { render, staticRenderFns } from "./ListaPoder.vue?vue&type=template&id=3227eb60&"
import script from "./ListaPoder.vue?vue&type=script&lang=js&"
export * from "./ListaPoder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports