import api from './index';

const getNivelAfastamento = () => api.get('nivel-afastamento');
const createNivelAfastamento = (dados) => api.post('nivel-afastamento', dados);
const updateNivelAfastamento = (nivel_afastamento_id, dados) => api.put(`nivel-afastamento/${nivel_afastamento_id}`, dados);
const deleteNivelAfastamento = (nivel_afastamento_id) => api.delete(`nivel-afastamento/${nivel_afastamento_id}`);

export {
    getNivelAfastamento,
    createNivelAfastamento,
    updateNivelAfastamento,
    deleteNivelAfastamento
}
