import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

import Home from './views/Home.vue';
import FichaFuncionalListar from './views/FichaFuncionalListar.vue'
import FichaFuncional from "./views/FichaFuncional";
import UsuariosListar from './views/UsuariosListar.vue'
import Valida from './components/auth/Valida.vue';
import Login from "./components/Login";
import Usuario from "./views/Usuario";
import OrgaosListar from "./views/OrgaosListar";
import Orgao from "./views/Orgao";
import TermoPolitica from "./views/TermoPolitica";
import ListasDominios from "./views/ListasDominios";
import SolicitacaoMovimentacao from "./views/SolicitacaoMovimentacao";
import SolicitacaoMovimentacaoListar from "./views/SolicitacaoMovimentacaoListar";
import MovimentacaoLoteServidores from "./views/MovimentacaoLoteServidores.vue";
import ProcessoSeletivo from "./views/ProcessoSeletivo.vue";
import ProcessoSeletivoListar from "./views/ProcessoSeletivoListar.vue";
import OportunidadeMovimentacao from "./views/OportunidadeMovimentacao.vue";

let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: false,
            roles: ['administrador', 'analista_cgcat', 'carreira', 'chefia', 'solicitante'],
            menu: {
                show: true,
                titulo: 'Página Inicial'
            },
            breadcrumb: {
                show: false,
            }
        }
    },
    {
        path: '/ficha-funcional',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'analista_cgcat'],
            menu: {
                show: true,
                titulo: 'Ficha Funcional',
            },
            breadcrumb: {
                show: true,
                titulo: 'Listar Fichas Funcionais'
            }
        },
        children: [
            {
                path: '',
                name: 'ficha-funcional-listar',
                component: FichaFuncionalListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'analista_cgcat'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Listar Fichas Funcionais'
                    }
                },
            },
            {
                path: ':servidor',
                name: 'ficha-funcional-editar',
                component: FichaFuncional,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'analista_cgcat', 'carreira'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Ficha Funcional'
                    }
                },
            },
        ]
    },
    {
        path: '/oportunidade-movimentacao',
        component: {
            render(c) {
                return c('router-view');
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador', 'analista_cgcat', 'carreira'],
            menu: {
                show: true,
                titulo: 'Oportunidades de Movimentação'
            },
            breadcrumb: {
                show: false,
                titulo: 'Listar Oportunidades de Movimentação'
            }
        },
        children: [
            {
                path: '',
                name: 'oportunidade-movimentacao-listar',
                component: OportunidadeMovimentacao,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'analista_cgcat', 'carreira'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Listar Oportunidades de Movimentação'
                    }
                },
            },
            {
                path: 'form/:processo?',
                name: 'oportunidade-movimentacao-form',
                component: ProcessoSeletivo,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador', 'analista_cgcat', 'carreira'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Oportunidade de Movimentação'
                    }
                }
            }
        ]
    },
    {
        path: '/processo-seletivo',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['solicitante'],
            // roles: ['dev'],
            menu: {
                show: true,
                titulo: 'Processo Seletivo',
            },
            breadcrumb: {
                show: true,
                titulo: 'Listar Processos Seletivos'
            }
        },
        children: [
            {
                path: '',
                name: 'processo-seletivo-listar',
                component: ProcessoSeletivoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['solicitante'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Listar Processos Seletivos'
                    }
                },
            },
            {
                path: 'form/:processo?',
                name: 'processo-seletivo-form',
                component: ProcessoSeletivo,
                meta: {
                    requiresAuth: true,
                    roles: ['solicitante'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Solicitação de aberura de processo seletivo'
                    }
                },
            },
        ]
    },
    {
        path: '/solicitacao-movimentacao',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['solicitante'],
            menu: {
                show: true,
                titulo: 'Solicitação Movimentação',
            },
            breadcrumb: {
                show: true,
                titulo: 'Listar Solicitações de Movimentação'
            }
        },
        children: [
            {
                path: '',
                name: 'solicitacao-movimentacao-listar',
                component: SolicitacaoMovimentacaoListar,
                meta: {
                    requiresAuth: true,
                    roles: ['solicitante'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Listar Solicitações de Movimentação'
                    }
                },
            },
            {
                path: 'form/:solicitacao?',
                name: 'solicitacao-movimentacao-form',
                component: SolicitacaoMovimentacao,
                meta: {
                    requiresAuth: true,
                    roles: ['solicitante'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Solicitação de Movimentação'
                    }
                },
            },
        ]
    },
    {
        path: '/gerenciar',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['administrador'],
            menu: {
                show: true,
                titulo: 'Gerenciar',
            },
            breadcrumb: {
                // show: false,
                // titulo: 'Listar Usuários'
            }
        },
        children: [
            {
                path: '/usuario',
                name: 'usuario-listar',
                submenu: true,
                component: UsuariosListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Usuários'
                    }
                }
            },
            {
                path: '/usuario/:usuario',
                name: 'usuario-editar',
                submenu: false,
                component: Usuario,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Usuário'
                    }
                },
            },
            {
                path: '/movimentacao-lote-servidores',
                name: 'movimentacao-lote-servidores',
                submenu: true,
                component: MovimentacaoLoteServidores,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Movimentação em Lote de Servidores'
                    }
                }
            },
            {
                path: '/termo-politica',
                name: 'termo-politica',
                submenu: true,
                component: TermoPolitica,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Termo de Uso e Aviso de Privacidade'
                    }
                }
            },
            {
                path: '/listas-dominios',
                name: 'listas-dominios',
                submenu: true,
                component: ListasDominios,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Listas de Domínios'
                    }
                }
            },
            {
                path: '/estrutura-organizacional',
                name: 'orgao-listar',
                submenu: true,
                component: OrgaosListar,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Listar Órgãos ou Entidades'
                    }
                }
            },
            {
                path: '/estrutura-organizacional/orgao/:orgao?',
                name: 'orgao-form',
                submenu: false,
                component: Orgao,
                meta: {
                    requiresAuth: true,
                    roles: ['administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Órgão'
                    }
                }
            }
        ]
    },
    {
        path: '/valida',
        name: 'valida',
        component: Valida,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
            },
            breadcrumb: {
                show: false,
            }
        }
    },
];

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        let titulo = 'Página requer autenticação';
        let mensagem = 'É necessário realizar login na aplicação para acessar a página desejada.'
        if ( store.getters['auth/isLoggedIn']) {
            if ( store.getters['auth/usuarioAutenticadoRoles'].some(r => to.meta.roles.indexOf(r) >= 0) ) {
                return next();
            } else {
                titulo = 'Acesso negado';
                mensagem = 'Você não possui permissão necessária para acessar a página desejada.';
            }
        }

        if (to.name !== 'home') {
            localStorage.setItem('redirect', to.fullPath);
        }

        router.push({ name: 'home' },
            () => store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: titulo,
                mensagem: mensagem,
            })
        );
    }
    next();
});

