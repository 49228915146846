import api from './index';

const getEsferas = () => api.get('esfera');
const createEsfera = (dados) => api.post('esfera', dados);
const updateEsfera = (esfera_id, dados) => api.put(`esfera/${esfera_id}`, dados);
const deleteEsfera = (esfera_id) => api.delete(`esfera/${esfera_id}`);

export {
    getEsferas,
    createEsfera,
    updateEsfera,
    deleteEsfera
}
