import {validationMixin} from "./validationMixin";

export const analiseChecklistMixin = {
    props: ['analiseSolicitacao', 'id_status_solicitacao', 'showInstrucoes'],
    mixins: [validationMixin],
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        disabled() {
            if (this.showInstrucoes) {
                return true;
            }
            return this.id_status_solicitacao > 3;
        }
    },
    methods: {
        changeObrigatorio(item) {
            if (!this.form[item.obrigatorio]) {
                this.form[item.model] = null;
                this.form[item.observacao] = null;
            }
            this.salvar();
        },
        salvar() {
            this.salvarFunction(
                this.analiseSolicitacao.id_solicitacao_movimentacao,
                this.analiseSolicitacao.id_analise_solicitacao,
                this.form
            ).then(response => {
                console.log(response);
            })
        },
        openDialog() {
            if (this.$refs.form.validate()) {
                this.dialog = true;
            }
        },
        enviar() {
            if (this.$refs.form.validate()) {
                this.enviarFunction(this.analiseSolicitacao.id_solicitacao_movimentacao, this.form).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: response.data.success ? 'Sucesso': 'Warning',
                        titulo: response.data.message,
                    });
                    if (this.showInstrucoes) {
                        this.$router.push({name: 'solicitacao-movimentacao-listar'});
                    } else {
                        this.$emit('setIdStatusSolicitacao', response.data.data.id_status_solicitacao)
                    }
                    this.$vuetify.goTo(0);
                })
                this.dialog = false;
            }
        }
    },
}
