<template>
    <oportunidades-processos-tabs />
</template>

<script>
import OportunidadesProcessosTabs from "../components/OportunidadeProcesso/OportunidadesProcessosTabs.vue";

export default {
    name: "OportunidadeMovimentacao",
    components: {OportunidadesProcessosTabs}
}
</script>

