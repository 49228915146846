export const fileMixin = {
    methods: {
        forceFileDownload(response, file = 'anexo.pdf') {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file) //or any other extension
            document.body.appendChild(link)
            link.click()
        },
        openFileNewTab(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            window.open(url, '_blank')
        }
    }
}
