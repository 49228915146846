import {dataTableMixin} from "./dataTableMixin";
import {validationMixin} from "./validationMixin";

export const listasDominiosMixin = {
    mixins: [dataTableMixin, validationMixin],
    data() {
        return {
            fields: {},
            form: {...this.fields},
            column_id: null,
            api: {
                index: null,
                create: null,
                update: null,
                delete: null,
            },
        }
    },
    methods: {
        editar(values) {
            this.form = {...values};
            this.$vuetify.goTo(`#${this.column_id}`);
        },
        salvar() {
            if (this.$refs.form.validate()) {
                let callback
                if (this.form[this.column_id]) {
                    callback = this.api.update(this.form[this.column_id], this.form)
                } else {
                    callback = this.api.create(this.form)
                }

                callback.then((response) => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.limpar()
                    this.$refs.form.resetValidation()
                    this.filtrar()
                }).catch((error) => {
                    this.error = {...error.response.data.errors}
                });
            }
        },
        limpar() {
            this.form = {...this.fields}
        },
        filtrar() {
            this.api.index().then(response => this.items = response.data.data);
        },
        deletar(id) {
            this.api.delete(id).then((response) => {
                this.items = this.items.filter(item => item[this.column_id] !== id)
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                });
                this.$vuetify.goTo(0);
            })
        }
    },
}
