import api from './index'

const getAreasAtuacao = (params) => api.get('area-atuacao', {
    params: params
});
const createAreaAtuacao = (dados) => api.post('area-atuacao', dados);
const updateAreaAtuacao = (id, dados) => api.put(`area-atuacao/${id}`, dados);
const deleteAreaAtuacao = (id) => api.delete(`area-atuacao/${id}`);

export {
    getAreasAtuacao,
    createAreaAtuacao,
    updateAreaAtuacao,
    deleteAreaAtuacao,
}
