<template>
    <div>
        <v-form ref="form" class="mb-3">
            <div class="d-flex mt-3">
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="$emit('view', returnToView, historico)"
                >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Voltar
                </v-btn>
            </div>
            <v-sheet class="d-flex flex-wrap justify-space-between pa-4  my-5 subtitle-1" rounded outlined>
                <div><b>Tipo:</b> {{ historico.tipo_historico.nm_tipo_historico }}</div>
                <div><b>Orgão ou Entidade:</b> {{ historico.nm_orgao }}</div>
                <div><b>Data Início:</b> {{ dateFormat(historico.dt_inicio) }}</div>
                <div><b>Data Fim:</b> {{ dateFormat(historico.dt_fim) }}</div>
                <div><b>Situação:</b> {{ historico.nm_situacao }}</div>
            </v-sheet>
            <v-sheet class="pa-4  my-5" rounded outlined>
                <h1 class="subtitle-1 mb-3">Anexo</h1>
                <v-row>
                    <v-col cols="6" md="2">
                        <v-select
                            label="Tipo do anexo *"
                            outlined
                            dense
                            :rules="validate('required', 'tipo do anexo')"
                            v-model="form.id_tipo_anexo"
                            :error-messages="error.id_tipo_anexo"
                            :items="itemsTipoAnexo"
                            item-value="id_tipo_anexo"
                            item-text="nm_anexo"
                        ></v-select>
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-text-field
                            label="Número do anexo"
                            outlined
                            dense
                            @keyup.enter="salvar()"
                            type="number"
                            v-model="form.nr_anexo"
                            :error-messages="error.nr_anexo"
                            :rules="validate('gte:0', 'número do anexo')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Descrição do anexo *"
                            outlined
                            dense
                            @keyup.enter="salvar()"
                            :rules="validate('required', 'descrição do anexo')"
                            v-model="form.ds_anexo"
                            :error-messages="error.ds_anexo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-text-field
                            label="Data do anexo"
                            outlined
                            dense
                            type="date"
                            v-model="form.dt_anexo"
                            :error-messages="error.dt_anexo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="12">
                        <v-file-input
                            accept="application/pdf"
                            label="Arquivo"
                            outlined
                            dense
                            :rules="[() => !!form.id_historico_anexo || !!form.file || 'O campo arquivo é obrigatório.']"
                            v-model="form.file"
                            :error-messages="error.file"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <row-data-usuario-alteracao :data="dataAlteracao" :usuario="usuarioAlteracao"/>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-3"
                    outlined
                    @click="limpar()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1 mb-7"
        >
            <template v-slot:item.dt_anexo="{ item }">
                <span>{{ dateFormat(item.dt_anexo) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="primary"
                            elevation="0"
                            x-small
                            @click="download(item.id_historico_anexo)"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-cloud-download
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Download</span>
                </v-tooltip>
                <EditAction
                    v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                    @click="form = {...item}"
                />
                <DeleteAction
                    v-if="$store.getters['auth/usuarioPermissao'](['administrador', 'analista_cgcat'])"
                    @delete="deletar(item.id_historico_anexo)"
                />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {
    createServidorHistoricoAnexo,
    deleteServidorHistoricoAnexo, getServidorHistoricoAnexoDownload,
    getServidorHistoricoAnexos, updateServidorHistoricoAnexo
} from "../../api/servidor";
import moment from 'moment';
import RowDataUsuarioAlteracao from "../RowDataUsuarioAlteracao";
import {anexoMixin} from "../../mixins/anexoMixin";
import {validationMixin} from "../../mixins/validationMixin";
import DeleteAction from "../Table/DeleteAction.vue";
import EditAction from "../Table/EditAction.vue";

moment.locale('pt-br');

export default {
    name: "FichaHistoricoAnexo",
    components: {EditAction, DeleteAction, RowDataUsuarioAlteracao},
    mixins: [anexoMixin, validationMixin],
    props: {
        historico: {
            type: Object,
            required: true
        },
        returnToView: {
            type: String
        }
    },
    data() {
        return {
            fields: {
                id_historico_anexo: null,
                id_tipo_anexo: null,
                nr_anexo: null,
                ds_anexo: null,
                dt_anexo: null,
                file: null,
            }
        }
    },
    computed: {
        dataAlteracao() {
            return !!this.form.updated_at ? moment(this.form.updated_at).format("DD/MM/YYYY LT") : null
        },
        usuarioAlteracao() {
            return !!this.form.usuario_alteracao ? this.form.usuario_alteracao.nm_usuario : ''
        }
    },
    methods: {
        download(id_anexo) {
            getServidorHistoricoAnexoDownload(this.historico.id_servidor, this.historico.id_historico, id_anexo)
                .then(response => {
                    this.forceFileDownload(response)
                });
        },
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY")
            }
            return null;
        },
        salvar() {
            if (this.$refs.form.validate()) {
                const formData = new FormData();
                formData.append('id_tipo_anexo', this.form.id_tipo_anexo);
                if (!!this.form.nr_anexo) {
                    formData.append('nr_anexo', this.form.nr_anexo);
                }
                if (!!this.form.ds_anexo) {
                    formData.append('ds_anexo', this.form.ds_anexo);
                }
                if (!!this.form.dt_anexo) {
                    formData.append('dt_anexo', this.form.dt_anexo);
                }
                if (!!this.form.file) {
                    formData.append('file', this.form.file);
                }

                let callback
                if (!!this.form.id_historico_anexo) {
                    callback = updateServidorHistoricoAnexo(
                        this.historico.id_servidor,
                        this.historico.id_historico,
                        this.form.id_historico_anexo,
                        formData
                    );
                } else {
                    callback = createServidorHistoricoAnexo(
                        this.historico.id_servidor,
                        this.historico.id_historico,
                        formData
                    );
                }

                callback.then((response) => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                    this.form = {}
                    this.$refs.form.resetValidation()
                    this.filtrar()
                }).catch((error) => {
                    this.error = {...error.response.data.errors}
                });
            }
        },
        filtrar() {
            getServidorHistoricoAnexos(this.historico.id_servidor, this.historico.id_historico, this.pagination.page, this.pagination.itemsPerPage)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        deletar(id_anexo) {
            deleteServidorHistoricoAnexo(this.historico.id_servidor, this.historico.id_historico, id_anexo)
                .then(response => {
                    this.items = this.items.filter(item => item.id_historico_anexo !== id_anexo);
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    });
                    this.$vuetify.goTo(0);
                })
        }
    },
}
</script>

<style scoped>

</style>
