import api from './index';

const getMe = () => api.get('usuario/me');

const getUsuario = (id_usuario) => api.get('usuario/' + id_usuario);

const getUsuarios = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('usuario', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

const putMe = (dados) => api.put('usuario/me', dados);

const putUsuario = (id_usuario, dados) => api.put('usuario/' + id_usuario, dados);

const putAceitarTermoUso = () => api.put('usuario/me/aceitar-termo-uso');

const deleteUsuario = (id_usuario) => api.delete('usuario/' + id_usuario);

export {
    getMe,
    getUsuario,
    getUsuarios,
    putMe,
    putUsuario,
    putAceitarTermoUso,
    deleteUsuario
}
