<template>
    <v-tooltip top>
        <template v-slot:activator="{ on: tooltip}">
            <v-btn
                fab
                color="success"
                x-small
                elevation="0"
                :to="to"
                :disabled="disabled"
                @click="$emit('click')"
                v-on="{ ...tooltip}"
            >
                <v-icon>
                    mdi-pencil
                </v-icon>
            </v-btn>
        </template>
        <span>Editar</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "EditAction",
    props: {
        to: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
