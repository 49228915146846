import { login } from '../api/auth';

class AuthService {
    getLocalUser() {
        const user = localStorage.getItem("user");

        if(!user) {
            return null;
        }

        return JSON.parse(user);
    }

    getLocalServidor() {
        const servidor = localStorage.getItem("servidor");

        if(!servidor) {
            return null;
        }

        return JSON.parse(servidor);
    }

    getLocalServidorCargo() {
        const servidor_cargo = localStorage.getItem("servidor_cargo");

        if(!servidor_cargo) {
            return null;
        }

        return JSON.parse(servidor_cargo);
    }

    getLocalToken() {
        const token = localStorage.getItem("access_token");

        if(!token) {
            return null;
        }

        return JSON.parse(token);
    }

    getLocalRoles() {
        const roles = localStorage.getItem("roles");

        return JSON.parse(roles);
    }

    getLocalDataUltimoAcesso() {
        const dataUltimoAcesso = localStorage.getItem("data_ultimo_acesso");

        return JSON.parse(dataUltimoAcesso);
    }

    login(code, state) {
        return login(code, state)
            .then(response => {
                if(response.status == 200) {
                    if (!!response.data.data.id_servidor) {
                        localStorage.setItem('servidor', JSON.stringify(response.data.data.id_servidor));
                        localStorage.setItem('servidor_cargo', JSON.stringify(response.data.data.id_cargo));
                    }
                    localStorage.setItem('user', JSON.stringify(response.data.data.id_usuario));
                    localStorage.setItem('access_token', JSON.stringify(response.data.data.token));
                    localStorage.setItem('roles', JSON.stringify(response.data.data.roles));
                    localStorage.setItem('data_ultimo_acesso', JSON.stringify(response.data.data.data_ultimo_acesso));
                }
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('servidor');
        localStorage.removeItem('servidor_cargo');
        localStorage.removeItem('access_token');
        localStorage.removeItem('roles');
        localStorage.removeItem('data_ultimo_acesso');
    }
}

export default new AuthService();
