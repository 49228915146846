import api from "./index";

const getDivulgacaoProcessoSeletivo = (id_processo_seletivo) => api.get('processo-seletivo/' + id_processo_seletivo + '/divulgacao');
const upsertDivulgacaoProcessoSeletivo = (id_processo_seletivo, dados) => api.post(
    "processo-seletivo/" + id_processo_seletivo + "/divulgacao",
    dados
);
const publicarDivulgacaoProcessoSeletivo = (id_processo_seletivo) => api.put(
    "processo-seletivo/" + id_processo_seletivo + "/divulgacao/publicar"
);

export {
    getDivulgacaoProcessoSeletivo,
    upsertDivulgacaoProcessoSeletivo,
    publicarDivulgacaoProcessoSeletivo,
}
